import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  HostListener,
  ElementRef,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as d3 from 'd3';

import * as func from './ava-sankey.functions';

@Component({
  selector: 'ava-sankey',
  templateUrl: 'ava-sankey.template.html',
  styleUrls: ['ava-sankey.template.css'],
})
export class AvaSankeyComponent implements OnInit {
  @Input() public height: number;
  @Input() public width: number;
  @Input() public data: any;

  @ViewChild('desc') description;

  NoteVisible = false;
  clientX = '0px';
  clientY = '0px';
  Amount = 0;
  YearAccrued = 0;
  YearPayed = 0;

  public constructor(
    private el: ElementRef,
    private translateService: TranslateService
  ) {}
  /*********************************************************************************** */
  ngOnInit() {
    // load the data
    var graph = this.data;

    var units = '';

    const margin = { top: 10, right: 10, bottom: 10, left: 10 };
    const width = this.width - margin.left - margin.right;
    const height = this.height - margin.top - margin.bottom;

    var formatNumber = d3.format(',.0f'); // zero decimal places
    const format = (d: any) => `${formatNumber(d)} ${units}`;

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // append the svg canvas to the page
    const rootEl = d3.select('#sankey');
    const svg = rootEl
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const sankey = func
      .d3sankey()
      .nodeWidth(12)
      .nodePadding(12)
      .size([width, height]);

    const path = sankey.link();

    const defs = svg.append('defs');

    // Set the sankey diagram properties

    sankey.nodes(graph.nodes).links(graph.links).layout(15);

    // create gradients for the links

    var grads = defs.selectAll('linearGradient').data(graph.links, getLinkID);

    grads
      .enter()
      .append('linearGradient')
      .attr('id', getLinkID)
      .attr('gradientUnits', 'objectBoundingBox');
    //stretch to fit

    grads
      .html('') //erase any existing <stop> elements on update
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', function (d: any) {
        return nodeColor(+d.source.x <= +d.target.x ? d.source : d.target);
      });

    grads
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', function (d: any) {
        return nodeColor(+d.source.x > +d.target.x ? d.source : d.target);
      });

    // add in the links
    let global = this;
    let container = svg.append('g');
    const link = svg
      .append('g')
      .selectAll('.link')
      .data(graph.links)
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', path)
      .style('stroke-width', (d: any) => Math.max(1, d.dy))
      .style('fill', 'none')
      .style('stroke-opacity', 0.18)
      .sort((a: any, b: any) => b.dy - a.dy)
      .on('mousemove', function (a) {
        let p = d3.mouse(this);
        global.setCoordinates(a, p);
      })
      .on('mouseover', function (a) {
        d3.select(this).style('stroke-opacity', 0.5);
        global.NoteVisible = true;
      })
      .on('mouseout', function () {
        d3.select(this).style('stroke-opacity', 0.2);
        global.NoteVisible = false;
      });

    // add the link titles
    // link.append('title')
    //   .text( (d:any) => `${d.source.name} → ${d.target.name}\n${format(d.value)}`);

    // add in the nodes

    const node = svg
      .append('g')
      .selectAll('.node')
      .data(graph.nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d: any) => `translate(${d.x},${d.y})`)
      .call(
        d3
          .drag()
          .subject((d: any) => d)
          .on('start', function () {
            this.parentNode.appendChild(this);
          })
          .on('drag', dragmove)
      );

    // add the rectangles for the nodes
    node
      .append('rect')
      .attr('height', (d: any) => d.dy)
      .attr('width', sankey.nodeWidth())
      .style('fill', (d: any) => {
        return (d.color = color(d.name));
      })
      .append('title')
      .text((d: any) => `${d.name}\n${format(d.value)}`);

    // add in the title for the nodes
    node
      .append('text')
      .attr('x', -6)
      .attr('y', (d: any) => d.dy / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'end')
      .attr('transform', null)
      .text((d: any) => d.name)
      .filter((d: any) => d.x < width / 2)
      .attr('x', 6 + sankey.nodeWidth())
      .attr('text-anchor', 'start');

    // add gradient to links
    link.style('stroke', (d: any, i: any) => {
      // make unique gradient ids
      const gradientID = `gradient${i}`;

      const startColor = d.source.color;
      const stopColor = d.target.color;

      //console.log('startColor', startColor);
      //console.log('stopColor', stopColor);

      const linearGradient = defs
        .append('linearGradient')
        .attr('id', gradientID);

      linearGradient
        .selectAll('stop')
        .data([
          { offset: '10%', color: startColor },
          { offset: '90%', color: stopColor },
        ])
        .enter()
        .append('stop')
        .attr('offset', (d: any) => {
          //console.log('d.offset', d.offset);
          return d.offset;
        })
        .attr('stop-color', (d: any) => {
          //console.log('d.color', d.color);
          return d.color;
        });

      return `url(#${gradientID})`;
    });

    // the function for moving the nodes
    function dragmove(d) {
      d3.select(this).attr(
        'transform',
        `translate(${(d.x = Math.max(
          0,
          Math.min(width - d.dx, d3.event.x)
        ))},${(d.y = Math.max(0, Math.min(height - d.dy, d3.event.y)))})`
      );
      sankey.relayout();
      link.attr('d', path);
    }

    // define utility functions
    function getLinkID(d) {
      return 'link-' + d.source.name + '-' + d.target.name;
    }
    function nodeColor(d) {
      return (d.color = color(d.name.replace(/ .*/, '')));
    }
  }

  setCoordinates(el, p) {
    let x = p[0];
    let y = p[1];
    this.clientX = x - 28 + 'px';
    this.clientY = y - 59 + 'px';

    this.Amount = this.formatNumber(el.value, 0);
    this.YearAccrued = el.source.name;
    this.YearPayed = el.target.name;
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }
}
