import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CompanyReportService } from '../../../../company-report.service';
import { take, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from 'app/shared/services/shared.service';

// Cordova
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Platform } from '@ionic/angular';

declare var window: any;

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: [
    './image-selector.component.scss',
    '../../../../../common_styles.scss',
  ],
})
export class ImageSelectorComponent implements OnInit {
  onClose: Subject<any> = new Subject<any>();
  onCloseModal: Subject<any> = new Subject<any>();
  imagesList: any;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  txtQueryChanged: Subject<string> = new Subject<string>();
  content: any;
  page = 1;
  page_size = 30;
  canSelectLocalImage: boolean;
  throttle = 50;
  scrollDistance = 2;
  scrollUpDistance = 2;

  modelChanged: Subject<string> = new Subject<string>();

  private searchInput: ElementRef;
  @ViewChild('searchInput', { static: false }) set _searchInput(
    content: ElementRef
  ) {
    if (content) {
      // initially setter gets called with undefined
      this.searchInput = content;
    }
  }

  constructor(
    private coreService: CoreService,
    public bsModalRef: BsModalRef,
    private companyReportService: CompanyReportService,
    public sharedService: SharedService,
    private camera: Camera,
    public platform: Platform
  ) {
    this.modelChanged
      .pipe(
        debounceTime(300), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe((model) => {
        this.searchText = model;
        if (this.searchText && this.searchText.length <= 2) return;
        this.isLoading = true;
        this.imagesList = undefined;
        this.getImages();
      });
  }

  async ngOnInit() {
    const response = await fetch(
      'https://images.pexels.com/photos/9715702/pexels-photo-9715702.jpeg'
    );
    const blob = await response.blob();
    console.log('blob', blob);
    const file = new File([blob], 'image.jpg', { type: blob.type });
    console.log('file', file);
  }

  onScrollDown() {
    this.page++;
    this.getImages();
  }

  getImages() {
    let seacrhText = '';
    if (
      (this.content && this.content.Search) ||
      (this.searchText && this.searchText.length > 2)
    ) {
      const defName = this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.NewObjective'
      );
      seacrhText = this.content.Search !== defName ? this.content.Search : '';
      if (this.searchText && this.searchText.length > 2) {
        seacrhText = this.searchText;
      }
    }
    if (seacrhText) {
      this.companyReportService
        .getMyCycleImagesQuery(seacrhText, this.page, this.page_size)
        .toPromise()
        .then((images: any) => {
          this.concatImages(images);
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    } else {
      this.companyReportService
        .getMyCycleImages(this.page, this.page_size)
        .toPromise()
        .then((images: any) => {
          this.concatImages(images);
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    }
  }

  concatImages(images) {
    var newImages = images.results ? images.results : [];
    if (!this.imagesList) this.imagesList = [];
    this.imagesList = this.imagesList.concat(newImages);
  }

  public showModal(content: any): void {
    this.content = content;
    this.canSelectLocalImage = content?.canSelectLocalImage;
    this.imagesList = undefined;
    this.getImages();
  }

  isLoading: boolean = false;
  async selectImage(image: any) {
    if (this.isLoading) return;
    console.log('selectImage', image);
    await this.companyReportService.selectImage(image).toPromise();
    this.onClose.next(image);
    this.bsModalRef.hide();
  }

  onFieldChange(query: any) {
    this.txtQueryChanged.next(query);
  }

  searchText: string;
  filter(text: any) {
    this.modelChanged.next(text);
  }

  getImage(image: any) {
    if (image.urls.thumb) {
      return {
        'background-image': `url('${image.urls.thumb}')`,
        height: `${$('.imageList button:first-child').width()}px`,
      };
    } else {
      return undefined;
    }
  }

  imageProcessing: boolean = false;
  onFileUpload(event) {
    this.imageProcessing = true;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);

      // this.service.employeeExtUploadPicture(this.userInfo.Id, formData).subscribe(
      //   (data: any) => {
      //     this.userInfo.Picture = data.Picture + '?random+\=' + Math.random();
      //     this.refUser.imageUrl = data.Picture + '?random+\=' + Math.random();
      //     this.imageProcessing = false;
      //   },
      //   (err: any) => {
      //     this.imageProcessing = false;
      //     this.coreService.toasterMessage('error', 'company', err.Message);
      //   })
    }
  }

  url_to_entry(url) {
    return new Promise((resolve, reject) =>
      window.resolveLocalFileSystemURL(url, resolve, reject)
    );
  }

  async read_file_binary(file_entry) {
    return new Promise((resolve, reject) => {
      file_entry.file((file) => {
        let reader: any = new FileReader();
        reader.onloadend = (e: any) => {
          console.log('result', reader.result);
          var blob = new Blob([new Uint8Array(reader.result)], {
            type: 'image/png',
          });
          console.log('blob', blob);
          resolve(blob);
        };
        reader.readAsArrayBuffer(file);
      });
    });
  }

  async localUriToFile(imageUri: any) {
    const blob: any = this.localUriToBlob(imageUri);
    const file = new File([blob], 'image.jpg', { type: blob.type });
    console.log('file', file);
    return file;
  }

  async localUriToBlob(imageUri: any) {
    console.log('imageUri', imageUri);
    const entry: any = await this.url_to_entry(imageUri);
    console.log('entry', entry);
    const blob: any = await this.read_file_binary(entry);
    console.log('blob', blob);
    return blob;
  }

  async openCamera(type: string) {
    const options: CameraOptions = {
      quality: 60,
      targetWidth: 800,
      targetHeight: 600,
      correctOrientation: true,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType:
        type == 'gallery'
          ? this.camera.PictureSourceType.PHOTOLIBRARY
          : this.camera.PictureSourceType.CAMERA,
    };

    this.camera.getPicture(options).then(
      async (imageData) => {
        console.log('imageData', imageData);
        const file: any = await this.localUriToBlob(imageData);
        console.log('localUriToFile', file);
        let formData: FormData = new FormData();
        formData.append('uploadFile', file, 'prova.png');
        const data: any = await this.sharedService
          .onAddImage(formData)
          .toPromise();
        console.log('onAddImage => ', JSON.stringify(data));
        const urlImage = this.coreService.getBaseURL() + data?.ImageUrl;
        console.log(urlImage);
        this.onCloseModal.next(urlImage);
        this.bsModalRef.hide();
      },
      (err) => {
        // Handle error
        console.log(err);
      }
    );
  }

  onAddImage(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let selectedLocalImage: File = fileList[0];
      this.onClose.next(selectedLocalImage);
      this.bsModalRef.hide();
    }
  }
}
