import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[MinNumber]',
})
export class MinNumberDirective {
  constructor(private el: ElementRef) {}

  @Input() MinNumber: number;

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    let e = <KeyboardEvent>event;
    console.log(event);
    if (this.MinNumber) {
      if (
        [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (
        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105)
      ) {
        e.preventDefault();
      }

      console.log(Number(e.key), Number((<any>e.srcElement).value.toString()));
      if (
        Number(e.key) < this.MinNumber ||
        Number((<any>e.srcElement).value.toString()) < this.MinNumber
      ) {
        e.preventDefault();
      }
    }
  }
}
