import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageComponent } from './manage/manage.component';
import { FiltersComponent } from './manage/filters/filters.component';
import { AboutComponent } from './about/about.component';
import { CycleManagerComponent } from './cycle-manager.component';
import { SharedModule } from 'app/shared/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { LanguageSelectorModule } from 'app/components/common/language-selector/language-selector.module';
import { OkrDashboardComponent } from './okr-dashboard/okr-dashboard.component';
import { OkrProgressWidgetComponent } from './okr-dashboard/okr-progress-widget/okr-progress-widget.component';

import { NouisliderModule } from 'ng2-nouislider';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { Pp360ComponentsModule } from 'app/pp360-components/pp360-components.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CycleManagerService } from './cycle-manager.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SlickCarouselModule,
    AngularMultiSelectModule,
    LanguageSelectorModule,
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    NouisliderModule,
    Pp360ComponentsModule,
  ],
  declarations: [
    CycleManagerComponent,
    ManageComponent,
    FiltersComponent,
    AboutComponent,
    OkrDashboardComponent,
    OkrProgressWidgetComponent,
  ],
  exports: [
    CycleManagerComponent,
    ManageComponent,
    FiltersComponent,
    AboutComponent,
  ],
  providers: [CycleManagerService],
})
export class CycleManagerModule {}
