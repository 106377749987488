import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360DropdownComponent } from './pp360-dropdown.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [Pp360DropdownComponent],
  imports: [CommonModule, AngularMultiSelectModule, FormsModule],
  exports: [Pp360DropdownComponent],
})
export class Pp360DropdownModule {}
