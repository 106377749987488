import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CompanyReportService } from 'app/company-report/company-report.service';
import { CoreService } from 'app/shared/services/core.service';
import * as moment from 'moment';

@Component({
  selector: 'app-defer-dialog',
  templateUrl: './defer-dialog.component.html',
  styleUrls: [
    './defer-dialog.component.scss',
    '../../common_styles.scss',
    '../../magic-checkbox.scss',
  ],
})
export class DeferDialogComponent implements OnInit {
  public onClose: Subject<any>;
  objective: any;
  userInfo: any;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  deferralType: string;
  cycleList: any[] = [];
  amsd_settings_cycles: any;
  selectedCycle: any;
  deferDueDate: any;

  public constructor(
    private _bsModalRef: BsModalRef,
    private companyReportService: CompanyReportService,
    private coreService: CoreService
  ) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
    this.deferralType = 'Extend';
  }

  doSaveDate(e) {
    this.deferDueDate = moment(new Date(e)).format('YYYY-MM-DDThh:mm:ss');
  }

  public showModal(content: any): void {
    this.objective = content.objective;
    this.userInfo = content.userInfo;

    this.amsd_settings_cycles = {
      text: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.NoCycleSelected'
      ),
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      enableFilterSelectAll: false,
      classes: 'amsdDropdown regular',
      showCheckbox: false,
      singleSelection: true,
      position: 'bottom',
      autoPosition: false,
    };

    this.dpConfig.dateInputFormat =
      this.userInfo.language === 'it' ? 'D MMM, YYYY' : 'MMM D, YYYY';
    this.dpConfig.containerClass = 'magda_datepicker_theme';
    this.dpConfig.showWeekNumbers = false;

    this.companyReportService
      .getDeferredCycles(this.objective.Id)
      .subscribe((cycleList: any) => {
        if (cycleList && cycleList.length > 0) {
          this.cycleList = [];
          cycleList.forEach((cycle: any) => {
            cycle.id = cycle.Id;
            cycle.itemName = cycle.Name;
            this.cycleList.push(cycle);
          });
        }
      });
  }

  deferring: boolean = false;
  public onConfirm(): void {
    this.deferring = true;
    this.onClose.next({
      deferr: true,
      DueDate: this.deferDueDate,
      DeferralType: this.deferralType,
      selectedCycleId: this.selectedCycle[0].Id,
    });
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next({ deferr: false });
    this._bsModalRef.hide();
  }

  onSelectCycle(event: any) {
    this.deferDueDate = moment(new Date(event.EndDate)).format(
      'YYYY-MM-DDThh:mm:ss'
    );
  }
}
