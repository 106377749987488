import { Injectable, TemplateRef } from '@angular/core';
import { RestfulService } from './restful.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Injectable()
export class SharedService {
  constructor(
    public restfulWebService: RestfulService,
    private translateService: TranslateService
  ) {}

  getLocalDate(date): any {
    const local = moment(date).local().format('YYYY-MM-DD HH:mm:ss');
    return local;
  }

  getSkills(reqBody) {
    return this.restfulWebService.post(`PatFeed/PatSkills`, reqBody);
  }
  getBehaviors(reqBody) {
    return this.restfulWebService.post(`PatFeed/PatBehaviors`, reqBody);
  }
  getGoals(reqBody) {
    return this.restfulWebService.post(`PatFeed/PatGoals`, reqBody);
  }

  getCompanyActivities(reqBody) {
    return this.restfulWebService.post(`PatFeed/Activities`, reqBody);
  }

  onAddImage(image) {
    return this.restfulWebService.fileUpload(`PatFeed/Photo`, image);
  }

  onAddNewsImage(image) {
    return this.restfulWebService.fileUpload(`PatFeed/NewsImage`, image);
  }

  newPatFeed(reqBody) {
    return this.restfulWebService.post(`PatFeed`, reqBody);
  }

  updatePatFeed(reqBody) {
    return this.restfulWebService.put(`PatFeed/Pat`, reqBody);
  }

  getFilterEmployees(ID, type, mode) {
    return this.restfulWebService.get(
      `Wall/Targets?type=${type}&mode=${mode}&employeeId=${ID}`
    );
  }

  // getFeedbackEmployees(Id){return this.restfulWebService.get(`EvaluationFeed/Targets?employeeId=${Id}`)}

  // getTokenEmployees(employeeId){ return this.restfulWebService.get(`TokenFeed/Targets?employeeId=${employeeId}`);}

  evaluationSkills(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `EvaluationFeed/Skills?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }
  evaluationBehaviors(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `EvaluationFeed/Behaviors?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }

  evaluationGoals(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `EvaluationFeed/Goals?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }

  evaluationActivities(employeeId) {
    return this.restfulWebService.get(
      `EvaluationFeed/Activities?employeeId=${employeeId}`
    );
  }

  postEvaluation(postEval) {
    return this.restfulWebService.post(`EvaluationFeed`, postEval);
  }
  updateEvaluation(postEval) {
    return this.restfulWebService.put(`EvaluationFeed`, postEval);
  }

  getTokenSkills(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `TokenFeed/Skills?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }
  getTokenBehaviors(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `TokenFeed/Behaviors?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }
  getTokenGoals(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `TokenFeed/Goals?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }

  getTokenActivities(employeeId) {
    return this.restfulWebService.get(
      `TokenFeed/Activities?employeeId=${employeeId}`
    );
  }

  postAToken(reqBody) {
    return this.restfulWebService.post(`TokenFeed`, reqBody);
  }

  updateTokenFeed(reqBody) {
    return this.restfulWebService.put(`TokenFeed/`, reqBody);
  }

  getAskskills(reqBody) {
    return this.restfulWebService.post(`AskFeed/AskSkills`, reqBody);
  }

  getAskBehaviors(reqBody) {
    return this.restfulWebService.post(`AskFeed/AskBehaviors`, reqBody);
  }

  askEval(newAsk) {
    return this.restfulWebService.post(`AskFeed`, newAsk);
  }

  getMyGrowth(Id) {
    return this.restfulWebService.get(
      `PersonalReport/MyGrowth?employeeId=${Id}`
    );
  }

  getStoryData(Id) {
    return this.restfulWebService.get(`Story/Data?employeeId=${Id}`);
  }
  getPerformaceStoryData(Id) {
    return this.restfulWebService.get(`Story/PerformanceData?employeeId=${Id}`);
  }

  selfEvaluation(reqbody) {
    return this.restfulWebService.post(`Story/SelfEvaluate`, reqbody);
  }

  addNewSkill(reqbody) {
    return this.restfulWebService.post(`Story/AddSkill`, reqbody);
  }

  removePersonalSkill(Id) {
    return this.restfulWebService.delete(`Story/DeleteSkill?id=${Id}`);
  }

  /* Work Experience */

  addNewPosition(reqbody) {
    return this.restfulWebService.post(`Story/AddPosition`, reqbody);
  }

  removePosition(Id) {
    return this.restfulWebService.delete(`Story/DeletePosition?id=${Id}`);
  }

  updatePosition(reqbody) {
    return this.restfulWebService.put(`Story/UpdatePosition`, reqbody);
  }

  /* Education */

  addNewEducation(reqbody) {
    return this.restfulWebService.post(`Story/AddEducation`, reqbody);
  }

  removeEducation(Id) {
    return this.restfulWebService.delete(`Story/DeleteEducation?id=${Id}`);
  }

  updateEducation(reqbody) {
    return this.restfulWebService.put(`Story/UpdateEducation`, reqbody);
  }

  /* Volunteer */

  updateVolunteer(reqbody) {
    return this.restfulWebService.put(
      `Story/UpdateVolunteerExperience`,
      reqbody
    );
  }

  addNewVolunteer(reqbody) {
    return this.restfulWebService.post(`Story/AddVolunteerExperience`, reqbody);
  }

  removeVolunteer(Id) {
    return this.restfulWebService.delete(
      `Story/DeleteVolunteerExperience?id=${Id}`
    );
  }

  /* Achievement */

  updateAchievement(reqbody) {
    return this.restfulWebService.put(`Story/UpdateAchievement`, reqbody);
  }

  addNewAchievement(reqbody) {
    return this.restfulWebService.post(`Story/AddAchievement`, reqbody);
  }

  removeAchievement(Id) {
    return this.restfulWebService.delete(`Story/DeleteAchievement?id=${Id}`);
  }

  /* Language */

  updateLanguage(reqbody) {
    return this.restfulWebService.put(`Story/UpdateEmployeeLanguage`, reqbody);
  }

  addNewLanguage(reqbody) {
    console.log(reqbody);
    return this.restfulWebService.post(`Story/AddEmployeeLanguage`, reqbody);
  }

  removeLanguage(Id) {
    return this.restfulWebService.delete(
      `Story/DeleteEmployeeLanguage?languageCode=${Id}`
    );
  }

  /* Static Collections */

  getLocations() {
    return this.restfulWebService.get(`Story/Locations`);
  }

  getAvailableSchools() {
    return this.restfulWebService.get('Story/AvailableSchools');
  }

  getAvailableLanguages() {
    return this.restfulWebService.get('Story/AvailableLanguages');
  }

  getAvailableOrganizations() {
    return this.restfulWebService.get('Story/AvailableOrganizations');
  }

  getAvailableCompanies() {
    return this.restfulWebService.get('Story/AvailableCompanies');
  }

  /* Activities */

  getActivities(PAGESTART, PAGESIZE) {
    return this.restfulWebService.get(
      `Story/Activities?pageStart=${PAGESTART}&pageSize=${PAGESIZE}`
    );
  }
  getAllActivities() {
    return this.restfulWebService.get(`Story/Activities`);
  }

  /* Hobbies & Interests */

  getHobbies() {
    return this.restfulWebService.get('Story/AvailableHobbies');
  }

  addNewHobby(reqbody) {
    return this.restfulWebService.post(`Story/AddHobby`, reqbody);
  }

  updateHobby(reqbody) {
    return this.restfulWebService.put(`Story/UpdateHobby`, reqbody);
  }

  removeHobby(Id) {
    return this.restfulWebService.delete(`Story/DeleteHobby?id=${Id}`);
  }

  /* Personal Skills */

  getAvailableSkills() {
    return this.restfulWebService.get('Story/AvailableSkills');
  }

  removeSkill(Id) {
    return this.restfulWebService.delete(`Story/DeleteSkill?id=${Id}`);
  }

  /* Employees */
  getEmployees() {
    return this.restfulWebService.get(`Cycles/Employees`);
  }

  getEmployeesTree() {
    return this.restfulWebService.get(`Cycles/Employees?asTree=true`);
  }

  /* Objectives */
  getObjectives(employeeId: string) {
    return this.restfulWebService.get(
      `Story/AllObjectives?employeeId=${employeeId}`
    );
  }

  formatNumber(x: any, decimal: number = 2, currency: boolean = false) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    if (currency)
      return Number(value).toLocaleString(this.translateService.currentLang, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    else
      return Number(value).toLocaleString(this.translateService.currentLang, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
  }
}
