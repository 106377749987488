import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AdminProfileChangeComponent } from 'app/shared/components/admin-profile-change/admin-profile-change.component';
import { GiveTokenComponent } from 'app/shared/components/give-token/give-token.component';
import { SharedService } from 'app/shared/services/shared.service';
import { NewPatComponent } from 'app/shared/components/new-pat/new-pat.component';
import { ChoicePopUpComponent } from 'app/shared/components/choice-pop-up/choice-pop-up.component';
import { NewsDialogComponent } from '../../../employee/wall/news-dialog/news-dialog.component';

@Component({
  selector: 'footer',
  templateUrl: 'footer.template.html',
  styleUrls: [`./footer.template.scss`],
})
export class FooterComponent implements OnInit {
  public userInfo: any;

  @Input() the_Wall: boolean = true;
  @Input() active_Pat: boolean = true;
  @Input() active_Evaluation: boolean = false;
  @Input() active_Token: boolean = false;
  @Input() toRefresh: boolean = false;

  @Output() valueChange = new EventEmitter();
  @Output() refreshRequired = new EventEmitter();
  @Output() newPatData = new EventEmitter();
  @Output() employeeList = new EventEmitter();

  employeesList: any;
  all_Employees_Teams: any;
  evaluation_Employees: any;
  footerWidth: boolean;
  patData: any;
  enablePatButton: boolean = false;
  enableTokenButton: boolean = false;
  enableFeedbackButton: boolean = false;
  availableTokens: any = 0;

  bsModalRef: BsModalRef;

  showHide: boolean = false;
  isShowPublished: boolean = false;
  isShowEliminated: boolean = false;
  publishedType: any;
  eliminatedType: any;

  constructor(
    private coreService: CoreService,
    private route: Router,
    private modalService: BsModalService,
    public sharedService: SharedService
  ) {
    this.coreService.userInfoChanged.subscribe((arg) =>
      this.manageUserInfoChanged(arg)
    );
    this.coreService.getShowPublished().subscribe((arg) => {
      if (arg) {
        this.showPublished(arg);
      }
    });
    this.coreService.getShowEliminated().subscribe((arg) => {
      if (arg) {
        this.ShowEliminated(arg);
      }
    });
    this.userInfo = this.coreService.getUserInfo();
    // console.log(this.userInfo);
    if (this.userInfo) {
      this.availableTokens = this.userInfo.availableTokens;
    }
  }

  showPublished(type) {
    this.publishedType = type;
    this.isShowPublished = true;
    setTimeout(() => {
      this.isShowPublished = false;
      this.coreService.setShowPublished(null);
      this.publishedType = '';
    }, 5000);
  }

  ShowEliminated(type) {
    this.eliminatedType = type;
    this.isShowEliminated = true;
    setTimeout(() => {
      this.isShowEliminated = false;
      this.coreService.setShowEliminated(null);
      this.eliminatedType = '';
    }, 5000);
  }

  manageUserInfoChanged(arg) {
    this.userInfo = this.coreService.getUserInfo();
  }

  ngDoCheck() {
    this.footerWidth = this.coreService.showMenuWidth;
  }
  ddd: any;
  ngOnInit() {
    this.getEmployee();
    this.getList();
    this.getEmployeesForEvaluation();
    if (this.active_Evaluation == true) {
      this.active_Pat = false;
      this.active_Token = false;
    }
    if (this.active_Token == true) {
      this.active_Evaluation = false;
      this.active_Pat = false;
    }
    // console.log(this.publishedType.type);
  }

  getEmployee() {
    this.enableTokenButton = false;
    this.sharedService
      .getFilterEmployees(this.userInfo.linkedEmployeeId, 'Token', 'List')
      .subscribe(
        (data: any) => {
          this.employeesList = data;
          this.enableTokenButton = true;
        },
        (err: any) => {
          console.log('errr');
        }
      );
  }

  getList() {
    this.enablePatButton = false;
    this.sharedService
      .getFilterEmployees(this.userInfo.linkedEmployeeId, 'Pat', 'List')
      .subscribe(
        (data: any) => {
          data.forEach((element: any) => {
            if (element.Id && element.Id.indexOf(':') < 0) {
              if (element.Type == 'Team') {
                element['Name'] = element.Name + ' Team';
                element['Id'] = 't:' + element['Id'];
              } else {
                element['Name'] = element.Name;
                element['Id'] = 'e:' + element['Id'];
              }
            }
          });
          this.all_Employees_Teams = data;
          this.employeeList.emit(data);
          this.enablePatButton = true;
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
  }

  getEmployeesForEvaluation() {
    this.enableFeedbackButton = false;
    this.sharedService
      .getFilterEmployees(this.userInfo.linkedEmployeeId, 'Feedback', 'List')
      .subscribe(
        (data: any) => {
          this.evaluation_Employees = data;
          this.enableFeedbackButton = true;
        },
        (err: any) => {
          console.log('err');
        }
      );
  }

  onProfileView() {
    this.bsModalRef = this.modalService.show(AdminProfileChangeComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.userInfo = this.userInfo;
  }

  getNewPats() {
    if (this.route.url === '/Manager/dashboard') {
      this.bsModalRef = this.modalService.show(NewPatComponent, {
        class: 'wallPopup manager-deshboard-popup',
        ignoreBackdropClick: true,
      });
    } else {
      this.bsModalRef = this.modalService.show(NewPatComponent, {
        class: 'wallPopup',
        ignoreBackdropClick: true,
      });
    }

    this.bsModalRef.content.all_Employees_Teams = this.all_Employees_Teams;
    this.bsModalRef.content.closeBtnName = 'Close';

    (<NewPatComponent>this.bsModalRef.content).refreshWallRequired.subscribe(
      (newPatData: boolean) => {
        this.refreshWall(newPatData);
        (<NewPatComponent>(
          this.bsModalRef.content
        )).refreshWallRequired.unsubscribe();
      }
    );
    this.showHide = false;
  }

  getTokenPopUps() {
    if (this.availableTokens !== 0) {
      if (this.route.url === '/Manager/dashboard') {
        this.bsModalRef = this.modalService.show(GiveTokenComponent, {
          class: 'wallPopup manager-deshboard-popup2',
          ignoreBackdropClick: true,
        });
      } else {
        this.bsModalRef = this.modalService.show(GiveTokenComponent, {
          class: 'wallPopup',
          ignoreBackdropClick: true,
        });
      }
      this.bsModalRef.content.employeesList = this.all_Employees_Teams;
      this.bsModalRef.content.closeBtnName = 'Close';
      this.showHide = false;
    }
  }

  getNewsPopUps() {
    let modal;
    if (this.route.url === '/Manager/dashboard') {
      modal = this.modalService.show(NewsDialogComponent, {
        class: 'wallPopup manager-deshboard-popup3',
        ignoreBackdropClick: true,
      });
    } else {
      modal = this.modalService.show(NewsDialogComponent, {
        class: 'wallPopup',
        ignoreBackdropClick: true,
      });
    }
    modal.content.initModal(null);
    (<NewsDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.refreshWall(this.patData);
        }
      }
    );
    this.showHide = false;
  }

  choicePop() {
    if (this.route.url === '/Manager/dashboard') {
      this.bsModalRef = this.modalService.show(ChoicePopUpComponent, {
        class: 'choicePopUp ammazza wallPopup manager-deshboard-popup1',
        ignoreBackdropClick: true,
        initialState: {
          evaluation_Employees: this.evaluation_Employees || [],
        },
      });
    } else {
      this.bsModalRef = this.modalService.show(ChoicePopUpComponent, {
        class: 'choicePopUp ammazza wallPopup',
        ignoreBackdropClick: true,
        initialState: {
          evaluation_Employees: this.evaluation_Employees || [],
        },
      });
    }
    this.bsModalRef.content.postEval.subscribe((data: any) => {
      this.refreshWall(data);
      this.bsModalRef.hide();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.showHide = false;
  }

  refreshWall(data) {
    this.refreshRequired.emit(data);
  }

  onWall() {
    this.route.navigate(['/User/wall/feed']);
  }

  onclick() {
    $('body').removeClass('mini-navbar');
    if (this.showHide == true) {
      this.showHide = false;
    } else {
      this.showHide = true;
    }
  }
}
