import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.css', '../common-styles.scss'],
})
export class ObjectivesComponent implements OnInit {
  @Input() plan: any;
  @Input() userId: any;

  showDetail: any[] = [];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    console.log('plan', this.plan);
  }

  getYears() {
    return new Array(this.plan.YearEnd - this.plan.YearStart + 1)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  hasDetail(type: string) {
    return (
      this.plan[type].filter((item: any) => item.Name).length > 0 || undefined
    );
  }

  /*************************************************************** */
  getObjectiveValue(obj: any, year: number) {
    let n = 0;
    let y = obj.YearlyValues.find((x) => x.Year == year);
    if (y) n = y.TargetValue;

    return n;
  }

  /*************************************************************** */
  getObjectSensivity(obj) {
    if (obj.IsCorridor) {
      return obj.Floor + '-' + obj.Cap + '%';
    } else {
      return '';
    }
  }

  /*************************************************************** */
  getObjectTargetThreshold(obj) {
    if (obj.IsCorridor) {
      if (obj.Measure.MeasureType == 'scale')
        return obj.Min + '/' + obj.Max + 'Δ';
      else return obj.Min + '-' + obj.Max + '%';
    } else {
      return '';
    }
  }

  /*************************************************************** */
  getMeasure(obj) {
    let m = '';
    if (obj.Measure.MeasureType == 'money') {
      if (obj.Currency) m += obj.Currency;
      if (obj.Format) m += ' ' + obj.Format.Name + '';
    } else if (obj.Measure.MeasureType == 'percentage') {
      m = '%';
    } else if (obj.Measure.MeasureType == 'numeric') {
      if (obj.Format) m += ' ' + obj.Format.Name + '';
    } else if (obj.Measure.MeasureType == 'scale') {
      if (obj.Scale) m += ' ' + obj.Scale.Name + '';
    }
    return m;
  }
}
