import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pp360-text-input',
  templateUrl: './pp360-text-input.component.html',
  styleUrls: ['./pp360-text-input.component.css'],
})
export class Pp360TextInputComponent implements OnInit, OnChanges {
  onInputChange: Subject<any> = new Subject();
  @ViewChild('input') input: ElementRef;
  @Input() debounceTime: number = 500;
  @Input() value: string;
  @Input() placeholder: string;
  @Output() textchange = new EventEmitter();
  @Output() isTranslate = new EventEmitter();
  @Input() languageTranslation = false;
  @Input() showTranslation = false;

  constructor() {}

  ngOnInit(): void {
    this.onInputChange.pipe(debounceTime(this.debounceTime)).subscribe(() => {
      this.textchange.emit(this.input.nativeElement.value);
    });
  }

  ngOnChanges() {}

  onKeyup() {
    this.onInputChange.next(true);
  }

  translate() {
    this.isTranslate.emit(this.languageTranslation);
  }
}
