import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlansComponent } from './plans/plans.component';
import { ResultsComponent } from './results/results.component';
import { SharedModule } from '../shared/shared.module';
import { AdminLtiService } from './admin-lti.service';
import { AdminLTIRouting } from './admin-lti.routing';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { GatewayComponent } from './plans/gateway/gateway.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SummaryBoxComponent } from './plans/summary-box/summary-box.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AdminLTIRouting,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
  ],
  providers: [AdminLtiService],
  entryComponents: [HelpDialogComponent],
  declarations: [
    PlansComponent,
    ResultsComponent,
    HelpDialogComponent,
    GatewayComponent,
    SummaryBoxComponent,
  ],
})
export class AdminLtiModule {}
