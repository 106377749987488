import {
  ElementRef,
  Directive,
  Input,
  TemplateRef,
  Renderer2,
  Injector,
  ViewContainerRef,
  NgZone,
  OnInit,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { PopoverConfig, PopoverDirective } from 'ngx-bootstrap/popover';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';
@Directive({
  selector: '[stickyPopover]',
  exportAs: 'stickyPopover',
})
export class StickyPopoverDirective
  extends PopoverDirective
  implements OnInit, OnDestroy
{
  @Input() stickyPopover: TemplateRef<any>;

  popoverTitle: string;

  placement: 'top' | 'bottom' | 'left' | 'right' | 'auto';

  triggers: string;
  container: string;
  popover: TemplateRef<any>;
  canClosePopover: boolean;
  containerClass: string;
  adaptivePosition: boolean;

  toggle(): void {
    super.toggle();
  }

  constructor(
    private _elRef: ElementRef,
    private _render: Renderer2,
    componentLoaderFactory: ComponentLoaderFactory,
    viewContainerRef: ViewContainerRef,
    _positionService: PositioningService,
    config: PopoverConfig,
    @Inject(DOCUMENT) _document: any
  ) {
    super(
      config,
      _elRef,
      _render,
      viewContainerRef,
      componentLoaderFactory,
      _positionService
    );
    this.triggers = 'manual';
    this.popoverTitle = '';
    this.container = 'body';
    this.containerClass = '';
    this.adaptivePosition = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.popover = this.stickyPopover;
    this._render.listen(this._elRef.nativeElement, 'mouseenter', () => {
      this.canClosePopover = true;
      this.open();
    });

    this._render.listen(
      this._elRef.nativeElement,
      'mouseleave',
      (event: Event) => {
        setTimeout(() => {
          if (this.canClosePopover) {
            this.close();
          }
        }, 100);
      }
    );

    this._render.listen(this._elRef.nativeElement, 'click', () => {
      this.close();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  open() {
    super.show();
    setTimeout(() => {
      const popover = window.document.querySelector('.popover');
      const scrollId = window.document.querySelector('#scrolling');
      if (popover) {
        this._render.listen(popover, 'mouseover', () => {
          this.canClosePopover = false;
        });

        this._render.listen(popover, 'mouseout', () => {
          this.canClosePopover = true;
          setTimeout(() => {
            if (this.canClosePopover) {
              this.close();
            }
          }, 0);
        });
        this._render.listen(popover, 'click', () => {
          this.canClosePopover = true;
          setTimeout(() => {
            if (this.canClosePopover) {
              this.close();
            }
          }, 0);
        });
        if (scrollId) {
          this._render.listen(scrollId, 'scroll', () => {
            this.canClosePopover = true;
            setTimeout(() => {
              if (this.canClosePopover) {
                this.close();
              }
            }, 0);
          });
        }
      }
    }, 0);
  }

  close() {
    super.hide();
  }
}
