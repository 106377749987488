import { Component, OnInit, Input, Output } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { EmployeeDialogComponent } from 'app/shared/employee-dialog/employee-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: [
    './about.component.scss',
    '../../../../common_styles.scss',
    '../../../../magic-checkbox.scss',
  ],
})
export class AboutComponent implements OnInit {
  @Input() cycle: any;
  @Input() selectedLang: string;
  allCategories: any;
  currentUserInfo: any;

  slideConfig: any = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
  };

  publicCaption: string;
  limitedCaption: string;
  privateCaption: string;
  reducedCaption: string;
  isSuperAdmin: boolean = false;
  isCompanyAdmin: boolean = false;
  iamManager: boolean = false;

  constructor(
    private coreService: CoreService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    console.log(this.cycle.CycleInfo);
    this.allCategories = [];

    this.currentUserInfo = this.coreService.getUserInfo();
    this.iamManager =
      this.currentUserInfo.isManager || this.currentUserInfo.isEvaluator;
    this.isSuperAdmin = this.currentUserInfo.roles.indexOf('SuperAdmin') >= 0;
    this.isCompanyAdmin =
      this.currentUserInfo.roles.indexOf('CompanyAdmin') >= 0;
    this.publicCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Public'
    );
    this.limitedCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Limited'
    );
    this.privateCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Private'
    );
    this.reducedCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Reduced'
    );

    if (this.cycle.GlobalCategories) {
      let array = this.cycle.GlobalCategories.filter(
        (category: any) => category.IsSelected
      );
      array.forEach((e: any) => {
        this.allCategories.push({
          Picture: e.Picture,
          Name: e.Name,
          Description: e.Description,
          //Name: e.Name[this.selectedLang].Text,
          //Description: e.Description[this.selectedLang].Text
        });
      });
    }

    if (this.cycle.CustomCategories) {
      this.cycle.CustomCategories.forEach((e: any) => {
        this.allCategories.push({
          Picture: e.Picture,
          Name: e.Name,
          Description: e.Description,
          //Name: e.Name[this.selectedLang].Text,
          //Description: e.Description[this.selectedLang].Text
        });
      });
    }
    let lang = this.currentUserInfo.language;
    this.allCategories.sort((a, b) =>
      a.Name[lang].Text.localeCompare(b.Name[lang].Text)
    );
    this.cycle.Partecipants.sort((a, b) => a.Name.localeCompare(b.Name));
    this.cycle.Teams.sort((a, b) => a.Name.localeCompare(b.Name));
    this.cycle.Evaluators.sort((a, b) => a.Name.localeCompare(b.Name));
    console.log('this.allCategories', this.allCategories);
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {}

  beforeChange(e) {}

  showPartecipants() {
    let selected = this.cycle.Partecipants;

    const content: any = {
      YesCaption: this.coreService.getTranslation('CONFIRM'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('Messages.SureDelete'),
      selected: JSON.parse(JSON.stringify(selected)) || [],
      externalFilter: JSON.parse(JSON.stringify(selected)),
      readonly: true,
    };

    console.log(content);

    const modal = this.modalService.show(EmployeeDialogComponent, {
      class: 'employee-selection-dialog',
    });
    (<EmployeeDialogComponent>modal.content).showModal(content);
    (<EmployeeDialogComponent>modal.content).onClose.subscribe(
      (result: any) => {
        if (result.success) {
          console.log('EmployeeDialogComponent result', result);
          this.cycle.LimitedViewer = [];
          result.selected.forEach((viewer: any) => {
            this.cycle.LimitedViewer.push({
              Id: viewer.Id,
              Name: viewer.Name,
              Picture: viewer.Picture,
            });
          });
        }
      }
    );
  }

  getMidNight(d) {
    let endDate = new Date(d);
    endDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59
    );
    return endDate.getTime();
  }

  getIcon(period: any) {
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    let startPeriodTime = 0;
    let endPeriodTime = 0;

    let periodStart = new Date(period.Start).getTime();
    let periodEnd = this.getMidNight(period.End);

    if (period.Start) {
      if (todayTime >= periodStart && todayTime <= periodEnd)
        return 'fa fa-2x fa-clock-o medium';
      if (todayTime > periodEnd) return 'fa fa-2x fa-hourglass-end medium';
    } else if (period.End) {
      if (todayTime <= periodEnd) return 'fa fa-2x fa-clock-o medium';
    }
  }

  inDefinition(cycle: any) {
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    let definitionStart = new Date(cycle.DefineDateFrom).getTime();
    let definitionEnd = this.getMidNight(cycle.DefineDateTo);
    return todayTime >= definitionStart && todayTime <= definitionEnd;
  }

  inExecution(cycle: any) {
    let periodStart = new Date(cycle.AchieveDateFrom).getTime();
    let periodEnd = new Date(cycle.AchieveDateTo).getTime();
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    return todayTime >= periodStart && todayTime <= periodEnd;
  }

  inEvaluation(cycle: any) {
    let evaluationStart = new Date(cycle.EvaluateDateFrom).getTime();
    let evaluationEnd = this.getMidNight(cycle.EvaluateDateTo);
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    return todayTime >= evaluationStart && todayTime <= evaluationEnd;
  }

  inAgree(cycle: any) {
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    const endPeriodTime = new Date(cycle.AgreedDate).getTime();

    // Fase non ancora iniziata
    if (endPeriodTime > todayTime) {
      //return 'fa-hourglass-end';
    } else {
      //return 'fa-clock-o'
    }

    return endPeriodTime > todayTime;
  }
}
