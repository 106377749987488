import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CoreService } from 'app/shared/services/core.service';
import { PatprizesService } from '../patprizes.service';
import { CustomImageCropperComponent } from 'app/shared/components/custom-image-cropper/custom-image-cropper.component';
import { ImageSelectorComponent } from 'app/company-report/objectives-individual/objective/obj-sheet-popup/image-selector/image-selector.component';
import { base64ToFile } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { SharedService } from 'app/shared/services/shared.service';

interface JQuery {
  textareaAutoSize(): void;
}

@Component({
  selector: 'app-patprizelibraryitemdetails',
  templateUrl: './patprizelibraryitemdetails.component.html',
  styleUrls: [
    './patprizelibraryitemdetails.component.scss',
    '../../../common_styles.scss',
  ],
})
export class PatPrizeLibraryItemDetailsComponent implements OnInit {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  content: any;
  selectedLang: string = 'en';
  onClose: Subject<any>;
  defaultLanguage: any;
  viewOnly: boolean = false;
  croppedImage: any = '';
  finalCroppedImage: any;
  optionalPhotoData: any;
  isUploading: boolean = false;
  newPatData: any = {};
  ischangeImg: boolean = false;
  currentFile: any;
  finalName: any;

  constructor(
    public bsModalRef: BsModalRef,
    private coreService: CoreService,
    private patprizesService: PatprizesService,
    private sanitizer: DomSanitizer,
    private pp360SpinnerService: Pp360SpinnerService,
    private modalService: BsModalService,
    public sharedService: SharedService
  ) {
    this.onClose = new Subject();
    let userInfo = this.coreService.getUserInfo();
    this.defaultLanguage = userInfo.defaultlanguage;
    this.selectedLang = userInfo.language;
  }

  ngOnInit(): void {}

  showModal(content: any, viewOnly: boolean = false): void {
    this.content = content;
    this.viewOnly = viewOnly;
    // if (this.content.languages && this.content.languages.length > 0) {
    //   this.selectedLang = this.content.languages[0].Code;
    // }
    let userInfo = this.coreService.getUserInfo();
    this.selectedLang = userInfo.language;
    setTimeout(() => {
      (<any>$('textarea')).textareaAutoSize();
    });
  }

  changeLanguage(languageCode: string) {
    this.selectedLang = languageCode;

    setTimeout(() => {
      (<any>$('textarea')).textareaAutoSize();
    });
  }
  /* /// Hide To API ISSUE ///
  getPatPrizeImage() {
    if (this.content.item.Image) {
      const urlSegments = this.content.item.Image.split('/');
      const lastSegment = urlSegments[urlSegments.length - 1];
      const imageName = lastSegment.split('?')[0];
      this.finalName = imageName;
      return {
        'background-image': `url('${this.content.item.Image}')`,
        'background-color': `var(--feed-background)`,
        border: `none`,
      };
    } else {
      return undefined;
    }
  }

  getImageSize(file) {
    return file ? (file.size / (1024 * 1024)).toFixed(2) : 0;
  }
  */
  isDone() {
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(base64ToFile(this.croppedImage))
    );
    this.finalCroppedImage = url;
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  /* /// Hide To API ISSUE ///

  cropImage(file) {
    const cropModalRef = this.modalService.show(CustomImageCropperComponent, {
      class: 'image-change',
    });
    const content = {
      file,
      isRounded: false,
      aspectRatio: 4 / 2,
    };
    (<CustomImageCropperComponent>cropModalRef.content).showModal(content);
    (<CustomImageCropperComponent>cropModalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.croppedImage = result;
          this.uploadImage(file);
          this.isDone();
        }
      }
    );
  }

  onAddImage(event) {
    if (this.newPatData) {
      this.ischangeImg = true;
    }
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.isUploading = true;
      let file: File = fileList[0];
      this.currentFile = file;
      this.finalName = this.currentFile.name;
      this.cropImage(file);
    }
  }

  uploadImage(file) {
    const imageFile = base64ToFile(this.croppedImage);
    let formData: FormData = new FormData();
    formData.append('uploadFile', imageFile, file.name);
    this.pp360SpinnerService.showSpinner(true);
    this.sharedService.onAddImage(formData).subscribe(
      (data: any) => {
        this.optionalPhotoData = data;
        this.content.item.Image =
          this.coreService.getBaseURL() + this.optionalPhotoData.ImageUrl;
        this.pp360SpinnerService.showSpinner(false);
        this.isUploading = false;
      },
      (err: any) => {
        console.log('onAddImage err => ', JSON.stringify(err));
        this.pp360SpinnerService.showSpinner(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isUploading = false;
      }
    );
  }

  selectImage() {
    const content: any = {
      Search: '',
    };
    let modal = this.modalService.show(ImageSelectorComponent, {
      class: 'image-selector',
    });
    (<ImageSelectorComponent>modal.content).showModal(content);
    if (this.newPatData) {
      this.ischangeImg = true;
    }
    (<ImageSelectorComponent>modal.content).onCloseModal.subscribe(
      (result: string) => {
        // console.log('onCloseModal', result);
        if (result) {
          // console.log('onCloseModal', result);
          this.loading$.next(true);
          this.content.item.Image = result;

          setTimeout(() => {
            var image = new Image();
            image.src = result;
            image.onload = () => {
              this.loading$.next(false);
            };
          }, 250);
        }
      }
    );

    (<ImageSelectorComponent>modal.content).onClose.subscribe((result: any) => {
      if (result) {
        this.finalCroppedImage = result.urls.small;
        this.finalName = result.user.name;

        this.content.item.Image = result.urls.small;

        this.loading$.next(true);
        setTimeout(() => {
          var image = new Image();
          image.src = this.content.item.Image;
          image.onload = () => {
            this.loading$.next(false);
          };
        }, 250);
      }
    });

    modal.onHidden.subscribe(() => {
      $('.modal-backdrop').attr('style', 'z-index: 2040 !important');
    });
  }

  async onEdit(item: any) {
    const response = await fetch(item);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
    this.croppedImage = { file, url };
    this.cropImage(file);
  }

  onRemoveImg() {
    this.content.item.Image = '';
    this.ischangeImg = true;
  }
  */
  onSave() {
    // this.content.itemprizesService.savePrize(this.content.item).subscribe(
    //   (res: any) => {
    //     this.onClose.next(true);
    //     this.bsModalRef.hide();
    //   }
    // )

    this.onClose.next(this.content.item);
    this.bsModalRef.hide();
  }

  onCancel() {
    this.onClose.next(undefined);
    this.bsModalRef.hide();
  }

  translate(item, selectedLang, property) {
    let userInfo = this.coreService.getUserInfo();
    const text = item[this.defaultLanguage][property];
    this.coreService
      .translateText(text, this.defaultLanguage, selectedLang)
      .subscribe((result: any) => {
        item[selectedLang][property] = result.TranslatedText;
      });
  }
}
