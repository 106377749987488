import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { PraiseService } from 'app/personal-growth/praise/praise.service';
import { CoreService } from 'app/shared/services/core.service';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-objectives-by-person',
  templateUrl: './table-objectives-by-person.component.html',
  styleUrls: [
    './table-objectives-by-person.component.scss',
    '../tables.scss',
    '../../../../common_styles.scss',
  ],
})
export class TableObjectivesByPersonComponent implements OnInit {
  @Input() dateRange: string;
  @Input() viewMode: string;
  @Input() Mobile: string;

  @Output() OnLoading = new EventEmitter();
  @Output() openComp = new EventEmitter();
  @Output() OnLoaded = new EventEmitter();

  filteredItems: any;
  items: any;
  currentFilter: any;
  userinfo: any;
  subEmp: any;
  @Input() objPerson: any;
  @Input() radioType: any;
  openPop: boolean;

  constructor(
    private praiseService: PraiseService,
    private ref: ChangeDetectorRef,
    public coreService: CoreService,
    public route: Router,
    private pp360SpinnerService: Pp360SpinnerService
  ) {}

  ngOnInit() {
    this.userinfo = this.coreService.getUserInfo();
    // console.log(this.objPerson);
    // console.log(this.dateRange);
    this.reload(this.viewMode, this.dateRange);
  }
  isExpands(itemId: any) {
    for (let i of this.objPerson) {
      if (itemId === i.id) {
        i['isExpanded'] = !i['isExpanded'];
      } else {
        i['isExpanded'] = false;
      }
    }
  }
  openComponent(data) {
    this.subEmp = data;
    this.openComp.next([data, 'skillEmployee']);
  }
  reload(data, dateRange) {
    this.updateCurrentFilter();
    this.OnLoading.emit();
    let filter = JSON.parse(JSON.stringify(this.currentFilter));
    filter.viewMode = data;
    filter.dateRange = dateRange;
    this.pp360SpinnerService.showSpinner(true);
    this.praiseService
      .getItemsPersonsByBehaviorSkills(null, null, filter)
      .subscribe((items: any) => {
        // this.getobjDeatils(items);
        this.OnLoaded.emit();
        this.items = items;
        this.filteredItems = JSON.parse(JSON.stringify(this.items));
        this.pp360SpinnerService.showSpinner(false);
        console.log(this.filteredItems);
        this.filteredItems.sort((a, b) => {
          if (a.CountObjectives === b.CountObjectives) {
            if (a.Pats?.Tot === b.Pats?.Tot) {
              if (a.Feedbacks?.Avg === b.Feedbacks?.Avg) {
                return a.Tokens?.Tot > b.Tokens?.Tot
                  ? -1
                  : a.Tokens?.Tot < b.Tokens?.Tot
                    ? 1
                    : 0;
              }
              return a.Feedbacks?.Avg > b.Feedbacks?.Avg
                ? -1
                : a.Feedbacks?.Avg < b.Feedbacks?.Avg
                  ? 1
                  : 0;
            }
            return a.Pats?.Tot > b.Pats?.Tot
              ? -1
              : a.Pats?.Tot < b.Pats?.Tot
                ? 1
                : 0;
          }
          return a.CountObjectives > b.CountObjectives
            ? -1
            : a.CountObjectives < b.CountObjectives
              ? 1
              : 0;
        });
      });
  }

  getTooltip(t, useViewMode = false) {
    let key = 'PRAISES.' + t;
    if (useViewMode) {
      key += this.viewMode;
    } else {
      key += 'Objectives';
    }

    return this.coreService.getTranslation(key);
  }

  updateCurrentFilter() {
    this.currentFilter = {
      dateRange: this.dateRange,
      viewMode: this.viewMode, // Given/Received
      type: 'ObjectivesEmployee',
    };
  }
  getChildLength(el) {
    let temp = document.getElementById(el);
    return null;
    return `calc(100%/${temp.children.length})`;
  }

  findMyGuru(itemId: string) {}

  expandRow(item: any) {
    // console.log(item);

    if (item.ShowDetails) {
      item.ShowDetails = false;
      this.ref.detectChanges();
      this.openPop = false;
    } else {
      this.filteredItems.forEach((element: any) => {
        element.ShowDetails = false;
      });
      this.openPop = true;
      if (!item.ObjectiveCategoriesList) {
        this.updateCurrentFilter();
        this.currentFilter.type = 'ObjectiveCategoryByEmployee';
        this.currentFilter.isByPerson = true;
        this.OnLoading.emit();
        this.praiseService
          .getItemsPersonsByBehaviorSkills(item.Id, null, this.currentFilter)
          .subscribe((skills: any) => {
            this.OnLoaded.emit();
            item.ObjectiveCategoriesList = skills;
            item.ShowDetails = true;
            this.ref.detectChanges();
          });
      } else {
        item.ShowDetails = true;
        this.ref.detectChanges();
      }
    }
  }

  expandSubRow(objective: any) {
    // console.log("expandSubRow", objective);

    if (objective.ShowSubDetails) {
      objective.ShowSubDetails = false;
      this.ref.detectChanges();
      this.openPop = false;
    } else {
      this.filteredItems.forEach((element: any) => {
        element.ShowSubDetails = false;
      });
      this.openPop = true;
      if (!objective.ObjectiveCategoriesList) {
        this.updateCurrentFilter();
        this.currentFilter.type = 'Objectives_Objectives';
        this.OnLoading.emit();
        this.praiseService
          .getItemsPersonsByBehaviorSkills(
            objective.Id,
            null,
            this.currentFilter
          )
          .subscribe((skills: any) => {
            this.OnLoaded.emit();
            objective.ObjectiveCategoriesList = skills;
            objective.ShowSubDetails = true;
            for (let i of objective.ObjectiveCategoriesList) {
              i['sortingValue'] =
                (i?.Pats?.Tot || 0) +
                (i?.Feedbacks?.Avg || 0) +
                (i?.Tokens?.Tot || 0);
            }

            objective.ObjectiveCategoriesList.sort((a, b) => {
              return a.sortingValue > b.sortingValue
                ? -1
                : a.sortingValue < b.sortingValue
                  ? 1
                  : 0;
            });
            this.ref.detectChanges();
          });
      } else {
        objective.ShowSubDetails = true;
        this.ref.detectChanges();
      }
    }
  }

  sort(event: any, type: string, subtype: string = '') {
    let currentSort;
    for (let item of this.filteredItems) {
      item.ShowDetails = false;
    }
    if ($(event.target).hasClass('sort-asc')) {
      $(event.target).removeClass('sort-asc'),
        $(event.target).addClass('sort-desc');
      currentSort = 'desc';
    } else {
      $(event.target).removeClass('sort-desc'),
        $(event.target).addClass('sort-asc');
      currentSort = 'asc';
    }

    this.filteredItems.sort((a, b) => {
      switch (type) {
        case 'Name':
          if (!a || !a.Name || !b || !a.Name) return -1;
          if (currentSort === 'desc') {
            return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? -1
              : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                ? 1
                : 0;
          } else {
            return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                ? -1
                : 0;
          }
          break;

        case 'CountObjectives':
          if (currentSort === 'desc') {
            return b.CountObjectives - a.CountObjectives;
          } else {
            return a.CountObjectives - b.CountObjectives;
          }
          break;

        default:
          const valueA =
            !a || !a[type] || !a[type][subtype] ? 0 : a[type][subtype];
          const valueB =
            !b || !b[type] || !b[type][subtype] ? 0 : b[type][subtype];

          if (currentSort === 'desc') {
            return valueB - valueA;
          } else {
            return valueA - valueB;
          }

          break;
        // default:
        //   if (
        //     !a ||
        //     !a.Totals ||
        //     !a.Totals[type] ||
        //     !a.Totals[type][subtype] ||
        //     !b ||
        //     !b.Totals ||
        //     !b.Totals[type] ||
        //     !b.Totals[type][subtype]
        //   )
        //     return -1;

        //   if (currentSort === "desc") {
        //     return a.Totals[type][subtype] > b.Totals[type][subtype]
        //       ? -1
        //       : a.Totals[type][subtype] < b.Totals[type][subtype]
        //       ? 1
        //       : 0;
        //   } else {
        //     return a.Totals[type][subtype] > b.Totals[type][subtype]
        //       ? 1
        //       : a.Totals[type][subtype] < b.Totals[type][subtype]
        //       ? -1
        //       : 0;
        //   }

        //   break;
      }
    });
  }
  goToProfile(id) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: id },
      replaceUrl: true,
    });
  }
}
