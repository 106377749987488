import {
  OnChanges,
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { OrgChart } from 'd3-org-chart';
import { take, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
//import * as d3 from 'd3';
import { select } from 'd3-selection';

@Component({
  selector: 'ava-d3-orgchart',
  templateUrl: './ava-d3-orgchart.component.html',
  styleUrls: ['./ava-d3-orgchart.component.scss'],
})
export class AvaD3OrgchartComponent implements OnInit, OnChanges {
  @ViewChild('chartContainer') chartContainer: ElementRef;
  @Input() data: any[];
  @Input() CurrentEmployeeId: any;
  @Input() SelectedEmployeeId: any;
  @Input() CompanyLogo: any;
  @Input() CompanyLogoBackground: string;
  @Input() BandColor: string;
  @Input() SelectionColor: string;

  chart;
  // nodes=[];
  // SelectedNode:any;
  searchText;

  index = 0;
  compact = 0;
  actNdCent = 0;
  logoBackground: string;
  bandColor: string;
  selectionColor: string;

  txtManages: string;
  txtOversees: string;
  txtFirstLevel: string;
  txtAllEmployees: string;
  txtGotoProfile: string;

  modelChanged: Subject<string> = new Subject<string>();

  filterSettingsSingle: any = {
    singleSelection: true,
    enableSearchFilter: true,

    classes: 'availableLinksDropDown',
    // autoPosition: true,
  };

  constructor() {
    this.modelChanged
      .pipe(
        debounceTime(300), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe((model) => {
        this.searchText = model.toLowerCase();
        if (this.searchText && this.searchText.length <= 2) return;
        let selectedEmps = this.data.filter(
          (x) => x.Content.FullName.toLowerCase().indexOf(this.searchText) >= 0
        );
        if (selectedEmps != null && selectedEmps.length > 0) {
          this.chart.clearHighlighting();
          selectedEmps.forEach((e) => {
            this.SelectedEmployeeId = e.id;
            this.chart.setHighlighted(e.id);
          });
          this.chart.render();
        }
      });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.SelectedEmployeeId = this.CurrentEmployeeId;

    this.logoBackground = this.CompanyLogoBackground ?? 'white';
    this.bandColor = this.BandColor ?? '#3AB6E3';
    this.selectionColor = this.SelectionColor ?? '#ffa900';

    this.txtManages = 'Manages';
    this.txtOversees = 'Oversees';
    this.txtFirstLevel = 'First Level';
    this.txtAllEmployees = 'All Employees';
    this.txtGotoProfile = 'Goto Profile';

    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }

  // onOpen_selectNode(event: any) {
  //   $('angular2-multiselect.availableLinksDropDown .selected-list').hide();
  //   setTimeout(() => {
  //     $('angular2-multiselect.availableLinksDropDown input').focus();
  //     $('angular2-multiselect.availableLinksDropDown input').select();
  //   }, 1000);
  // }

  // onClose_selectNode(event: any) {
  //   $('angular2-multiselect.availableLinksDropDown .selected-list').show();

  //   // Forzo l'update della lista opzioni
  //   const updateList = JSON.parse(JSON.stringify(this.nodes));
  //   this.nodes = [];
  //   setTimeout(() => {
  //     this.nodes = updateList;
  //   }, 1000);
  // }

  search(e) {
    this.modelChanged.next(e);
  }

  select(e) {
    this.SelectedEmployeeId = e.id;
    this.setHighlighted();
  }

  swap() {
    this.chart
      .layout(['right', 'bottom', 'left', 'top'][this.index++ % 4])
      .render()
      .fit();
  }

  doCompact() {
    this.chart
      .compact(!!(this.compact++ % 2))
      .render()
      .fit();
  }

  centerActive() {
    this.chart.setActiveNodeCentered(!!(this.actNdCent++ % 2)).render();
  }

  setUpToTheRootHighlighted() {
    let id = this.SelectedEmployeeId;
    if (!id) id = this.CurrentEmployeeId;
    this.chart.setUpToTheRootHighlighted(id).render().fit();
  }

  setHighlighted() {
    this.chart.clearHighlighting();
    this.chart.setHighlighted(this.SelectedEmployeeId).render();
  }

  updateChart() {
    function getShortenText(s, n) {
      if (s) {
        if (s.length > n) {
          return `<span title="${s}">${s.substring(0, n - 3)}...</span>`;
        } else return s;
      }
      return '';
    }

    function getName(s, n) {
      if (s) {
        if (s.length > n) {
          return `<span title="${s}">${s.substring(0, n - 3)}...</span>`;
        } else return s;
      }
      return '';
    }

    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }
    let localSelectionColor = this.selectionColor;
    // console.log(this.data)
    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.data)
      .nodeWidth((d) => 250)
      .initialZoom(0.7)
      .nodeHeight((d) => 175)
      .childrenMargin((d) => 40)
      .compactMarginBetween((d) => 15)
      .compactMarginPair((d) => 80)
      .linkUpdate(function (d, i, arr) {
        select(this)
          .attr('stroke', (d: any) =>
            d.data._upToTheRootHighlighted ? localSelectionColor : '#dbdbdb'
          )
          .attr('stroke-width', (d: any) =>
            d.data._upToTheRootHighlighted ? 10 : 1
          );

        if (d.data._upToTheRootHighlighted) {
          select(this).raise();
        }
      })
      .onNodeClick((e) => {
        this.SelectedEmployeeId = e;
        this.setHighlighted();
      })
      .nodeContent((d: any) => {
        if (d.data.id === 'ROOT') {
          return `
                      <div style="padding-top:30px;background-color:none;margin-left:1px;height:${
                        d.height
                      }px;border-radius:2px;overflow:visible">
                        <div style="height:${
                          d.height - 32
                        }px;padding-top:0px;background-color:white;border:1px solid lightgray;">
  
                            <img src="${
                              this.CompanyLogo
                            }" style="background-color:${
                              this.logoBackground
                            }; margin-top:-37px;margin-left:${
                              d.width / 2 - 75
                            }px;margin-top:-30px;margin-left:50px;width:150px;height:75px;object-fit: contain;
                            box-shadow:1px 2px 5px 0 var(--shadow-color)" />
                                             
                            <div style="margin-top:-30px;background-color:${
                              this.bandColor
                            };height:10px;width:${
                              d.width - 2
                            }px;border-radius:1px"></div>
  
                            <div style="margin-top:20px;padding:10px; padding-top:25px;text-align:center">
                                <div style="color:#111672;font-size:16px;font-weight:bold">${getShortenText(
                                  d.data.Content.FullName,
                                  25
                                )} </div>                                
                            </div> 
                            <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
                              <div > ${this.txtFirstLevel}:  ${
                                d.data._directSubordinates
                              } 👤</div>  
                              <div > ${this.txtAllEmployees}: ${
                                d.data._totalSubordinates
                              } 👤</div>    
                            </div>
                        </div>     
                      </div>`;
        } else {
          return `
                    <div style="padding-top:30px;background-color:none;margin-left:1px;height:${
                      d.height
                    }px;border-radius:2px;overflow:visible">
                      <div style="height:${
                        d.height - 32
                      }px;padding-top:0px;background-color:white;border:1px solid lightgray;">

                        <img src="${
                          d.data.Content.Picture
                        }" style="margin-top:-30px;margin-left:${
                          d.width / 2 - 30
                        }px;border-radius:100px;width:60px;height:60px;" />
                                           
                        <div style="margin-top:-30px;background-color:${
                          this.bandColor
                        };height:10px;width:${
                          d.width - 2
                        }px;border-radius:1px"></div>
                        <div>
                   
                        <a href="#/User/story?idVisitor=${d.data.id}" title="${
                          this.txtGotoProfile
                        }">
                            <i class="fas fa-address-card fa-2x pointer"></i>
                        </a>
                        </div>
                        <div style="padding:10px; padding-top:10px;text-align:center">
                            <div style="color:#111672;font-size:14px;font-weight:bold"> ${
                              d.data.Content.FirstName.split(/[.\-=/_ ] /)[1] &&
                              d.data.Content.FullName.length >= 35
                                ? d.data.Content.FirstName.split(
                                    /[.\-=/_ ] /
                                  )[0] +
                                  d.data.Content.FirstName.split(
                                    /[.\-=/_ ] /
                                  )[1][0] +
                                  '. ' +
                                  d.data.Content.SecondName
                                : d.data.Content.FullName
                            } </div>
                            <div style="color:#404040;font-size:14px;margin-top:4px" > ${getShortenText(
                              d.data.Content.Role,
                              25
                            )} </div>
                            <div style="color:#404040;font-size:14px;margin-top:4px"> ${getShortenText(
                              d.data.Content.Team,
                              25
                            )} </div>
                        </div> 
                        <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
                          <div > ${this.txtManages}:  ${
                            d.data._directSubordinates
                          } 👤</div>  
                          <div > ${this.txtOversees}: ${
                            d.data._totalSubordinates
                          } 👤</div>    
                        </div>
                      </div>     
                    </div>`;
        }
      })
      .nodeUpdate(function (d, i, arr) {
        select(this)
          .select('.node-rect')
          .attr('stroke', (d: any) =>
            d.data._highlighted || d.data._upToTheRootHighlighted
              ? localSelectionColor
              : 'none'
          )
          .attr(
            'stroke-width',
            d.data._highlighted || d.data._upToTheRootHighlighted ? 20 : 1
          );
      })
      .render();
  }
}
