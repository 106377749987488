import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'app/shared/services/core.service';

@Component({
  selector: 'app-bonus-payable',
  templateUrl: './bonus-payable.component.html',
  styleUrls: ['./bonus-payable.component.scss', '../../common-styles.scss'],
})
export class BonusPayableComponent implements OnInit {
  @Input() plan: any;
  currentYear: number;

  constructor(
    private translateService: TranslateService,
    private coreService: CoreService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentYear = this.plan.YearStart;
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  getYears() {
    return new Array(this.plan.YearEnd - this.plan.YearStart)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  getYearsUntilCurrentYear(currentYear) {
    return new Array(currentYear + 1 - this.plan.YearStart)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  getAmount(type: string) {
    let v = 0;
    if (this.plan) {
      let pby = this.plan.PartecipantsByYear.find(
        (x) => x.Year === this.currentYear
      );
      if (pby && pby.Partecipants.length > 0) {
        let p = pby.Partecipants[0];
        let pa = p.Values.PaybleDeferredArray.find(
          (x) => x.PayYear === this.currentYear
        );
        switch (type) {
          case 'accrued':
            v = p.Values.Accrued.value;
            break;
          case 'accrued_max':
            v = p.Values.Accrued.max;
            break;
          case 'accrued_payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'accrued_deferred':
            v = pa ? pa.DeferredCash.value + pa.DeferredEquity.value : 0;
            break;
          case 'payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'payable_cash':
            v = pa ? pa.Cash.value : 0;
            break;
          case 'payable_equity':
            v = pa ? pa.Equity.value : 0;
            break;
          case 'bonusbank':
            break;
        }
      }
    }
    return v;
  }

  expandAccrued: boolean = false;
  expandPayable: boolean = false;
  expandBonusBank: boolean = false;
  expand(type: string) {
    switch (type) {
      case 'accrued':
        this.expandAccrued = true;
        this.expandPayable = false;
        this.expandBonusBank = false;
        break;
      case 'payable':
        this.expandAccrued = false;
        this.expandPayable = true;
        this.expandBonusBank = false;
        break;
      case 'bonusbank':
        this.expandAccrued = false;
        this.expandPayable = false;
        this.expandBonusBank = true;
        break;
    }
    this.ref.detectChanges();
  }

  /*******************************************************************************
    ____   ____  _   _ _    _  _____   _____    __     __      ____  _      ______
   |  _ \ / __ \| \ | | |  | |/ ____| |  __ \ /\\ \   / //\   |  _ \| |    |  ____|
   | |_) | |  | |  \| | |  | | (___   | |__) /  \\ \_/ //  \  | |_) | |    | |__
   |  _ <| |  | | . ` | |  | |\___ \  |  ___/ /\ \\   // /\ \ |  _ <| |    |  __|
   | |_) | |__| | |\  | |__| |____) | | |  / ____ \| |/ ____ \| |_) | |____| |____
   |____/ \____/|_| \_|\____/|_____/  |_| /_/    \_\_/_/    \_\____/|______|______|
  *******************************************************************************/

  getBonusPayableGraphData(year: number) {
    return [this.getAmount('payable_cash'), this.getAmount('payable_equity')];
  }

  getBonusPayableGraphDataColors(year: number) {
    return [
      {
        backgroundColor: ['#FFDD45', '#FFFFFF'],
      },
    ];
  }

  getBonusPayableGraphDataLabels(year: number) {
    return [
      this.coreService.getTranslation('ADMIN_LTI.PAYOUT.BODY.CASH'),
      this.coreService.getTranslation('ADMIN_LTI.PAYOUT.BODY.EQUITY'),
    ];
  }

  getBonusPayableGraphOptions(year: number) {
    return {
      responsive: true,
      maintainAspectRation: false,
      legend: {
        display: true,
        position: this.expandPayable ? 'bottom' : 'right',
      },
    };
  }

  getBonusPayableWidgetAccrued(year: number) {
    return 50;
  }

  getBonusPayableWidgetAccruedProgressMax(year: number) {
    return 100;
  }
}
