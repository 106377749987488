import { Injectable } from '@angular/core';
import { RestfulService } from '../shared/services/restful.service';

@Injectable()
export class AdminLtiService {
  constructor(private restfulWebService: RestfulService) {}

  getParameters() {
    return this.restfulWebService.get(`TokenOperation/Parameters`);
  }

  getPlans() {
    return this.restfulWebService.get(`LTI/Plans`);
  }
  getPlan(planId) {
    return this.restfulWebService.get(`LTI/Plan?planId=${planId}`);
  }
  getEmployees() {
    return this.restfulWebService.get(`LTI/Employees`);
  }

  getGoals() {
    return this.restfulWebService.get(`LTI/Goals`);
  }
  getAllMeasures() {
    return this.restfulWebService.get(`MeasureExt`);
  }
  getMeasures(goalId: string) {
    return this.restfulWebService.get(`LTI/Measures?goalId=${goalId}`);
  }
  getFormats() {
    return this.restfulWebService.get(`LTI/Formats`);
  }
  getScales() {
    return this.restfulWebService.get(`LTI/Scales`);
  }

  upload(planId, itemToPost) {
    return this.restfulWebService.fileUpload(
      `lti/upload?planId=${planId}`,
      itemToPost
    );
  }

  newPlan(plan) {
    return this.restfulWebService.post(`LTI/Plan`, plan);
  }
  editPlan(plan) {
    return this.restfulWebService.put(`LTI/Plan?planId=${plan.Id}`, plan);
  }
  activatePlan(plan) {
    return this.restfulWebService.put(`LTI/Activate?planId=${plan.Id}`, plan);
  }
  deletePlan(planId) {
    return this.restfulWebService.delete(`LTI/DeletePlan?planId=${planId}`);
  }

  sendResult(planId, values) {
    return this.restfulWebService.put(
      `LTI/FinalResult?planId=${planId}`,
      values
    );
  }
}
