import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../services/shared.service';
import { CoreService } from '../../services/core.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-choice-pop-up',
  templateUrl: './choice-pop-up.component.html',
  styleUrls: ['./choice-pop-up.component.scss', '../../../common_styles.scss'],
})
export class ChoicePopUpComponent implements OnInit {
  public onClose: Subject<any>;
  userInfo: any;
  evaluation_Employees: any = [];
  oldDesc = [];
  numberOfAsks = 0;
  selectedTab = 'Give';
  @Output() postEval = new EventEmitter();
  @Output() updateEval = new EventEmitter();
  selectedFeedback;
  isPreSelected = false;
  msg: any;
  newDesc = [];
  status: boolean = true;
  check: boolean = true;

  constructor(
    public coreService: CoreService,
    public sharedService: SharedService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.userInfo = this.coreService.getUserInfo();
    if (this.selectedFeedback) {
      this.selectedFeedback = JSON.parse(JSON.stringify(this.selectedFeedback));
      this.selectedFeedback.Target.isPreselect = true;
      this.evaluation_Employees = [this.selectedFeedback.Target];
      for (let element of this.selectedFeedback.Evaluations) {
        this.oldDesc.push(element.Description);
      }
      // console.log(this.oldDesc);
    }
  }

  showModal(content) {}

  getWallMarginTop() {
    var h = 60;
    if (this.numberOfAsks > 0) h += 30;
    return h;
  }

  onPopupClose() {
    this.bsModalRef.hide();
  }

  onMessageFromChild(message: string) {
    this.msg = message;
  }
  defineCheck(check: boolean) {
    this.check = check;
  }

  hidePopup() {
    this.newDesc = [];
    this.status = true;
    // console.log(this.isPreSelected,this.selectedFeedback,this.msg);

    if (this.selectedFeedback && this.msg) {
      for (let i of this.msg) {
        this.newDesc.push(i.Description);
      }
      // console.log(this.newDesc);
      // console.log(this.oldDesc);
      for (let i = 0; i < this.newDesc.length; i++) {
        if (this.newDesc[i] != this.oldDesc[i]) {
          this.status = false;
        }
      }
      // console.log(this.status);
    }
    if (!this.check) {
      this.status = false;
    }

    if (this.status) {
      this.bsModalRef.hide();
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DISCARD'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DISCARD_CHANGES'),
        subtitle: this.coreService.getTranslation('WALL.DISCARD_CHANGES_TITLE'),
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation',
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.bsModalRef.hide();
          }
        }
      );
    }
  }
  // cancelChanges() {
  //   const content: any = {
  //     YesCaption: this.coreService.getTranslation("DISCARD"),
  //     NoCaption: this.coreService.getTranslation("CANCEL"),
  //     type: "YesNo",
  //     title: this.coreService.getTranslation("WALL.DISCARD_CHANGES"),
  //     subtitle: this.coreService.getTranslation("WALL.DISCARD_CHANGES_TITLE"),
  //   };

  //   const modal = this.modalService.show(SimpleDialogComponent, {
  //     class: "self-evaluation",
  //   });
  //   (<SimpleDialogComponent>modal.content).showModal(content);
  //   (<SimpleDialogComponent>modal.content).onClose.subscribe(
  //     (result: boolean) => {
  //       if (result) {
  //         this.bsModalRef.hide();
  //       }
  //     }
  //   );
  // }
}
