import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LanguageSelectorComponent } from './language-selector.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [LanguageSelectorComponent],
  imports: [CommonModule, SlickCarouselModule, TooltipModule.forRoot()],
  exports: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
