import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pp360-radio',
  templateUrl: './pp360-radio.component.html',
  styleUrls: ['./pp360-radio.component.scss'],
})
export class Pp360RadioComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() model: boolean;
  @Input() value: string;

  @Output() onStateChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  click() {
    if (!this.disabled) {
      this.model = !this.model;
      this.onStateChange.emit(this.model);
    }
  }
}
