import { Component, OnInit } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewFeedPopupComponent } from '../view-feed-popup/view-feed-popup.component';

@Component({
  selector: 'engagement-popup',
  templateUrl: './engagement-popup.component.html',
  styleUrls: ['./engagement-popup.component.scss'],
})
export class EngagementPopupComponent implements OnInit {
  response: any = [];
  data: any;
  type: any;
  bsModalRef: BsModalRef;
  showHide: boolean = false;
  toggleSkill: boolean = false;
  btn1: boolean = true;
  btn2: boolean = false;
  filterTypeArr: any = [
    { label: 'Annual', value: 'Annual' },
    { label: 'In the last 30 days', value: 'In the last 30 days' },
  ];
  selected = { label: 'Annual', value: 'Annual' };
  userData: any = [
    {
      image: '',
      name: 'Ferri Amelia',
      lastCFDays: 'Never sent',
      viewLastCF: false,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
    {
      image: '',
      name: 'Ferri Amelia',
      lastCFDays: '70 Days',
      viewLastCF: true,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
    {
      image: '',
      name: 'Ferri Amelia',
      lastCFDays: '40 Days',
      viewLastCF: true,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
    {
      image: '',
      name: 'Ferri Amelia',
      lastCFDays: '20 Days',
      viewLastCF: true,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
    {
      image: '',
      name: 'Ferri Amelia',
      lastCFDays: '0 Days',
      viewLastCF: true,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
  ];
  feedbackData: any = [
    {
      image: '',
      name: 'Fabio Fedel',
      feedbackItems: [
        {
          type: 'Cooperative approach',
          user: 'Fabio Fedel',
          icon: 'setting',
          date: 'dd/mm/yyyy',
          feedbackValue: 2,
          viewFeedback: true,
        },
      ],
    },
    {
      image: '',
      name: 'Fabio Fedel',
      feedbackItems: [
        {
          type: 'Cooperative approach',
          user: 'Fabio Fedel',
          icon: 'language',
          date: 'dd/mm/yyyy',
          feedbackValue: 2,
          viewFeedback: true,
        },
        {
          type: 'Cooperative approach',
          user: 'Fabio Fedel',
          icon: 'bullseye',
          date: 'dd/mm/yyyy',
          feedbackValue: 1,
          viewFeedback: true,
        },
      ],
    },
  ];
  cooperativeData: any = [
    {
      topSkill: 'Cooperative approach',
      empName: 'Fabio Fedel',
      guruRank: '1',
      viewGuruRank: true,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
    {
      topSkill: 'Entrepreneurial approach',
      empName: 'Amelia Ferri',
      guruRank: '10',
      viewGuruRank: true,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
    {
      topSkill: 'Championing a Vision',
      empName: 'Irene Degrafi',
      guruRank: '20',
      viewGuruRank: true,
      userToken: true,
      userEvalution: true,
      userPat: true,
    },
  ];
  constructor(
    public coreSevice: CoreService,
    private modalRef: BsModalService
  ) {}

  ngOnInit(): void {
    for (let z of this.feedbackData) {
      for (let i of z.feedbackItems) {
        let arr = [];
        for (let j = 0; j < i.feedbackValue; j++) {
          arr.push(1);
        }
        for (let j = 0; j < 5 - i.feedbackValue; j++) {
          arr.push(0);
        }
        i['amount'] = arr;
      }
    }
    // if(this.data.Key === 'O3') {
    //   for (let item of this.userData) {
    //     item.Rows.sort((a, b) => {
    //       a = a.LastUpdate;
    //       b = b.LastUpdate;
    //       return String(b).localeCompare(String(a), "en", { numeric: true }) * -1;
    //     });
    //   }
    // }
  }
  getTokenPopUp() {
    console.log(this.response);
  }
  getNewEvaluation() {
    console.log(this.response);
  }
  getNewPat() {
    console.log(this.response);
  }
  viewFeedbackPopup(item) {
    this.bsModalRef = this.modalRef.show(ViewFeedPopupComponent, {
      class: 'okrPopUp ammazza wallPopup',
      ignoreBackdropClick: false,
      initialState: {
        selectedFeedback: item,
        type: 'lastCF',
      },
    });
    // this.bsModalRef.content.postEval.subscribe((data: any) => {
    //   this.refreshWall(data);
    //   this.bsModalRef.hide();
    // });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.showHide = false;
  }

  toggleSkills() {
    this.btn1 = !this.btn1;
    this.btn2 = !this.btn2;
  }
  changeItemType(event) {
    this.selected = event.value;
  }
}
