export interface Theme {
  name: string;
  properties: any;
}

export const defaultStyle: Theme = {
  name: 'defaultStyle',
  properties: {
    '--background-type': 'light',
    '--footer-default': 'rgba(255, 221, 69, 1)',
    '--header-default': 'rgba(0, 77, 126, 1)',
    '--menu-default': 'rgba(94, 94, 94, 1)',
  },
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--background-type': 'dark',
    '--footer-default': 'rgba(205, 164, 52, 1)',
    '--header-default': 'rgba(3, 3, 3, 1)',
    '--menu-default': 'rgba(27, 27, 27, 1)',
  },
};

export const light: Theme = {
  name: 'light',
  properties: {
    '--background-type': 'light',
    '--footer-default': 'rgba(151, 163, 217, 1)',
    '--header-default': 'rgba(33, 83, 49, 1)',
    '--menu-default': 'rgba(255, 255, 255, 1)',
  },
};

export const p1: Theme = {
  name: 'PromDarkBluGiallo',
  properties: {
    '--background-type': 'dark',
    '--footer-default': 'rgba(223, 178, 86, 1)',
    '--header-default': 'rgba(33, 43, 89, 1)',
    '--menu-default': 'rgba(0, 0, 0, 1)',
  },
};

export const p2: Theme = {
  name: 'PromDarkBluBlu',
  properties: {
    '--background-type': 'dark',
    '--footer-default': 'rgba(110, 120, 166, 1)',
    '--header-default': 'rgba(33, 43, 89, 1)',
    '--menu-default': 'rgba(0, 0, 0, 1)',
  },
};

export const p3: Theme = {
  name: 'PromDarkBluBluBlu',
  properties: {
    '--background-type': 'dark',
    '--footer-default': 'rgba(33, 43, 89, 1)',
    '--header-default': 'rgba(186, 196, 242, 1)',
    '--menu-default': 'rgba(110, 120, 166, 1)',
  },
};

export const p4: Theme = {
  name: 'PromLightBluGiallo',
  properties: {
    '--background-type': 'light',
    '--footer-default': 'rgba(223, 178, 86, 1)',
    '--header-default': 'rgba(33, 43, 89, 1)',
    '--menu-default': 'rgba(74, 74, 74, 1)',
  },
};
