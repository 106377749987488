import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InappropriateDialogComponent } from './../inappropriate-dialog/inappropriate-dialog.component';
import { WallService } from '../wall.service';
import { EmployeeTeamPopUpComponent } from 'app/shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { SharedService } from 'app/shared/services/shared.service';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

import { NewsDialogComponent } from 'app/employee/wall/news-dialog/news-dialog.component';
import * as moment from 'moment';
import { ProfileTooltip } from '../profile-tooltip/profile-tooltip.component';
import { DatePipe } from '@angular/common';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { ListOfEmployeesComponent } from 'app/shared/components/list-of-employees/list-of-employees.component';
@Component({
  selector: 'feed-news',
  templateUrl: './feed-news.component.html',
  styleUrls: [
    './feed-news.component.scss',
    '../common-feed-style.scss',
    '../../../common_styles.scss',
  ],
})
export class FeedNewsComponent implements OnInit {
  @Input() item: any;
  @Input() showReported: boolean;
  @Input() readOnly: any;
  @Output() reloadRequired = new EventEmitter();
  @Output() feedDeleted = new EventEmitter();
  @Output() commentDeleted = new EventEmitter();
  @Output() feedUpdated = new EventEmitter<any>();
  @Output() refreshParticipant = new EventEmitter();
  @Input() inappropriatebtn: any;

  @Output() reportedAsInappropriate = new EventEmitter<any>();

  sendingComment = false;
  toggle: boolean = false;

  userInfo: any;
  empInfo: any;
  userData: any;
  datePip: any;
  // datepipe: any;
  constructor(
    public coreService: CoreService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private service: WallService,
    private sharedService: SharedService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {}

  ngOnInit() {
    this.userData = this.coreService.getUserInfo();
    this.item.totalComments = 0;
    this.userInfo = this.userData;
    this.item.Date = this.sharedService.getLocalDate(this.item.Date);

    this.showHideTranslated(this.item, 'Description', 'Title', false);
    if (this.service.profiles.length) {
      const user = this.item.Author ? this.item.Author : this.item.Target;
      const employee = this.service.profiles.find((x) => x.Id === user?.Id);
      if (employee) {
        this.empInfo = employee;
      } else {
        this.getProfileInfo();
      }
    } else {
      this.getProfileInfo();
    }
    this.item['isComment'] = false;
    this.item['commentArr'] = [];
    this.item.Comments.reverse();
    if (this.item.Comments.length) {
      for (
        let i = this.item.Comments.length - 1;
        i >= this.item.Comments.length - 3;
        i--
      ) {
        this.item['commentArr'].push(this.item.Comments[i]);
      }
    }
    if (this.item.Likes.length) {
      for (let i = 0; i < this.item.Likes.length; i++) {
        if (this.item.Likes[i].Author.Id == this.userInfo.linkedEmployeeId) {
          this.item['isCheckLike'] = true;
          break;
        }
      }
    }
    this.manageCountComment();
  }

  updateCount(comments) {
    for (let i of comments) {
      if (i.SubComments.length > 0) {
        this.item.totalComments += i.SubComments.length;
        this.updateCount(i.SubComments);
      }
    }
  }

  manageCountComment() {
    if (this.item.Comments.length > 0) {
      this.item.totalComments =
        this.item.Comments.length + this.item.totalComments;
      this.updateCount(this.item.Comments);
    }
  }

  getProfileInfo() {
    const user = this.item.Author ? this.item.Author : this.item.Target;
    this.service.getEmployeeInfo(user?.Id).subscribe((data: any) => {
      if (data) {
        this.empInfo = data;
        const employee = this.service.profiles.find((x) => x.Id === user?.Id);
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  getImage(item) {
    if (item.Picture) {
      return {
        'background-image': `url('${item.Picture}')`,
      };
    } else {
      return undefined;
    }
  }

  showHideTranslated(item, property, title, change) {
    if (item.TranslatedTitle && item.TranslatedText) {
      item.ShowTranslated = !item.ShowTranslated;
    } else {
      let selectedLang = this.userData.language;
      const text = item[property];
      // console.log('text   ', text);
      // console.log('property   ', property);
      if (item[property]) {
        this.coreService
          .translateText(text, null, selectedLang)
          .subscribe((result: any) => {
            item['TranslatedText'] = result.TranslatedText;
            if (change == true) {
              item.ShowTranslated = true;
            }
          });
      }
      const titleText = item[title];
      if (item[title]) {
        this.coreService
          .translateText(titleText, null, selectedLang)
          .subscribe((result: any) => {
            item['TranslatedTitle'] = result.TranslatedText;
            if (change == true) {
              item.ShowTranslated = true;
            }
          });
      }
    }
  }

  delete(item: any, comment: any) {
    const content: any = {
      YesCaption: this.coreService.getTranslation('DELETE'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('WALL.DELETE_POST'),
    };

    if (comment) {
      (content.title = this.coreService.getTranslation('WALL.DELETE_COMMENT')),
        (content.subtitle = this.coreService.getTranslation(
          'WALL.DELETE_COMMENT_TITLE'
        ));
    } else {
      if (item.Category == 1) {
        content.subtitle = this.coreService.getTranslation(
          'WALL.DELETE_EVENT_TITLE'
        );
      }
      if (item.Category == 2) {
        content.subtitle = this.coreService.getTranslation(
          'WALL.DELETE_INFO_TITLE'
        );
      }
      if (item.Category == 3) {
        content.subtitle = this.coreService.getTranslation(
          'WALL.DELETE_SUCCESS_TITLE'
        );
      }
      // content.subtitle= this.coreService.getTranslation("WALL.DELETE_NEWS_TITLE");
    }

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.pp360SpinnerService.showSpinner(true);
          if (comment) {
            this.service.deleteComment(comment.Id).subscribe(
              (data: any) => {
                this.coreService.toasterMessage(
                  'success',
                  'auth',
                  this.coreService.getTranslation('OK')
                );
                item.expandMenu = false;
                this.commentDeleted.emit({ item, comment });
                //this.reloadRequired.emit();
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
              }
            );
          } else {
            this.service.deleteNews(item.Id).subscribe(
              (data: any) => {
                // this.coreService.toasterMessage(
                //   "success",
                //   "auth",
                //   this.coreService.getTranslation("OK")
                // );
                if (this.item.Category === 1) {
                  this.coreService.setShowEliminated({ type: 'News' });
                } else if (this.item.Category === 2) {
                  this.coreService.setShowEliminated({ type: 'Info' });
                } else if (this.item.Category === 3) {
                  this.coreService.setShowEliminated({ type: 'Success' });
                }
                item.expandMenu = false;
                this.feedDeleted.emit(item);
                this.pp360SpinnerService.showSpinner(false);
                //this.reloadRequired.emit();
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
                this.pp360SpinnerService.showSpinner(false);
              }
            );
          }
        }
      }
    );
  }

  addAPart(item) {
    item['isParticipated'] = true;
    var reqBody = {
      NewsId: item.Id,
      AuthorId: this.userData.linkedEmployeeId,
    };

    this.service.postAPart(reqBody, 'News').subscribe(
      (data: any) => {
        const userData = this.userData;
        userData.Id = userData.linkedEmployeeId;
        item.Partecipants.push(userData);
        this.refreshParticipant.emit(item);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  removeAPart(item) {
    item['isParticipated'] = false;
    this.service
      .deleteAPart(item.Id, this.userData.linkedEmployeeId, 'News')
      .subscribe(
        (data: any) => {
          var index = item.Partecipants.findIndex(
            (e: any) => e.Id === this.userData.linkedEmployeeId
          );
          item.Partecipants.splice(index, 1);
          this.refreshParticipant.emit(item);
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
  }

  getConfigMenu(item: any) {
    return {
      editAction:
        !item.IsReported && item.Author.Id === this.userInfo.linkedEmployeeId,
      deleteAction: item.Author.Id === this.userInfo.linkedEmployeeId,
      inappropriateAction:
        !item.IsReported &&
        item.Author.Id !== this.userInfo.linkedEmployeeId &&
        (item.Description || item.Picture),
      data: item.Description || item.Picture,
    };
  }
  name() {
    if (this.item.Category == 2) {
      return 'info';
    } else if (this.item.Category == 3) {
      return 'success';
    } else if (this.item.Category == 1) {
      return 'Event';
    }
  }
  edit(item: any, comment: any) {
    if (comment) {
      console.log(comment);
    }
    const content: any = {};
    const modal = this.modalService.show(NewsDialogComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true,
    });
    this.item.StartTime = moment(this.item.StartTime).toDate();
    modal.content.initModal(JSON.parse(JSON.stringify(this.item)));
    (<NewsDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          // this.reloadRequired.emit();
          this.feedUpdated.emit(result);
        }
      }
    );
  }

  shortcutForPat(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;
    let bsModalRef = this.modalService.show(EmployeeTeamPopUpComponent, {
      class: 'popup-user',
    });
    bsModalRef.content.team = team;

    bsModalRef.content.addNewPat.subscribe((data: any) => {
      this.reloadRequired.emit();
    });
    // bsModalRef.content.closeBtnName = 'Close';
  }

  expandMenu(item) {
    item.expandMenu = !item.expandMenu;
  }

  reportInnapropriate(item, comment = null) {
    item.expandMenu = false;
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    const content: any = {
      title: msg,
      type: item.Category,
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation',
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.Id,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 5, //<=News
          };
          if (comment) {
            req.FeedType = 6; //COMMENT
            req.FeedId = comment.Id;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }

  getNewsImage(item: any) {
    // se l'immagine contiene un base 64 invece di un url
    return item.Picture.indexOf('data:image/jpeg;base64,') >= 0
      ? item.Picture
      : this.coreService.getImageURL(item.Picture, null, item.IsReported);
  }

  addALike(item) {
    item['isCheckLike'] = true;
    var reqBody = {
      NewsId: item.Id,
      AuthorId: this.userInfo.linkedEmployeeId,
    };
    this.service.postALike(reqBody, 'News').subscribe(
      (data: any) => {
        item.Likes.push(data);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  removeALike(item) {
    item['isCheckLike'] = false;

    this.service
      .deleteALike(item.Id, this.userInfo.linkedEmployeeId, 'News')
      .subscribe(
        (data: any) => {
          var index = item.Likes.findIndex(
            (e: any) => e.AuthorId === this.userInfo.linkedEmployeeId
          );
          item.Likes.splice(index, 1);
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
  }

  onComment(item) {
    item.isComment = !item.isComment;
  }

  shortprofiletooltip(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;
    let bsModalRef = this.modalService.show(ProfileTooltip, {
      class: 'tooldirection',
    });
    bsModalRef.content.team = team;

    bsModalRef.content.addNewPat.subscribe((data: any) => {
      this.reloadRequired.emit();
    });
    // bsModalRef.content.closeBtnName = 'Close';
  }

  sendMsg(comment) {
    this.item.totalComments++;
    this.sendingComment = true;
    let reqBody = {
      NewsId: this.item.Id,
      AuthorId: this.userInfo.linkedEmployeeId,
      Text: comment?.comment ? comment?.comment : ' ',
      Picture: comment?.image ? comment?.image : null,
    };
    if (reqBody.Text) {
      this.pp360SpinnerService.showSpinner(true);
      this.service.commentOnWall(reqBody, 'News').subscribe(
        (data: any) => {
          this.item.Comments.unshift(data);
          this.pp360SpinnerService.showSpinner(false);
          this.sendingComment = false;
        },
        (err: any) => {
          console.log('errrr', err);
          this.pp360SpinnerService.showSpinner(false);
          this.sendingComment = false;
        }
      );
    }
  }
  showReactions(item, type) {
    if (type === 'comment') {
      this.onComment(item);
    } else {
      var emps = [];
      var items: any;
      switch (type) {
        case 'like':
          items = item.Likes;
          items.forEach((element: any) => {
            var e = {
              Description: element.Author.JobTitle,
              Id: element.Author.Id,
              Location: element.Author.Location,
              Name: element.Author.Name,
              Type: 'Employee',
              Picture: element.Author.Picture,
              SomethingAbout: element.Author.SomethingAbout,
            };
            emps.push(e);
          });
          break;

        case 'repatpart':
          items = item.Partecipants;
          items.forEach((element: any) => {
            var e = {
              Description: element.JobTitle,
              Id: element.Id,
              Location: element.Location,
              Name: element.Name,
              Type: 'Employee',
              Picture: element.Picture,
              SomethingAbout: element.SomethingAbout,
            };
            emps.push(e);
          });
          break;

        case 'repat':
          items = item.Partecipants;
          items.forEach((element: any) => {
            var e = {
              Description: element.JobTitle,
              Id: element.Id,
              Location: element.Location,
              Name: element.Name,
              Type: 'Employee',
              Picture: element.Picture,
              SomethingAbout: element.SomethingAbout,
            };
            emps.push(e);
          });
          break;
      }

      if (emps.length > 0) this.listOfEmp(emps, type);
    }
  }
  listOfEmp(empTeamList, mode) {
    this.bsModalRef = this.modalService.show(ListOfEmployeesComponent, {
      class: 'modal-md pop-width list-of-emp',
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.empTeamList = empTeamList;
    this.bsModalRef.content.mode = mode;
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
