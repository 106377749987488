import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360TabsModule } from 'app/pp360-components/pp360-tabs/pp360-tabs.module';
import { Pp360TextInputModule } from 'app/pp360-components/pp360-text-input/pp360-text-input.module';
import { Pp360RadioModule } from 'app/pp360-components/pp360-radio/pp360-radio.module';
import { Pp360CheckboxModule } from './pp360-checkbox/pp360-checkbox.module';
import { Pp360ScrollContainerModule } from './pp360-scroll-container/pp360-scroll-container.module';
import { Pp360SimpleDialogComponent } from './pp360-simple-dialog/pp360-simple-dialog.component';
import { Pp360SpinnerComponent } from './pp360-spinner/pp360-spinner.component';

import { Pp360DropdownModule } from './pp360-dropdown/pp360-dropdown.module';
import { Pp360EditorModule } from './pp360-editor/pp360-editor.module';
import { Pp360AttachmentModule } from './pp360-attachment/pp360-attachment.module';
import { Pp360DoughnutComponent } from './pp360-doughnut-chart/pp360-doughnut-chart.component';

@NgModule({
  declarations: [
    Pp360SimpleDialogComponent,
    Pp360SpinnerComponent,
    Pp360DoughnutComponent,
  ],
  imports: [
    CommonModule,
    Pp360TabsModule,
    Pp360TextInputModule,
    Pp360RadioModule,
    Pp360CheckboxModule,
    Pp360ScrollContainerModule,
    Pp360DropdownModule,
    Pp360EditorModule,
    Pp360AttachmentModule,
  ],
  exports: [
    Pp360RadioModule,
    Pp360CheckboxModule,
    Pp360ScrollContainerModule,
    Pp360SimpleDialogComponent,
    Pp360SpinnerComponent,
    Pp360DropdownModule,
    Pp360TabsModule,
    Pp360TextInputModule,
    Pp360EditorModule,
    Pp360AttachmentModule,
    Pp360DoughnutComponent,
  ],
})
export class Pp360ComponentsModule {}
