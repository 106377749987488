// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  //***************************************************************************** */
  production: false,
  name: 'DEMO',
  version: '2023.2.1',

  serverWS: 'wss://tools.avaservice.eu:8443/patpat_demo/ws',
  serverURI: 'https://tools.avaservice.eu:8443/patpat_demo/',

  googleClientId:
    '201225183218-d1e1in9ic03fjcn94n33unk37e2ktinp.apps.googleusercontent.com',

  ShowPersonalReport: false,

  ShowManagerEvaluations: false,

  ShowGoals: false,
  ShowProjectTeams: false,

  ShowGrowthSummary: false,
  ShowBossAndSelfEvaluations: false,

  showClone: false,

  ShowCycleAdvanced: true,

  ShowPerfomanceReviews: false,
};
