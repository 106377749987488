import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Pp360SpinnerService } from '../../pp360-components/pp360-spinner/pp360-spinner.service';
import { CoreService } from 'app/shared/services/core.service';
import { AuthService } from '../auth.service';
import { navigationService } from '../../shared/services/navigation.service';
import { ImageUploadDialogComponent } from 'app/shared/components/image-upload-dialog/image-upload-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileHandle } from 'app/shared/components/image-upload-dialog/dragDrop.directive';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'choose-login',
  templateUrl: './choose-login.component.html',
  styleUrls: ['./choose-login.component.scss'],
})
export class ChooseLoginComponent implements OnInit {
  isUser: boolean = true;
  userinfo: any = [];
  userImage: string = '';
  public userInfo: any;
  userData;
  selectedRole: string;
  bsModalRef: BsModalRef;
  slides = [];
  userRole: string;
  changedImage: SafeUrl;
  username: string = '';
  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private pp360SpinnerService: Pp360SpinnerService,
    public navService: navigationService,
    private coreService: CoreService,
    private modalService: BsModalService,
    public service: AuthService,
    public router: Router
  ) {}

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
  };

  managerSlide = {
    class: 'fas fa-5x fa-user-tie',
    role: 'Manager',
    text: this.coreService.getTranslation('Menu.Manager'),
  };

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  afterChange(e) {
    const element = e.slick.$slides.get(e.currentSlide);
    if (element) {
      const el = element.querySelector('.role');
      const roleTitle = el.innerHTML.replace(/ /g, '');
      this.selectedRole = el.id;
    }
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }
  ngOnInit(): void {
    this.readUserInfo();
    this.slides = [
      { class: 'fas fa-5x fa-user', role: 'User', text: 'Menu.Employee' },
      {
        class: 'fas fa-5x fa-user-tag',
        role: 'LocalAdmin',
        text: 'Menu.Local_Admin',
      },
      {
        class: 'fas fa-5x fa-users-cog',
        role: 'CompanyAdmin',
        text: 'Menu.Company_Admin',
      },
      {
        class: 'fas fa-5x fa-user-cog',
        role: 'SuperAdmin',
        text: 'Menu.Super_Admin',
      },
    ];
    this.userData = this.coreService.getUserInfo();
    if (!this.userData) {
      this.router.navigate(['login']);
    }
    const name = this.userData?.fullName?.split(' ');
    this.username = name[0];
    this.userImage = this.userData.imageUrl;
    const userRoles = this.coreService.getUserRole().split(',');
    const slides = this.slides.filter((s) => {
      return userRoles.includes(s.role);
    });

    if (this.userData.isManager) {
      slides.splice(1, 0, this.managerSlide);
    }
    this.slides = slides;
    this.selectedRole = this.slides[0].role;
  }

  readUserInfo() {
    this.userInfo = this.coreService.getUserInfo();
  }
  getUserLetters() {
    const fullname = this.userData ? this.userData.fullName : '';
    const name = fullname.split(' ');
    return fullname && name.length > 1 ? `${name[0][0]}${name[1][0]}` : '';
  }

  selectRole() {
    this.pp360SpinnerService.showSpinner();
    this.service.savePrefferedRole(this.selectedRole).subscribe(
      (res) => {
        this.pp360SpinnerService.showSpinner(false);
        this.navService.setupMenus();
        this.coreService.setPreferredRole(this.selectedRole);
        setTimeout(() => {
          this.service.redirectUser(this.selectedRole);
        }, 100);
      },
      () => this.pp360SpinnerService.showSpinner(false)
    );
  }
  changeImage() {
    const isGravatar = this.userData.imageUrl.includes('https:');
    const url = this.changedImage
      ? this.changedImage
      : environment.serverURI + this.userData.imageUrl;
    if (!isGravatar && url) {
      this.downloadImage(url);
    } else {
      this.openModal(null);
    }
  }

  openModal(data) {
    this.bsModalRef = this.modalService.show(ImageUploadDialogComponent, {
      class: 'image-change',
      initialState: {
        finalCroppedImage: data?.finalCroppedImage,
        file: data?.file,
      },
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (result) {
        this.changedImage = result;
      }
    });
  }

  downloadImage(imgUrl) {
    this.httpClient.get(imgUrl, { responseType: 'blob' as 'json' }).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: res.type });
        const file = new File([blob], 'avatar', { type: 'image/jpeg' });
        const fileHandle: FileHandle = {
          file,
          url: this.sanitizer.bypassSecurityTrustUrl(
            window.URL.createObjectURL(file)
          ),
        };
        this.openModal({
          finalCroppedImage: imgUrl,
          file: fileHandle,
        });
      },
      (error) => {
        this.openModal(null);
      }
    );
  }

  setLogo() {
    let a = '';
    if (
      this?.userInfo?.Theme?.Code &&
      (this?.userInfo?.belongingCompanyName === 'Demo Company' ||
        this?.userInfo?.belongingCompanyName === 'Test Company' ||
        this?.userInfo?.belongingCompanyName === 'Active Value Advisors')
    ) {
      a = './assets/images/logos/' + this.userInfo.Theme.Code + '_L_logo.png ';
    } else {
      a = this.coreService.getImageURL(
        this.userInfo.belongingCompanyLogo,
        this.coreService.getDefaultImage()
      );
    }
    return a;
  }
}
