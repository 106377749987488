import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360ScrollContainerComponent } from './pp360-scroll-container.component';

@NgModule({
  declarations: [Pp360ScrollContainerComponent],
  imports: [CommonModule],
  exports: [Pp360ScrollContainerComponent],
})
export class Pp360ScrollContainerModule {}
