import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WallService } from 'app/employee/wall/wall.service';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ManagerOkrPopupComponent } from '../manager-okr-popup/manager-okr-popup.component';

@Component({
  selector: 'okr-follower-popup',
  templateUrl: './okr-follower-popup.component.html',
  styleUrls: ['./okr-follower-popup.component.scss'],
})
export class OkrFollowerPopupComponent implements OnInit {
  isAfterContentChecked: boolean = false;
  empTeamList: any;
  isOkrs: boolean = false;
  isEmployee: boolean = false;
  userData: any;
  empOkr: any;
  Verb = '';
  openPop: any = {
    $id: '17',
    Decimals: 0,
    DescriptionKey: 'O4_DESC',
    DetailType: 'ProgressOKR',
    HasDetails: true,
    HasToggle: false,
    IsFakeValue: false,
    Key: 'O4',
    MaxValue: 0,
    Mnemonic: 'MIN days to deadline OKR',
    Position: 0,
    Status: 'Neutral',
    TitleKey: 'O4_TITLE',
    Type: 'OKR',
    Value: 239,
    ValueType: 'Number',
    WeightedValue: 0,
  };
  mode: any;
  onClose: Subject<{ type: string; emp: any }>;
  okrsecond: boolean;
  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private route: Router,
    private service: WallService,
    public coreService: CoreService
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.userData = this.coreService.getUserInfo();
    console.log(this.userData);
  }

  getProfileInfo(emp) {
    this.service.getEmployeeInfo(emp.Id).subscribe((data: any) => {
      if (data) {
        emp.empInfo = data;
        const employee = this.service.profiles.find((x) => x.Id === emp.Id);
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  ngAfterContentChecked() {
    // this.verb=`${this.empTeamList?.length}`

    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      this.mode = this.bsModalRef['content'].mode || 'list';
      this.empTeamList = this.bsModalRef['content'].empTeamList || [];
      if (this.mode === 'okrs' || this.mode === 'okr') {
        console.log(this.empTeamList);
        this.empTeamList.forEach((emp: any) => {
          if (this.service.profiles.length) {
            const employee = this.service.profiles.find((x) => x.Id === emp.Id);
            if (employee) {
              emp.empInfo = employee;
            } else {
              this.getProfileInfo(emp);
            }
          } else {
            this.getProfileInfo(emp);
          }
        });
        this.empTeamList?.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
            ? 1
            : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
              ? -1
              : 0;
        });
        var mode = this.bsModalRef['content'].mode || 'list';

        var len = this.empTeamList.length;
        var msg = '';
        switch (mode) {
          case 'okr':
            msg = '{0} ';
            this.isOkrs = true;
            break;
          case 'okrs':
            msg = '{0} ';
            this.isOkrs = true;
            this.okrsecond = true;
            break;
        }
        msg = msg.replace('{0}', len);
        this.Verb = msg;

        this.empTeamList.forEach((el) => {
          if (el.Type == 'Employee') this.isEmployee = true;
        });
        this.isAfterContentChecked = true;
      }
    }
  }
  goToProfile(emp) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: emp.Id },
      replaceUrl: true,
    });
    this.bsModalRef.hide();
  }

  openPopup(item: any) {
    item = this.openPop;
    this.coreService
      .getManagerEnteryPointBoxDetail(item.Key)
      .subscribe((data: any) => {
        this.bsModalRef = this.modalService.show(ManagerOkrPopupComponent, {
          class: 'okr-top-align-pop',
          ignoreBackdropClick: false,
          initialState: {
            response: data,
          },
        });
        this.bsModalRef.content.closeBtnName = 'Close';
      });
  }
}
