import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminLtiService } from '../admin-lti.service';
import { initScreen } from '../../app.helpers';
import { AppConfigService } from 'app/app-config.service';
import { smoothlyMenu } from '../../app.helpers';
import { CoreService } from '../../shared/services/core.service';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TranslateService } from '@ngx-translate/core';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GatewayComponent } from './gateway/gateway.component';
import { combineLatest } from 'rxjs';
import { SimpleDialogComponent } from '../../shared/simple-dialog/simple-dialog.component';
import { calculateLTIPlanParametes } from '../functions';
import { getPlanToSave } from '../functions';
import { getCompletionStatus } from '../functions';
import { PdfDialogComponent } from '../../shared/pdf-dialog/pdf-dialog.component';
import { interval } from 'rxjs';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: [
    './plans.component.scss',
    '../../magic-checkbox.scss',
    '../typography.scss',
  ],
})
export class PlansComponent implements OnInit {
  sideHistoryItems: any;
  currentPlan: any;
  originalPlan: any;
  intermediatePlan: any;
  currentTabIndex: number = 0;
  employeesList: any;
  currentEmployee: any;
  //  loading = false;
  employeesMap = {};

  userInfo: any;
  parameters: any;
  goals: any;
  modelBuilding = false;
  roundBonusValues: boolean = false;

  // Edit
  editParticipants: boolean = false;
  currentYear: number;
  selectedPlan: any;

  constructor(
    private adminLtiService: AdminLtiService,
    public coreService: CoreService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private appConfigService: AppConfigService,
    private router: Router,
    private pp360SpinnerService: Pp360SpinnerService
  ) {}

  showSideHistory(show: boolean = true) {
    if (show) $('#sideHistory').removeClass('minimized');
    else $('#sideHistory').addClass('minimized');
  }

  deleteItem(event: any) {
    console.log('deleteItem', event.type, event.index);
    switch (event.type) {
      case 'gateway':
        this.currentPlan.Gateways.splice(event.index, 1);
        break;
      case 'objective':
        this.currentPlan.Objectives.splice(event.index, 1);
        break;
      case 'corrective':
        this.currentPlan.Correctives.splice(event.index, 1);
        break;
    }
  }

  private parametersObsrv$;
  private plansObsrv$;
  private employeesObsrv$;
  private intervalSubscription;
  private autosaveIntervallSubscription;
  private statusSubscription;

  toggleNavigation(): void {
    // $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  ngAfterViewInit() {
    setTimeout(() => initScreen(), 100);

    this.appConfigService.replaceHeader$.next({
      showIcons: false,
      showTitle: true,
      pageIcon: 'fa-handshake-o',
      pageTitle: this.coreService.getTranslation('Menu.AdminLTI'),
    });
  }

  ngOnInit() {
    this.toggleNavigation();
    this.pp360SpinnerService.showSpinner(true);
    this.userInfo = this.coreService.getUserInfo();
    // CompanyId = this.userInfo.belongingCompanyId;

    this.parametersObsrv$ = this.adminLtiService.getParameters();
    this.plansObsrv$ = this.adminLtiService.getPlans();
    this.employeesObsrv$ = this.adminLtiService.getEmployees();

    combineLatest(
      this.parametersObsrv$,
      this.plansObsrv$,
      this.employeesObsrv$,
      (parametersObsrv, plansObsrv, employeesObsrv) => ({
        parametersObsrv,
        plansObsrv,
        employeesObsrv,
      })
    ).subscribe((pair: any) => {
      console.log(pair);
      this.parameters = pair.parametersObsrv;
      this.sideHistoryItems = pair.plansObsrv;
      this.employeesList = pair.employeesObsrv;
      this.createEmployeeMap();
      this.pp360SpinnerService.showSpinner(false);
    });
    this.initObjective();

    this.statusSubscription = interval(1000).subscribe(() =>
      getCompletionStatus(this.currentPlan)
    );
    this.intervalSubscription = interval(3000).subscribe(() =>
      calculateLTIPlanParametes(this.currentPlan, false)
    );
    this.autosaveIntervallSubscription = interval(120000).subscribe(() =>
      this.autoSave()
    );
  }

  ngOnDestroy() {
    this.statusSubscription.unsubscribe();
    this.intervalSubscription.unsubscribe();
    this.autosaveIntervallSubscription.unsubscribe();
  }

  onChangeName() {
    let item = this.sideHistoryItems.find((x) => x.Id == this.currentPlan.Id);
    if (item) {
      item.Name = this.currentPlan.Name;
    }
  }
  checkDisabled() {
    let disabled = true;
    if (this.currentPlan) {
      return this.currentPlan.Status
        ? !this.currentPlan.Status.isComplete()
        : true;
    }
    return disabled;
  }
  /**************************************************************************/
  removeParticipant(index: number) {
    this.currentPlan.PartecipantsByYear[0].Partecipants.splice(index, 1);
  }

  /**
   * Create a Map of employees
   */
  createEmployeeMap() {
    this.employeesList.forEach((emp) => {
      this.employeesMap[emp.Id] = emp;
    });
  }
  /**
   * Select a Plan on the sidebar, first saving current plan
   * @param plan selected plan
   */
  setCurrentPlan(plan: any) {
    //this.showSideHistory(false)

    //console.log('setCurrentPlan', plan);

    let autosaver = this.autoSave();
    if (autosaver) {
      autosaver.subscribe((x) => {
        //Wait the current plan is saved
        this.selectPlan(plan);
      });
    } else {
      this.selectPlan(plan);
    }
  }
  /**
   * Select a plan
   * @param plan selected plan
   */
  selectPlan(plan: any) {
    console.log('selectPlan', plan);
    this.selectedPlan = plan;
    this.adminLtiService.getPlan(plan.Id).subscribe((result: any) => {
      if (result) {
        if (!this.currentPlan || this.currentPlan.Id != result.Id) {
          //if (result.IsActive) {
          //CASO DI Piano già Inviato
          //}
          //else {
          this.roundBonusValues = result.RoundBonusFlag != 'None';
          if (
            !result.IsActive &&
            !result.IsReviewing &&
            result.PartecipantsByYear &&
            result.PartecipantsByYear.length > 0
          ) {
            //Mantain only first PartecipamntByYear
            let newArray = [];
            newArray.push(result.PartecipantsByYear[0]);
            result.PartecipantsByYear = newArray;

            //Map Employees to partecipants
            let emps = [];
            result.PartecipantsByYear[0].Partecipants.forEach(
              (element: any) => {
                let emp = this.employeesMap[element.EmployeeId];
                if (emp) {
                  emp.TargetBonusPercentage = element.TargetBonusPercentage;
                  emp.GrossAnnualSalary = element.GrossAnnualSalary;
                  emps.push(emp);
                }
              }
            );

            result.PartecipantsByYear[0].Partecipants = emps;

            let numberOfAccruedYear = result.YearEnd - result.YearStart + 1;

            let numberOfPayYear =
              result.PayoutStructure.PayoutYearEnd -
              result.PayoutStructure.PayoutYearStart +
              1;
            if (result.PayoutStructure.Rows.length != numberOfAccruedYear) {
              //Wrong number of Rows in PayoutStructure
              let rows = [];
              for (let y = result.YearStart; y <= result.YearEnd; y++) {
                let r = {
                  AccrueYear: y,
                  PayoutValues: [],
                };
                rows.push(r);
              }
              result.PayoutStructure.Rows = rows;
            }

            //FOR EACH ROW ADDS COLUMNS OF Payout Years
            result.PayoutStructure.Rows.forEach((row) => {
              if (row.PayoutValues.length != numberOfPayYear) {
                let values = [];
                for (
                  let y = result.PayoutStructure.PayoutYearStart;
                  y <= result.PayoutStructure.PayoutYearEnd;
                  y++
                ) {
                  let r = {
                    PayoutYear: y,
                    Cash: 0,
                    Equity: 0,
                  };
                  values.push(r);
                }
                row.PayoutValues = values;
              }
            });
          } else {
            //PIANO GIA' ATTIVO
            result.PartecipantsByYear.forEach((pby) => {
              let emps = [];
              pby.Partecipants.forEach((p) => {
                let emp = this.employeesMap[p.EmployeeId];
                if (emp) {
                  let connectedEmp = JSON.parse(JSON.stringify(emp));
                  connectedEmp.TargetBonusPercentage = p.TargetBonusPercentage;
                  connectedEmp.GrossAnnualSalary = p.GrossAnnualSalary;
                  emps.push(connectedEmp);
                }
              });
              pby.Partecipants = emps;
            });
          }

          result.Gateways.forEach((element: any) => {
            element.goals = this.goals;
            element.YearlyValues.forEach((yv) => {
              if (yv.TargetValue == -100000) yv.TargetValue = undefined;
            });
          });

          result.Objectives.forEach((element: any) => {
            element.goals = this.goals;
            element.YearlyValues.forEach((yv) => {
              if (yv.TargetValue == -100000) yv.TargetValue = undefined;
            });
          });

          result.Correctives.forEach((element: any) => {
            element.goals = this.goals;
            element.YearlyValues.forEach((yv) => {
              if (yv.TargetValue == -100000) yv.TargetValue = undefined;
            });
          });

          this.currentPlan = result;
          calculateLTIPlanParametes(this.currentPlan, false);
          getCompletionStatus(this.currentPlan);

          if (this.currentPlan.IsReviewing) {
            //CARICA IL PIANO ORIGINALE ATTIVO
            this.intermediatePlan = JSON.parse(
              JSON.stringify(this.currentPlan)
            ); //IL PIANO CORRENTE VIENE CLONATO
            this.adminLtiService
              .getPlan(this.currentPlan.ReferencePlanId)
              .subscribe((result: any) => {
                this.originalPlan = result;
                calculateLTIPlanParametes(this.originalPlan, false);
                this.editParticipants = true;
                this.currentYear = this.currentPlan.YearStart;
              });
          } else {
            this.originalPlan = JSON.parse(JSON.stringify(this.currentPlan)); //IL PIANO CORRENTE VIENE CLONATO
            this.currentYear = this.currentPlan.YearStart;
            calculateLTIPlanParametes(this.originalPlan, false);
          }

          if (result.IsActive) {
            this.editParticipants = false;
            this.currentYear = this.currentPlan.YearStart;
          }
        } //  if (!this.currentPlan || this.currentPlan.Id != result.Id)
      } //if(result)
      else {
        this.coreService.toasterMessage('error', 'LTI', 'Cannot Load Plan');
      }
    });
  }

  isSelected(item) {
    if (this.currentPlan) {
      return this.currentPlan.Id === item.Id;
    } else return false;
  }

  sendDraft() {
    this.autoSave();
    this.coreService.toasterMessage(
      'success',
      'AdminLTI',
      this.coreService.getTranslation('ADMIN_LTI.DRAFT_SAVED')
    );
  }

  deleteDraft() {
    const content: any = {
      YesCaption: this.coreService.getTranslation('YES'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('Messages.SureDelete'),
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.pp360SpinnerService.showSpinner(true);
          this.adminLtiService.deletePlan(this.currentPlan.Id).subscribe(() => {
            this.coreService.toasterMessage(
              'success',
              'AdminLTI',
              this.coreService.getTranslation('ADMIN_LTI.DRAFT_DELETED')
            );
            this.adminLtiService.getPlans().subscribe((r: any) => {
              this.sideHistoryItems = r;
              this.currentPlan = null;
              if (r && r.length > 0) this.selectPlan(r[0]);
            });
            this.pp360SpinnerService.showSpinner(false);
          });
        }
      }
    );
  }
  /**
   * Autosave Plan
   */
  autoSave() {
    if (this.currentPlan && this.currentPlan.Id) {
      if (!this.currentPlan.IsActive) {
        let savingPlan = getPlanToSave(
          this.currentPlan,
          !this.currentPlan.IsReviewing
        );
        let subscription = this.adminLtiService.editPlan(savingPlan);
        subscription.subscribe((r) => {
          console.log('saved!');
        });
        return subscription;
      }
    } else return null;
  }
  /**
   * Save current Plan and Create new Plan
   */
  newPlan() {
    let autosaver = this.autoSave();
    if (autosaver) {
      autosaver.subscribe((x) => {
        //Wait the current plan is saved
        this.createNewPlan();
      });
    } else {
      this.createNewPlan();
    }
  }
  /**
   * Create new Plan
   */
  createNewPlan() {
    this.currentPlan = {
      Name: 'My New Plan',
      CompanyId: this.userInfo.belongingCompanyId,
      Date: new Date(),
      YearStart: new Date().getFullYear(),
      YearEnd: new Date().getFullYear() + 1,
      RoundBonusFlag: 'None',
      RoundBonusValue: 1000,
      IsActive: false,
      Gateways: [],
      Objectives: [],
      Correctives: [],

      CorrectiveThresholdMax: 0,
      CorrectiveThresholdMin: 0,
      CorrectiveType: 'Rolling',
      PayoutStructure: {
        PayoutType: 'Cash',
        PayoutYearStart: new Date().getFullYear(),
        PayoutYearEnd: new Date().getFullYear() + 1,
        PayoutNote: undefined,
        Rows: [],
      },
      PartecipantsByYear: [
        {
          Year: new Date().getFullYear(),
          Partecipants: [],
        },
      ],
      Note: '',
      Clauses: '',
      Document: '',
      Attachment: '',

      ActivationDate: undefined,
    };

    //SAVE CURRENT PLAN and GET ID
    this.sideHistoryItems = [];
    this.pp360SpinnerService.showSpinner(true);
    this.adminLtiService.newPlan(this.currentPlan).subscribe((result: any) => {
      this.currentPlan.Id = result.Id;
      this.adminLtiService.getPlans().subscribe((r: any) => {
        this.sideHistoryItems = r;
      });
      this.pp360SpinnerService.showSpinner(false);
    });
  }

  /**
   * Handles event changing start year
   */
  onChangeYearStart() {
    this.currentPlan.PartecipantsByYear[0].Year = this.currentPlan.YearStart;

    this.onChangeYearEnd();
  }
  /**
   *  Handles event changing end year
   */
  onChangeYearEnd() {
    //Change the gateways, objectives, correctives
    this.arrangeObjects(this.currentPlan.Gateways);
    this.arrangeObjects(this.currentPlan.Objectives);
    this.arrangeObjects(this.currentPlan.Correctives);

    //change the payoutstructure
    this.initPayout();
  }
  /********************************************************************* */
  arrangeObjects(objects) {
    objects.forEach((o) => {
      let validValues = o.YearlyValues.filter(
        (v) =>
          v.Year >= this.currentPlan.YearStart &&
          v.Year <= this.currentPlan.YearEnd
      );
      let newValues = [];
      for (
        let y = this.currentPlan.YearStart;
        y <= this.currentPlan.YearEnd;
        y++
      ) {
        let existing = validValues.find((x) => x.Year == y);
        if (existing) {
          newValues.push(existing);
        } else {
          let newYearlyValue = {
            Year: y,
            TargetValue: 0,
            CurrentValue: 0,
          };
          newValues.push(newYearlyValue);
        }
      }

      o.YearlyValues = newValues;
    });
  }

  /**
   *
   * @param event Selects a tab
   * @param index
   */
  onSelectTab(event: TabDirective, index) {
    console.log('selectTab', event);
    this.currentTabIndex = index;
    console.log('onSelectTab', this.currentTabIndex);
    switch (index) {
      case 3:
        this.initPayout();
        break;
    }
    this.autoSave();
  }

  getFilteredEmployeeList(): any[] {
    if (this.currentPlan.PartecipantsByYear[0].Partecipants.length == 0)
      return this.employeesList;
    // Ritorno la differenza tra i due.
    return this.employeesList.filter(
      this.comparer(this.currentPlan.PartecipantsByYear[0].Partecipants)
    );
  }

  formatNumber(x: any, decimal: number = 2, currency: boolean = true) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    if (currency)
      return Number(value).toLocaleString(this.translateService.currentLang, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    else
      return Number(value).toLocaleString(this.translateService.currentLang, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
  }

  showSummaryBox() {
    return true;
  }

  sometingChanges() {
    return false;
  }

  yellowBox() {
    return (
      this.sometingChanges() ||
      (!this.currentPlan.IsActive && !this.currentPlan.IsReviewing)
    );
  }

  getTargetValue() {
    let n = 0.0;
    if (this.currentPlan.TotalBonus) n = this.currentPlan.TotalBonus.Target;
    return this.formatNumber(n, 0);
  }
  getMinValue() {
    return this.formatNumber(0);
  }
  getMaxValue() {
    return this.formatNumber(0);
  }

  showHelp() {
    const content: any = {
      Title: this.coreService.getTranslation('ADMIN_LTI.HELP_DIALOG.TITLE'),
      CorrectionsRollingBtn: this.coreService.getTranslation(
        'ADMIN_LTI.HELP_DIALOG.ROLLING'
      ),
      CorrectionsPlanEndBtn: this.coreService.getTranslation(
        'ADMIN_LTI.HELP_DIALOG.PLAN_END'
      ),
    };

    const modal = this.modalService.show(HelpDialogComponent, {
      class: 'adminLTI-popup',
    });
    (<HelpDialogComponent>modal.content).showModal(content);
  }

  /*******************************************************************************
   _______ _                       _____           _            _                   _
  |__   __(_)                ___  |  __ \         | |          (_)                 | |
    | |   _ _ __ ___   ___ ( _ ) | |__) |_ _ _ __| |_ ___  ___ _ _ __   __ _ _ __ | |_ ___
    | |  | | '_ ` _ \ / _ \/ _ \/\  ___/ _` | '__| __/ _ \/ __| | '_ \ / _` | '_ \| __/ __|
    | |  | | | | | | |  __/ (_>  < |  | (_| | |  | ||  __/ (__| | |_) | (_| | | | | |_\__ \
    |_|  |_|_| |_| |_|\___|\___/\/_|   \__,_|_|   \__\___|\___|_| .__/ \__,_|_| |_|\__|___/
                                                                | |
                                                                |_|
  *******************************************************************************/

  onChangeRoudBonus() {
    this.roundBonusValues = !this.roundBonusValues;
    if (this.roundBonusValues) {
      if (this.currentPlan.RoundBonusFlag === 'None') {
        this.currentPlan.RoundBonusFlag = 'Up';
      }
    } else {
      this.currentPlan.RoundBonusFlag = 'None';
    }
  }
  getPlanFromYear() {
    let currentYear: number = Number(new Date().getFullYear()) - 2;
    let years: number[] = [];
    for (let index = 0; index < 10; index++) {
      years.push(currentYear++);
    }
    return years;
  }

  getPlanToYear() {
    let currentYear: number = Number(new Date().getFullYear()) - 1;
    let years: number[] = [];
    for (let index = 0; index < 12; index++) {
      years.push(currentYear++);
    }
    return years;
  }

  comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.Id == current.Id;
        }).length == 0
      );
    };
  }

  searchedEmployee: any;
  onSelectEmployee(selectedEmployee: any) {
    console.log('onSelectEmployee', selectedEmployee);
    const alreadyExists =
      this.currentPlan.PartecipantsByYear[0].Partecipants.length > 0
        ? this.currentPlan.PartecipantsByYear[0].Partecipants.filter(
            (employee: any) => employee.Id === selectedEmployee.Id
          ).length > 0
        : false;
    if (!alreadyExists)
      this.currentPlan.PartecipantsByYear[0].Partecipants.push(
        selectedEmployee
      );
    this.searchedEmployee = undefined;
  }

  checkTargetBonus(participant: any) {}

  getTotTable() {
    let totTable: number = 0;
    let pby = this.currentPlan.PartecipantsByYear.find(
      (x) => x.Year === this.currentYear
    );
    if (!pby) {
      pby = this.currentPlan.PartecipantsByYear[0];
    }
    if (pby) {
      pby.Partecipants.forEach(
        (participant: any) => (totTable += participant.TargetBonus)
      );
    }

    return totTable;
  }

  /*******************************************************************************
      _____       _
    / ____|     | |
    | |  __  __ _| |_ _____      ____ _ _   _ ___
    | | |_ |/ _` | __/ _ \ \ /\ / / _` | | | / __|
    | |__| | (_| | ||  __/\ V  V / (_| | |_| \__ \
    \_____|\__,_|\__\___| \_/\_/ \__,_|\__, |___/
                                       __/ |
                                      |___/
  *******************************************************************************/

  gatewaysList: any[] = [];

  getGatewayTargetBonus() {
    return this.getTargetValue();
  }

  getGatewayTargetBonusMultiplierValue() {
    if (
      this.currentPlan &&
      this.currentPlan.Multipliers &&
      this.currentPlan.Multipliers.multipliers &&
      this.currentPlan.Multipliers.multipliers.gateways
    ) {
      let g = this.currentPlan.Multipliers.multipliers.gateways;

      if (!isNaN(g.min) && !isNaN(g.max)) {
        return { min: g.min, max: g.max };
      }
    }

    return { min: 1, max: 1 };
  }

  getGatewayTargetBonusMultiplier() {
    let v = this.getGatewayTargetBonusMultiplierValue();

    if (v.min === v.max) {
      return this.formatNumber(v.min, 2, false);
    } else {
      return (
        this.formatNumber(v.min, 2, false) +
        '-' +
        this.formatNumber(v.max, 2, false)
      );
    }
  }

  getAdmittedBonusValue() {
    let n = 0.0;
    if (this.currentPlan.TotalBonus) n = this.currentPlan.TotalBonus.Target;
    let v = this.getGatewayTargetBonusMultiplierValue();
    return { min: v.min * n, max: v.max * n };
  }

  getAdmittedBonus() {
    let n = this.getAdmittedBonusValue();
    return this.formatNumber(n.min, 0) + ' - ' + this.formatNumber(n.max, 0);
  }

  getAdmittedBonusGateways() {
    let n = this.getAdmittedBonusValue();

    if (this.currentPlan.Gateways.find((item: any) => item.IsCorridor)) {
      return this.formatNumber(n.min, 0) + ' - ' + this.formatNumber(n.max, 0);
    } else {
      return this.formatNumber(n.min, 0);
    }
  }

  addNewGateway() {
    let years: number[] = [];
    let yearlyValues: any[] = [];

    for (
      let index = this.currentPlan.YearStart;
      index <= this.currentPlan.YearEnd;
      index++
    ) {
      years.push(index);
      yearlyValues.push({
        Year: index,
        TargetValue: undefined,
        CurrentValue: undefined,
      });
    }
    this.currentPlan.Gateways.forEach(
      (element: any) => (element.isOpen = false)
    );
    this.currentPlan.Gateways.push({
      goals: this.goals,
      IsCorridor: false,
      Min: 90,
      Max: 110,
      Floor: 80,
      Cap: 120,
      Weight: 100,
      years: years,
      YearlyValues: yearlyValues,
      isOpen: true,
    });
  }

  getAllGatewayWeight() {
    let sum: number = 0;
    if (this.currentPlan) {
      this.currentPlan.Gateways.forEach((element: any) => {
        sum += parseInt(element.Weight);
      });
      return sum;
    } else {
      return 100;
    }
  }

  /*******************************************************************************
     ____  _     _           _   _
    / __ \| |   (_)         | | (_)
   | |  | | |__  _  ___  ___| |_ ___   _____
   | |  | | '_ \| |/ _ \/ __| __| \ \ / / _ \
   | |__| | |_) | |  __/ (__| |_| |\ V /  __/
    \____/|_.__/| |\___|\___|\__|_| \_/ \___|
               _/ |
              |__/
  *******************************************************************************/

  objectiveList: any[] = [];

  initObjective() {
    this.adminLtiService.getGoals().subscribe((goals: any) => {
      this.goals = goals;
    });
  }

  getObjectiveTargetBonusMultiplierValue() {
    if (
      this.currentPlan &&
      this.currentPlan.Multipliers &&
      this.currentPlan.Multipliers.multipliers &&
      this.currentPlan.Multipliers.multipliers.objectives
    ) {
      let g = this.currentPlan.Multipliers.multipliers.objectives;

      if (!isNaN(g.min) && !isNaN(g.max)) {
        return { min: g.min, max: g.max };
      }
    }

    return { min: 1, max: 1 };
  }

  getObjectiveTargetBonusMultiplier() {
    let v = this.getObjectiveTargetBonusMultiplierValue();

    if (v.min === v.max) {
      return this.formatNumber(v.min, 2, false);
    } else {
      return (
        this.formatNumber(v.min, 2, false) +
        '-' +
        this.formatNumber(v.max, 2, false)
      );
    }
  }

  getAccruedBonusValue() {
    let n = this.getAdmittedBonusValue();
    let v = this.getObjectiveTargetBonusMultiplierValue();
    return { min: v.min * n.min, max: v.max * n.max };
  }

  getAccruedBonus() {
    let n = this.getAccruedBonusValue();
    return this.formatNumber(n.min, 0) + ' - ' + this.formatNumber(n.max, 0);
  }

  addNewObjective() {
    let years: number[] = [];
    let yearlyValues: any[] = [];
    for (
      let index = this.currentPlan.YearStart;
      index <= this.currentPlan.YearEnd;
      index++
    ) {
      years.push(index);
      yearlyValues.push({
        Year: index,
        TargetValue: undefined,
        CurrentValue: undefined,
      });
    }
    this.currentPlan.Objectives.forEach(
      (element: any) => (element.isOpen = false)
    );
    this.currentPlan.Objectives.push({
      goals: this.goals,
      IsCorridor: false,
      Weight: 100,
      Min: 90,
      Max: 110,
      Floor: 80,
      Cap: 120,
      years: years,
      YearlyValues: yearlyValues,
      isOpen: true,
    });
  }

  getAllObjectiveWeight() {
    let sum: number = 0;
    if (this.currentPlan) {
      this.currentPlan.Objectives.forEach((element: any) => {
        sum += parseInt(element.Weight);
      });
      return sum;
    } else {
      return 100;
    }
  }

  /*******************************************************************************
     _____                         _   _            ____  _     _           _   _
    / ____|                       | | (_)          / __ \| |   (_)         | | (_)
   | |     ___  _ __ _ __ ___  ___| |_ ___   _____| |  | | |__  _  ___  ___| |_ ___   _____  ___
   | |    / _ \| '__| '__/ _ \/ __| __| \ \ / / _ \ |  | | '_ \| |/ _ \/ __| __| \ \ / / _ \/ __|
   | |___| (_) | |  | | |  __/ (__| |_| |\ V /  __/ |__| | |_) | |  __/ (__| |_| |\ V /  __/\__ \
    \_____\___/|_|  |_|  \___|\___|\__|_| \_/ \___|\____/|_.__/| |\___|\___|\__|_| \_/ \___||___/
                                                              _/ |
                                                             |__/
  *******************************************************************************/

  correctiveObjectiveList: any[] = [];

  getCorrectiveTargetBonusMultiplierValue() {
    if (
      this.currentPlan &&
      this.currentPlan.Multipliers &&
      this.currentPlan.Multipliers.multipliers &&
      this.currentPlan.Multipliers.multipliers.correctives
    ) {
      let g = this.currentPlan.Multipliers.multipliers.correctives;

      if (!isNaN(g.min) && !isNaN(g.max)) {
        return { min: g.min, max: g.max };
      }
    }

    return { min: 1, max: 1 };
  }

  getCorrectiveOTargetBonusMultiplier() {
    let v = this.getCorrectiveTargetBonusMultiplierValue();

    if (v.min === v.max) {
      return v.min.toFixed(2);
    } else {
      return v.min.toFixed(2) + '-' + v.max.toFixed(2);
    }

    return 1.0;
  }

  getCorrectiveAdmittedBonusValue() {
    let n = 0.0;
    if (this.currentPlan.TotalBonus) n = this.currentPlan.TotalBonus.Target;
    let v = this.getCorrectiveTargetBonusMultiplierValue();
    return { min: v.min * n, max: v.max * n };
  }

  getCorrectiveAdmittedBonus() {
    let n = this.getCorrectiveAdmittedBonusValue();

    return this.formatNumber(n.min) + ' - ' + this.formatNumber(n.max);
  }

  addNewCorrectiveObjective() {
    let years: number[] = [];
    let yearlyValues: any[] = [];
    for (
      let index = this.currentPlan.YearStart;
      index <= this.currentPlan.YearEnd;
      index++
    ) {
      years.push(index);
      yearlyValues.push({
        Year: index,
        TargetValue: undefined,
        CurrentValue: undefined,
      });
    }
    this.currentPlan.Correctives.forEach(
      (element: any) => (element.isOpen = false)
    );
    let itCor = {
      goals: this.goals,
      // IsCorridor: true,
      Weight: 100,
      Min: 90,
      Max: 110,
      Floor: 80,
      Cap: 120,
      years: years,
      YearlyValues: yearlyValues,
      isOpen: true,
    };
    // if(this.goals.length>0){
    //   itCor['GoalId']=this.goals[0].Id;
    // }
    this.currentPlan.Correctives.push(itCor);
  }

  getAllCorrectiveObjectiveWeight() {
    let sum: number = 0;
    if (this.currentPlan) {
      this.currentPlan.Correctives.forEach((element: any) => {
        sum += parseInt(element.Weight);
      });
      return sum;
    } else {
      return 100;
    }
  }

  /*******************************************************************************
    _____                        _
   |  __ \                      | |
   | |__) |_ _ _   _  ___  _   _| |_
   |  ___/ _` | | | |/ _ \| | | | __|
   | |  | (_| | |_| | (_) | |_| | |_
   |_|   \__,_|\__, |\___/ \__,_|\__|
                __/ |
               |___/


   public class LTIPayoutStructureDTO
   {
      public PayoutType PayoutType { get; set; }
      public short PayoutYearEnd { get; set; }
      public short PayoutYearStart { get; set; }
      public string PayoutNote { get; set; }

      public List<LTIPayoutStructureRowDTO> Rows { get; set; }
   }

  public class LTIPayoutStructureRowDTO
  {
     public short AccrueYear { get; set; }
     public List<LTIPayoutValueDTO> PayoutValues { get; set; }
  }

  public class LTIPayoutValueDTO
  {
   public short PayoutYear { get; set; }
   public double Cash { get; set; }
   public double Equity { get; set; }
  }


  *******************************************************************************/
  getPayFromYear() {
    let currentYear: number = this.currentPlan.YearStart;
    let years: number[] = [];
    for (let index = 0; index < 10; index++) {
      years.push(currentYear++);
    }
    return years;
  }

  getPayToYear() {
    let currentYear: number = this.currentPlan.YearStart;
    let years: number[] = [];
    for (let index = 0; index < 10; index++) {
      years.push(currentYear++);
    }
    return years;
  }

  /**
   * Init the payout structure adding
   */
  initPayout() {
    if (!this.currentPlan.PayoutStructure.PayoutYearStart) {
      this.currentPlan.PayoutStructure.PayoutYearStart =
        this.currentPlan.YearStart + 1;
    }
    if (!this.currentPlan.PayoutStructure.PayoutYearEnd) {
      this.currentPlan.PayoutStructure.PayoutYearEnd =
        this.currentPlan.YearEnd + 1;
    }

    if (this.currentPlan.PayoutStructure.Rows.length == 0) {
      for (
        let year = this.currentPlan.YearStart;
        year <= this.currentPlan.YearEnd;
        year++
      ) {
        this.currentPlan.PayoutStructure.Rows.push({
          AccrueYear: year,
          PayoutValues: new Array(
            this.currentPlan.PayoutStructure.PayoutYearEnd -
              this.currentPlan.PayoutStructure.PayoutYearStart +
              1
          )
            .fill({})
            .map((val, idx) => {
              return {
                PayoutYear: this.currentPlan.YearStart + idx,
                Cash: 0,
                Equity: 0,
              };
            }),
        });
      }
    } else {
      this.currentPlan.PayoutStructure.PayoutYearStart =
        this.currentPlan.PayoutStructure.PayoutYearStart <
        this.currentPlan.YearStart
          ? this.currentPlan.YearStart
          : this.currentPlan.PayoutStructure.PayoutYearStart;
      this.currentPlan.PayoutStructure.PayoutYearEnd =
        this.currentPlan.PayoutStructure.PayoutYearEnd <
        this.currentPlan.YearEnd
          ? this.currentPlan.YearEnd
          : this.currentPlan.PayoutStructure.PayoutYearEnd;

      let validRows = this.currentPlan.PayoutStructure.Rows.filter(
        (x) =>
          x.AccrueYear >= this.currentPlan.YearStart &&
          x.AccrueYear <= this.currentPlan.YearEnd
      );
      let newRowsArray = [];
      for (
        let aY = this.currentPlan.YearStart;
        aY <= this.currentPlan.YearEnd;
        aY++
      ) {
        let existingRow = validRows.find((x) => x.AccrueYear == aY);
        if (existingRow) {
          let correctedRow = this.corectPayoutRow(existingRow);
          newRowsArray.push(correctedRow);
        } else {
          let newRow = {
            AccrueYear: aY,
            PayoutValues: new Array(
              this.currentPlan.PayoutStructure.PayoutYearEnd -
                this.currentPlan.PayoutStructure.PayoutYearStart +
                1
            )
              .fill({})
              .map((val, idx) => {
                return {
                  PayoutYear: this.currentPlan.YearStart + idx,
                  Cash: 0,
                  Equity: 0,
                };
              }),
          };

          newRowsArray.push(newRow);
        }
      }

      this.currentPlan.PayoutStructure.Rows = newRowsArray;
    }

    console.log(this.currentPlan.PayoutStructure);
  } //initPayout()

  /**
   * Correct row
   * @param row Payoutrow
   */
  corectPayoutRow(row) {
    let validCols = row.PayoutValues.filter(
      (x) =>
        x.PayoutYear >= this.currentPlan.PayoutStructure.PayoutYearStart &&
        x.PayoutYear <= this.currentPlan.PayoutStructure.PayoutYearEnd
    );
    let newColsArray = [];
    for (
      let y = this.currentPlan.PayoutStructure.PayoutYearStart;
      y <= this.currentPlan.PayoutStructure.PayoutYearEnd;
      y++
    ) {
      let existing = validCols.find((x) => x.PayoutYear == y);
      if (existing) {
        newColsArray.push(existing);
      } else {
        let newCol = {
          PayoutYear: y,
          Cash: 0,
          Equity: 0,
        };

        newColsArray.push(newCol);
      }
    }

    row.PayoutValues = newColsArray;
    return row;
  }
  /**
   * Changes datamodel when changing Year Start or End
   */
  onChangePayStructure() {
    this.modelBuilding = true;
    let payStruct = this.currentPlan.PayoutStructure;
    let yStart = payStruct.PayoutYearStart;
    let yEnd = payStruct.PayoutYearEnd;
    payStruct.Rows.forEach((row) => {
      let values = [];
      for (let y = yStart; y <= yEnd; y++) {
        let existing = row.PayoutValues.find((x) => x.PayoutYear == y);
        let r = {
          PayoutYear: y,
          Cash: 0,
          Equity: 0,
        };
        if (existing) {
          r.Cash = existing.Cash;
          r.Equity = existing.Equity;
        }
        values.push(r);
      }
      row.PayoutValues = values;
    });

    setTimeout(() => {
      this.modelBuilding = false;
    }, 200);
  }

  payoutColumns() {
    if (
      !this.currentPlan ||
      !this.currentPlan.PayoutStructure ||
      !this.currentPlan.PayoutStructure.PayoutYearEnd ||
      !this.currentPlan.PayoutStructure.PayoutYearStart
    )
      return [];
    let arr = new Array(
      this.currentPlan.PayoutStructure.PayoutYearEnd -
        this.currentPlan.PayoutStructure.PayoutYearStart +
        1
    ).fill(1);
    return arr;
  }

  checkDisabledInput(row: any, col: any) {
    // return false; //col.PayoutYear < row.AccrueYear;
    return col.PayoutYear < row.AccrueYear;
  }

  getTotRow(row: number) {
    let tot: number = 0;
    this.currentPlan.PayoutStructure.Rows[row].PayoutValues.forEach(
      (col: any) => {
        if (col.Cash > 0) tot += Number(col.Cash.toString());
        if (col.Equity > 0) tot += Number(col.Equity.toString());
      }
    );
    return tot;
  }

  /*******************************************************************************

    _____            _                          _____  _     _        _ _           _
   |  __ \          (_)                 ___    |  __ \(_)   | |      (_) |         | |
   | |__) |_____   ___  _____      __  ( _ )   | |  | |_ ___| |_ _ __ _| |__  _   _| |_ ___
   |  _  // _ \ \ / / |/ _ \ \ /\ / /  / _ \/\ | |  | | / __| __| '__| | '_ \| | | | __/ _ \
   | | \ \  __/\ V /| |  __/\ V  V /  | (_>  < | |__| | \__ \ |_| |  | | |_) | |_| | ||  __/
   |_|  \_\___| \_/ |_|\___| \_/\_/    \___/\/ |_____/|_|___/\__|_|  |_|_.__/ \__,_|\__\___|


  public string Note { get; set; }
  public string Clauses { get; set; }
  public string Document { get; set; }
  public string Attachment { get; set; }


  public DateTime Date { get; set; }
  public DateTime? ActivationDate { get; private set; }

  public bool IsActive { get; set; }
  public bool IsDisabled { get; set; }

  *******************************************************************************/
  reviewRows: any[] = [];

  getReviewTotTarget() {
    return 0;
  }

  getReviewTotMin() {
    return 0;
  }

  getReviewTotMax() {
    return 0;
  }

  isUploading: boolean = false;
  uploaded: boolean = false;
  uploadTerms(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.isUploading = true;
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.adminLtiService.upload(this.currentPlan.Id, formData).subscribe(
        (data: any) => {
          this.isUploading = false;
          this.uploaded = true;
          this.currentPlan.Attachment = data.AttachedFile;
          this.currentPlan.AttachmentName = file.name;
        },
        (err: any) => {
          this.isUploading = false;
        }
      );
    }
  }

  sendLtiTerms() {
    console.log(this.currentPlan);
    if (this.currentPlan.Id && !this.currentPlan.IsActive) {
      const content: any = {
        YesCaption: this.coreService.getTranslation('YES'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation(
          'ADMIN_LTI.REVIEW_DIST.SEND_ASK'
        ),
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation',
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.adminLtiService
              .activatePlan(this.currentPlan)
              .subscribe((result: any) => {
                const content: any = {
                  YesCaption: this.coreService.getTranslation('OK'),
                  type: 'Ok',
                  title: this.coreService.getTranslation(
                    'ADMIN_LTI.REVIEW_DIST.SEND_CONFIRM'
                  ),
                };

                const modal = this.modalService.show(SimpleDialogComponent, {
                  class: 'self-evaluation',
                });
                (<SimpleDialogComponent>modal.content).showModal(content);
                this.pp360SpinnerService.showSpinner(true);
                (<SimpleDialogComponent>modal.content).onClose.subscribe(
                  (result: boolean) => {
                    if (result) {
                      this.adminLtiService
                        .activatePlan(this.currentPlan)
                        .subscribe((result: any) => {
                          this.adminLtiService
                            .getPlans()
                            .subscribe((r: any) => {
                              this.sideHistoryItems = r;

                              const currplan = this.sideHistoryItems.find(
                                (plan: any) => (plan.Id = this.currentPlan.Id)
                              );
                              this.currentPlan = undefined;
                              this.setCurrentPlan(currplan);
                            });
                        });
                    }
                    this.pp360SpinnerService.showSpinner(false);
                  }
                );
              });
          }
        }
      );
    }
  }

  /**********************************************************

    ______    _ _ _     _____           _   _      _                   _
   |  ____|  | (_) |   |  __ \         | | (_)    (_)                 | |
   | |__   __| |_| |_  | |__) |_ _ _ __| |_ _  ___ _ _ __   __ _ _ __ | |_ ___
   |  __| / _` | | __| |  ___/ _` | '__| __| |/ __| | '_ \ / _` | '_ \| __/ __|
   | |___| (_| | | |_  | |  | (_| | |  | |_| | (__| | |_) | (_| | | | | |_\__ \
   |______\__,_|_|\__| |_|   \__,_|_|   \__|_|\___|_| .__/ \__,_|_| |_|\__|___/
                                                    | |
                                                    |_|
  **********************************************************/

  enableEditParticipants() {
    const content: any = {
      YesCaption: this.coreService.getTranslation('YES'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'ADMIN_LTI.TIME_PART.BODY.EDIT_PARTICIPANTS_MSG_DIALOG'
      ),
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.editParticipants = true;
          //IL PIANO CORRENTE DIVENTA In Review MENTRE SI CONSERVA IN ORIGINALPLAN QUELLO ATTIVO
          this.currentPlan.IsActive = false;
          this.currentPlan.IsReviewing = true;
          this.currentPlan.ReferencePlanId = this.currentPlan.Id;
          this.currentPlan.Id = undefined;
          this.currentPlan.ActivationDate = undefined;
          //SALVO IL CURRENT PLAN CHE E' IN REVIEW

          //SAVE CURRENT PLAN and GET ID
          this.sideHistoryItems = [];
          let savingPlan = getPlanToSave(this.currentPlan, false);
          savingPlan.Date = Date.now();
          this.pp360SpinnerService.showSpinner(true);
          this.adminLtiService.newPlan(savingPlan).subscribe((result: any) => {
            this.currentPlan.Id = result.Id;
            this.adminLtiService.getPlans().subscribe((r: any) => {
              this.sideHistoryItems = r;
            });
            this.pp360SpinnerService.showSpinner(false);
          });
        }
      }
    );
  }

  plansLength() {
    if (
      !this.currentPlan ||
      !this.currentPlan.YearEnd ||
      !this.currentPlan.YearStart
    )
      return [];
    return new Array(this.currentPlan.YearEnd - this.currentPlan.YearStart + 1)
      .fill(1)
      .map((val, idx) => this.currentPlan.YearStart + idx);
  }

  getPartecipantsByYear(year: number) {
    const result = this.currentPlan.PartecipantsByYear.find(
      (partYear) => partYear.Year == year
    );
    return result ? result.Partecipants : [];
  }

  isEditable() {
    const actualYear = new Date().getFullYear();
    return this.editParticipants && this.currentYear > actualYear;
  }

  onSelectEmployeeEdit(selectedEmployee: any) {
    const partecipantsByYear = this.currentPlan.PartecipantsByYear.filter(
      (partByYear) => partByYear.Year >= this.currentYear
    );
    if (partecipantsByYear && partecipantsByYear.length > 0) {
      partecipantsByYear.forEach((partecipant: any) => {
        const alreadyExists =
          partecipant.Partecipants.filter(
            (employee: any) => employee.Id === selectedEmployee.Id
          ).length > 0;
        if (!alreadyExists) partecipant.Partecipants.push(selectedEmployee);
      });
    }
    this.searchedEmployee = undefined;
  }

  removeParticipantEdit(participant: any) {
    const partecipantsByYear = this.currentPlan.PartecipantsByYear.filter(
      (partByYear) => partByYear.Year >= this.currentYear
    );
    if (partecipantsByYear && partecipantsByYear.length > 0) {
      partecipantsByYear.forEach((partecipantByYear: any) => {
        let index = 0,
          removeIndex = -1;

        partecipantByYear.Partecipants.forEach((employee) => {
          console.log(employee, participant, index);
          if (employee.Id === participant.Id) {
            removeIndex = index;
          }
          index++;
        });
        if (removeIndex > 0)
          partecipantByYear.Partecipants.splice(removeIndex, 1);
      });
    }
  }

  checkTargetBonusEdit(participant: any) {
    const partecipantsByYear = this.currentPlan.PartecipantsByYear.filter(
      (partByYear) => partByYear.Year > this.currentYear
    );
    if (partecipantsByYear && partecipantsByYear.length > 0) {
      partecipantsByYear.forEach((partecipantByYear: any) => {
        partecipantByYear.Partecipants.forEach((employee) => {
          if (employee.Id === participant.Id) {
            employee = JSON.parse(JSON.stringify(participant));
          }
        });
      });
    }
  }

  openPdf(pdfUrl: string) {
    if (pdfUrl) {
      pdfUrl = this.coreService.getImageURL(pdfUrl, undefined);
      const content: any = {
        pdfUrl: pdfUrl,
      };
      const modal = this.modalService.show(PdfDialogComponent, {
        class: 'pdw-view',
      });
      (<PdfDialogComponent>modal.content).showModal(content);
    } else {
      //Nothing to show
    }
  }

  cancelDraft() {
    const currentId = this.currentPlan.Id;
    this.currentPlan = undefined;
    if (this.intermediatePlan) {
      this.currentPlan = JSON.parse(JSON.stringify(this.intermediatePlan));
      getCompletionStatus(this.currentPlan);
      calculateLTIPlanParametes(this.currentPlan, false);
      this.editParticipants = true;
    } else {
      this.currentPlan = JSON.parse(JSON.stringify(this.originalPlan));
      getCompletionStatus(this.currentPlan);
      calculateLTIPlanParametes(this.currentPlan, false);
      this.editParticipants = false;
    }
    if (this.currentPlan.IsActive) {
      //IN QUESTO CASO E' POSSIBILE ELIMINARE IL CLONE
      this.pp360SpinnerService.showSpinner(true);
      this.adminLtiService.deletePlan(currentId).subscribe(() => {
        this.adminLtiService.getPlans().subscribe((r: any) => {
          this.sideHistoryItems = r;
        });
        this.pp360SpinnerService.showSpinner(false);
      });
    }
  }
  goBack() {
    this.router.navigate(['/TileMenu/menu/company-admin']);
  }
} // End class
