import { CoreService } from 'app/shared/services/core.service';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { UserService } from 'app/super-admin/user/user.service';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { RestfulService } from 'app/shared/services/restful.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-theme-change',
  templateUrl: './theme-change.component.html',
  styleUrls: ['./theme-change.component.scss'],
})
export class ThemeChangeComponent implements OnInit {
  // idata: any;
  public isMobile: boolean;
  @Input() condition: boolean;
  CurrentUser: any = [];
  somethingChanged = false;
  properties = [];
  themes: any = [];
  @Output()
  message: EventEmitter<string> = new EventEmitter();
  scrollShow: boolean;
  themeCode: any;
  variationCodeNo: any;
  tName: any = 'light';
  @Output() OnLoaded: EventEmitter<any> = new EventEmitter();
  companyTheme: boolean = false;
  isSetTheme: boolean;
  adminThemeSet: any;

  constructor(
    private coreService: CoreService,
    public screenSizeService: ScreenSizeService,
    public service: UserService,
    public restServ: RestfulService,
    private router: Router,
    private pp360SpinnerService: Pp360SpinnerService
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 800;
    });
  }
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.scrollShow = true;
    if (this.scrollShow) {
      setTimeout(() => {
        this.scrollShow = false;
      }, 1000);
    }
  }
  ngOnInit() {
    this.CurrentUser = this.coreService.getUserInfo();
    // this.idata = {
    //   value: 'light',
    //   image: './assets/images/theme/RoyalBlue/light.png',
    // };
    if (this.router.url === '/CompanyAdmin/Customizations/Colors') {
      this.companyTheme = true;
      this.getCompanyTheme();
    } else {
      this.companyTheme = false;
    }
    this.getThemes();
  }

  getThemes() {
    this.themeCode = this.CurrentUser.Theme?.Code;
    this.variationCodeNo = this.CurrentUser.Theme?.VariationCode;
    this.tName = this.CurrentUser.Theme?.VariationName;
    this.pp360SpinnerService.showSpinner(true);
    this.service.getAvailableThemes().subscribe(
      (data: any) => {
        this.themes = JSON.parse(JSON.stringify(data));
        if (!this.themeCode && !this.variationCodeNo) {
          this.themeCode = this.themes[0].Code;
          this.variationCodeNo = this.themes[0].SubThemes[0].VariationCode;
          this.tName = this.themes[0].SubThemes[0].VariationName;
        }
        for (let i of this.themes) {
          i.SubThemes = i.SubThemes.reverse();
          if (i.SubThemes[0].VariationCode == 'D') {
            i['image'] = 'dark';
          } else if (i.SubThemes[0].VariationCode == 'F') {
            i['image'] = 'flip';
          } else if (i.SubThemes[0].VariationCode == 'L') {
            i['image'] = 'light';
          }
          if (i.Code == this.themeCode) {
            i.image = this.tName.toLowerCase();
            if (this.tName.toLowerCase() == 'flipside') {
              i.image = 'flip';
            }
          }

          if (this.CurrentUser.Theme) {
            if (this.CurrentUser.Theme.Code === i.Code) {
              i.selected = true;
            } else {
              i.selected = false;
            }
          } else if (data[0].Code === i.Code) {
            i.selected = true;
          } else {
            i.selected = false;
          }
        }
        setTimeout(() => {
          this.pp360SpinnerService.showSpinner(false);
        }, 1000);
      },
      (err: any) => {
        console.log('err', err);
        this.pp360SpinnerService.showSpinner(false);
      }
    );
    this.changeTheme(this.themeCode, this.variationCodeNo);
    this.coreService.getCompanyTheme().subscribe((data: any) => {
      this.isSetTheme = data.EmployeesCanCustomizeTheme;
      this.themeCode = this.themeCode ? this.themeCode : data.ThemeCode;
      this.variationCodeNo = this.variationCodeNo
        ? this.variationCodeNo
        : data.ThemeVariationCode;
    });
  }
  back() {
    this.message.emit('true');
  }
  selectedTheme(data) {
    for (let i of this.themes) {
      if (data.Code === i.Code) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      if (i.Code == this.themeCode) {
        this.variationCodeNo = i.SubThemes[0].VariationCode;
        if (data.image == 'light') {
          this.variationCodeNo = 'L';
        }
        if (data.image == 'flip') {
          this.variationCodeNo = 'F';
        }
        if (data.image == 'dark') {
          this.variationCodeNo = 'D';
        }
      }
    }
  }
  selectedThemes(i, data) {
    this.variationCodeNo = i.VariationCode;
    this.themeCode = i.Code;
    this.tName = i.VariationName.toLowerCase();
    if (this.tName == 'flipside') {
      this.tName = 'flip';
    }
    data['image'] = this.tName;
  }
  onSave() {
    if (!this.companyTheme) {
      let body = {
        Code: this.themeCode,
        VariationCode: this.variationCodeNo,
      };
      this.pp360SpinnerService.showSpinner(true);
      this.service.setUserTheme(body).subscribe(
        async (data: any) => {
          await this.changeTheme(this.themeCode, this.variationCodeNo);
          await this.getUpdatedUserInfo();
          setTimeout(() => {
            this.OnLoaded.emit();
            this.pp360SpinnerService.showSpinner(false);
          }, 5000);
        },
        (err: any) => {
          console.log('err', err);
          this.OnLoaded.emit();
          this.pp360SpinnerService.showSpinner(false);
        }
      );
      console.log(this.CurrentUser);
    } else {
      if (this.adminThemeSet) {
        let body = {
          ThemeCode: this.themeCode,
          ThemeVariationCode: this.variationCodeNo,
          EmployeesCanCustomizeTheme: this.isSetTheme,
        };
        this.pp360SpinnerService.showSpinner(true);
        this.coreService.changeCompanyTheme(body).subscribe(
          (data: any) => {
            setTimeout(() => {
              this.changeTheme(this.themeCode, this.variationCodeNo);
              this.getUpdatedUserInfo();
              this.OnLoaded.emit();
              this.pp360SpinnerService.showSpinner(false);
            }, 5000);
          },
          (err: any) => {
            console.log('err', err);
            this.OnLoaded.emit();
            this.pp360SpinnerService.showSpinner(false);
          }
        );
      }
    }
  }
  changeTheme(themeC: string, variationC: string) {
    for (let i of this.themes) {
      for (let j of i.SubThemes) {
        if (themeC == j.Code && variationC == j.VariationCode) {
          document.documentElement.style.setProperty('--white', '#' + j.RGB1);
          document.documentElement.style.setProperty(
            '--feed-background',
            '#' + j.RGB2
          );
          document.documentElement.style.setProperty(
            '--login-textfield-color',
            '#' + j.RGB3
          );
          document.documentElement.style.setProperty(
            '--login-textfield-border',
            '#' + j.RGB4
          );
          document.documentElement.style.setProperty(
            '--login-text-color',
            '#' + j.RGB5
          );
          document.documentElement.style.setProperty(
            '--login-button',
            '#' + j.RGB6
          );
          document.documentElement.style.setProperty(
            '--login-text-error',
            '#' + j.RGB7
          );
          document.documentElement.style.setProperty(
            '--login-button-hover',
            '#' + j.RGB8
          );
          document.documentElement.style.setProperty(
            '--okr-green',
            '#' + j.RGB9
          );
        }
      }
    }
  }
  changeProperty(currentItem, newItem, property) {
    if (currentItem[property] != newItem[property]) {
      currentItem[property] = newItem[property];
      this.somethingChanged = true;
      this.properties.push(property);
    }
  }
  getUpdatedUserInfo() {
    this.restServ.get(`Account/UserInfo`).subscribe(this.getUserInfoObserver);
  }
  getUserInfoObserver = {
    next: (userInfo: any) => {
      var currentUserInfo = this.CurrentUser;
      this.somethingChanged = false;
      this.properties = [];
      this.changeProperty(currentUserInfo, userInfo, 'Theme');
      if (this.somethingChanged) {
        this.coreService.setUserInfo(currentUserInfo);
        this.coreService.changedProperty(this.properties);
      }
    },
  };
  onSetThemeChange() {
    this.isSetTheme != this.isSetTheme;
  }
  onCancel() {
    this.getThemes();
  }
  goBack() {
    this.router.navigate(['/TileMenu/menu/company-admin']);
  }

  getCompanyTheme() {
    this.coreService.getCompanyTheme().subscribe((data: any) => {
      this.isSetTheme = data.EmployeesCanCustomizeTheme;
      if (!data.ThemeCode || data.ThemeCode === null) {
        data.ThemeCode = 'RB';
      }
      if (!data.ThemeVariationCode || data.ThemeVariationCode === null) {
        data.ThemeVariationCode = 'L';
      }
      this.adminThemeSet = data;
      for (let i of this.themes) {
        if (data.ThemeCode === i.Code) {
          i.selected = true;
        } else {
          i.selected = false;
        }
        if (i.Code == this.themeCode) {
          this.variationCodeNo = i.SubThemes[0].VariationCode;
          if (data.image == 'light') {
            this.variationCodeNo = 'L';
          }
          if (data.image == 'flip') {
            this.variationCodeNo = 'F';
          }
          if (data.image == 'dark') {
            this.variationCodeNo = 'D';
          }
        }
      }
      this.isSetTheme = data.EmployeesCanCustomizeTheme;
      this.themeCode = data.ThemeCode;
      this.variationCodeNo = data.ThemeVariationCode;
      this.changeTheme(this.themeCode, this.variationCodeNo);
    });
  }
}
