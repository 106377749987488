import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'pp360-checkbox',
  templateUrl: './pp360-checkbox.component.html',
  styleUrls: ['./pp360-checkbox.component.scss'],
})
export class Pp360CheckboxComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() disabled: boolean;

  @Output() onStateChange = new EventEmitter<boolean>();

  // NgModel
  @Input() model: boolean;
  @Input() isPerformance: boolean;
  @Output() modelChange = new EventEmitter<any>();

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {}

  click() {
    if (!this.disabled) {
      this.model = !this.model;
      this.onStateChange.emit(this.model);
      this.modelChange.emit(this.model);
      this.ref.detectChanges();
    }
  }
}
