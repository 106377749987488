import {
  Component,
  OnInit,
  AfterContentChecked,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { NewPatComponent } from 'app/shared/components/new-pat/new-pat.component';
import { NewEvaluationComponent } from 'app/shared/components/new-evaluation/new-evaluation.component';
import { EvaluationComponent } from 'app/shared/components/evaluation/evaluation.component';
import { GiveTokenComponent } from '../../../shared/components/give-token/give-token.component';
import { Router } from '@angular/router';
import { SharedService } from 'app/shared/services/shared.service';
import { AppConfigService } from 'app/app-config.service';
import { ChoicePopUpComponent } from 'app/shared/components/choice-pop-up/choice-pop-up.component';

@Component({
  selector: 'profile-tooltip',
  templateUrl: './profile-tooltip.component.html',
  styleUrls: ['./profile-tooltip.component.scss'],
})
export class ProfileTooltip implements OnInit {
  isAfterContentChecked: boolean = false;
  team: any;
  userInfo: any;
  @Input() empInfo: any;

  patFeedData: any;
  patFeedPost: any;
  tokenFeedData = [];
  evalFeed = [];
  tokenFeedPost: any;
  profileName: any;
  @Output() addNewPat = new EventEmitter();
  @Output() addNewToken = new EventEmitter();
  @Output() postEval = new EventEmitter();
  fname: string;
  mname: string;
  lname: string = '';

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public coreService: CoreService,
    private route: Router,
    private sharedService: SharedService,
    private appConfigService: AppConfigService
  ) {}

  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();
    // console.log(this.empInfo);
    // console.log(this.userInfo);

    if (this.empInfo?.Name.split(' ')[0].length > 8) {
      this.fname = this.empInfo.Name.split(' ')[0][0] + '.';
    } else {
      this.fname = this.empInfo?.Name.split(' ')[0];
    }
    if (this.empInfo?.Name.split(' ')[1].length > 8) {
      this.mname = this.empInfo?.Name.split(' ')[1][0] + '.';
    } else {
      this.mname = this.empInfo?.Name.split(' ')[1];
    }
    if (this.empInfo?.Name.split(' ')[2]) {
      if (this.empInfo?.Name.split(' ')[2].length > 8) {
        this.lname = this.empInfo?.Name.split(' ')[2][0] + '.';
      } else {
        this.lname = this.empInfo?.Name.split(' ')[2];
      }
    }
    this.profileName = this.fname + ' ' + this.mname + ' ' + this.lname;
  }

  ngAfterContentChecked() {
    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      this.team = this.bsModalRef['content'].team || [];
      if (!this.team.Type) {
        this.team.Type = 'Employee';
        this.team.Name = this.team.SecondName + ' ' + this.team.FirstName;
        this.team.Description = this.team.JobTitle;
      }
      this.isAfterContentChecked = true;
    }
  }

  goToProfile() {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: this.empInfo?.Id },
      replaceUrl: true,
    });
    this.bsModalRef.hide();
  }

  getNewPat() {
    this.bsModalRef.hide();
    let teamArr = [];
    const empInfo = JSON.parse(JSON.stringify(this.empInfo));
    empInfo['isPreselect'] = true;
    empInfo.isPreselect = true;
    empInfo.Type = 'Employee';
    empInfo.Id = 'e:' + empInfo.Id;
    teamArr.push(empInfo);
    this.bsModalRef = this.modalService.show(NewPatComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.all_Employees_Teams = teamArr;

    this.bsModalRef.content.preselectEmployee(teamArr);
    this.bsModalRef.content.closeBtnName = 'Close';

    (<NewPatComponent>this.bsModalRef.content).refreshWallRequired.subscribe(
      (newPatData: boolean) => {
        this.appConfigService.refreshWall$.next(true); //TODO ???????????
        (<NewPatComponent>(
          this.bsModalRef.content
        )).refreshWallRequired.unsubscribe();
      }
    );
  }

  getNewEvaluation() {
    this.bsModalRef.hide();
    const empInfo = JSON.parse(JSON.stringify(this.empInfo));
    let emp = [];
    empInfo['isPreselect'] = true;
    empInfo['Type'] = 'Employee';
    emp.push(empInfo);
    this.bsModalRef = this.modalService.show(ChoicePopUpComponent, {
      class: 'choicePopUp ammazza wallPopup',
      ignoreBackdropClick: false,
      initialState: {
        evaluation_Employees: emp,
        isPreSelected: true,
      },
    });
    this.bsModalRef.content.postEval.subscribe((data: any) => {
      this.evalFeed.unshift(data);
      this.postEval.emit(data);

      this.bsModalRef.hide();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  getTokenPopUp() {
    if (this.userInfo?.availableTokens === 0) {
      return;
    }
    this.bsModalRef.hide();
    let token = [];
    const empInfo = JSON.parse(JSON.stringify(this.empInfo));
    empInfo['isPreselect'] = true;
    empInfo.Type = 'Employee';
    token.push(empInfo);
    this.bsModalRef = this.modalService.show(GiveTokenComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true,
      initialState: {
        disableEmployeeDropDown: true,
      },
    });
    this.bsModalRef.content.employeesList = token;

    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
