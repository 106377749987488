import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-objectives-m',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.css', '../../common-styles.scss'],
})
export class ObjectivesMComponent implements OnInit {
  @Input() plan: any;
  @Input() type: string;
  objList: any;
  text: any;

  showDetail: any[] = [];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    switch (this.type) {
      case 'gateways':
        this.objList = this.plan.Gateways;
        this.text = this.translateService.getTranslation(
          'PARTECIPANT_LTI.OBJECTIVES.GATEWAYS_HINT'
        );
        break;
      case 'objectives':
        this.objList = this.plan.Objectives;
        this.text = this.translateService.getTranslation(
          'PARTECIPANT_LTI.OBJECTIVES.OBJECTIVES_HINT'
        );
        break;
      case 'correctives':
        this.objList = this.plan.Correctives;
        this.text = this.translateService.getTranslation(
          'PARTECIPANT_LTI.OBJECTIVES.CORRECTIVE_HINT'
        );
        break;
    }
  }

  getYears() {
    return new Array(this.plan.YearEnd - this.plan.YearStart + 1)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  /*************************************************************** */
  getObjectiveValue(obj: any, year: number) {
    let n = 0;
    let y = obj.YearlyValues.find((x) => x.Year == year);
    if (y) n = y.TargetValue;

    return n;
  }
}
