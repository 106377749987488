import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { RestfulService } from './restful.service';
import { Router } from '@angular/router';
import { CoreService } from './core.service';

@Injectable()
export class AVInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  restfulService: RestfulService;
  coreService: CoreService;

  constructor(
    private injector: Injector,
    private router: Router
  ) {}

  private setHeaderToRequest(request: HttpRequest<any>): HttpRequest<any> {
    var action = request.headers.get('Action');
    var noAuth = request.headers.get('NoAuth');

    const token: string = <string>localStorage.getItem('auth-token');
    const msalToken: string = <string>localStorage.getItem('msal-token');

    if (token && !noAuth) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }
    if (!action) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    } else {
      if (action === 'download') {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/binary'),
        });
      }
      request = request.clone({ headers: request.headers.delete('Action') });
    }

    if (noAuth) {
      request = request.clone({ headers: request.headers.delete('NoAuth') });
    }

    if (msalToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${msalToken}`),
      });
    }

    return request;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.setHeaderToRequest(request)).pipe(
      catchError((err: any) => {
        // console.log("INTERCEPTOR:",err);
        if (navigator.onLine)
          if (err instanceof HttpErrorResponse) {
            var noError =
              this.router.url.includes('/firstlogin') ||
              this.router.url.includes('/login') ||
              request.url.includes('UserInfo');
            if (noError) {
              return throwError(JSON.stringify({ status: -1 }));
            } else {
              if (err.status === 401) {
                // Refresh Token
                return this.handle401Error(request, next);
              }
              if (err.status === 417) {
                this.handle417Error(request, next);
              } else if (err.status === 0) {
                // this.router.navigate(['login']);
                return;
              } else {
                return throwError(JSON.stringify(err));
              }
            }
            return throwError(JSON.stringify(err));
          }
      })
    );
  }

  private handle417Error(request: HttpRequest<any>, next: HttpHandler) {
    this.coreService = this.injector.get(CoreService);
    let text = this.coreService.getTranslation(
      'ERRORS.CANNOT_DELETE_USED_ENTITY'
    );
    this.coreService.toasterMessage('error', 'PatPat360', text);
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.indexOf('GetToken') > 0) {
      localStorage.clear();
      this.router.navigate(['login']);
      return;
    }
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      const refreshModel: any = {
        refreshToken: <string>localStorage.getItem('refresh_token'),
        userName: <string>localStorage.getItem('userName'),
      };

      if (refreshModel.refreshToken && refreshModel.userName) {
        this.restfulService = this.injector.get(RestfulService);
        this.coreService = this.injector.get(CoreService);

        this.restfulService
          .post(`Account/GetToken`, refreshModel)
          .toPromise()
          .then(async (response: any) => {
            await this.coreService.SetAuthToken(response);
            this.router.navigate(['wall']);
            //return next.handle(this.setHeaderToRequest(request));
          });
      } else {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    } else {
      this.isRefreshingToken = false;
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.setHeaderToRequest(request));
        })
      );
    }
  }
}
