import { Injectable } from '@angular/core';
import { RestfulService } from '../../shared/services/restful.service';
import { CoreService } from '../../shared/services/core.service';

@Injectable()
export class WallService {
  profiles = [];
  isEventOpen = false;
  isFeedbackOpen = false;

  constructor(
    private restfulWebService: RestfulService,
    private coreService: CoreService
  ) {}

  getSkills(reqBody) {
    return this.restfulWebService.post(`PatFeed/PatSkills`, reqBody);
  }

  getPagedPatfeedForWall(ID, page, size) {
    return this.restfulWebService.get(
      `PatFeed?employeeId=${ID}&pageStart=${page}&pagesize=${size}`
    );
  }
  viewFeedback(ID) {
    return this.restfulWebService.put(
      `EvaluationFeed/View?groupid=${ID}`,
      null
    );
  }

  postEvaluatorFeedback(reqBody) {
    return this.restfulWebService.post(`PatFeed/EvaluateFeedback`, reqBody);
  }

  deletePat(itemId) {
    return this.restfulWebService.delete(`PatFeed/Pat?patId=${itemId}`);
  }

  deleteNews(itemId) {
    return this.restfulWebService.delete(`PatFeed/News?newsId=${itemId}`);
  }

  deleteComment(itemId, entity: string = 'Pat') {
    return this.restfulWebService.delete(
      `${entity}Feed/Comment?commentId=${itemId}`
    );
  }

  postInappropriateFeedback(reqBody) {
    return this.restfulWebService.post(`PatFeed/ReportInappropiate`, reqBody);
  }

  postALike(reqBody, entity: string = 'Pat') {
    return this.restfulWebService.post(`${entity}Feed/Like`, reqBody);
  }

  deleteALike(tapFeedId, authorId, entity: string = 'Pat') {
    let p1 = 'tapFeedId';
    if (entity === 'News') {
      p1 = 'newsFeedId';
    }
    return this.restfulWebService.delete(
      `${entity}Feed/Like?${p1}=${tapFeedId}&authorId=${authorId}`
    );
  }

  postAPart(reqBody, entity: string = 'Pat') {
    return this.restfulWebService.post(`${entity}Feed/Partecipate`, reqBody);
  }

  deleteAPart(tapFeedId, authorId, entity: string = 'Pat') {
    let p1 = 'eventId';
    if (entity === 'News') {
      p1 = 'eventId';
    }
    return this.restfulWebService.delete(
      `${entity}Feed/Partecipate?${p1}=${tapFeedId}&authorId=${authorId}`
    );
  }

  postARepat(reqBody) {
    return this.restfulWebService.post(`PatFeed/Repat`, reqBody);
  }

  deleteARepat(tapFeedId) {
    return this.restfulWebService.delete(
      `PatFeed/Repat?tapFeedId=${tapFeedId}`
    );
  }

  onAddImage(image) {
    return this.restfulWebService.fileUpload(`PatFeed/Photo`, image);
  }

  commentOnWall(comment: any, entity: string = 'Pat') {
    return this.restfulWebService.post(`${entity}Feed/Comment`, comment);
  }

  evaluationFeed(Id) {
    return this.restfulWebService.get(`EvaluationFeed?employeeId=${Id}`);
  }

  evaluationSkills(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `EvaluationFeed/Skills?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }

  postEvaluation(postEval) {
    return this.restfulWebService.post(`EvaluationFeed`, postEval);
  }

  tokenFeed(employeeId) {
    return this.restfulWebService.get(`TokenFeed?employeeId=${employeeId}`);
  }

  getTokenSkills(currentEmployeeId, employeeId) {
    return this.restfulWebService.get(
      `TokenFeed/Skills?currentEmployeeId=${currentEmployeeId}&employeeId=${employeeId}`
    );
  }

  postAToken(reqBody) {
    return this.restfulWebService.post(`TokenFeed`, reqBody);
  }

  getAskskills(reqBody) {
    return this.restfulWebService.post(`AskFeed/AskSkills`, reqBody);
  }

  askEval(newAsk) {
    return this.restfulWebService.post(`AskFeed`, newAsk);
  }

  getGuruSkills(Id) {
    return this.restfulWebService.get(`Guru/Skills?employeeId=${Id}`);
  }

  getGuru(employeeId, skillId, PageStart, PageSize) {
    return this.restfulWebService.get(
      `Guru/Data?employeeId=${employeeId}&skillId=${skillId}&pageStart=${PageStart}&pageSize=${PageSize}`
    );
  }

  postAboutWall(employeeId, reqBody) {
    return this.restfulWebService.post(
      `PatFeed/Wall?employeeId=${employeeId}`,
      reqBody
    );
  }

  getPagedPatfeedForAboutWall(ID, page, size, reqBody) {
    return this.restfulWebService.post(
      `PatFeed/Wall?employeeId=${ID}&pageStart=${page}&pagesize=${size}`,
      reqBody
    );
  }

  getFilter() {
    return this.restfulWebService.get(`PatFeed/FilterItems`);
  }

  getEmployeeInfo(employeeId) {
    return this.restfulWebService.get(
      `EmployeeInfo/Counters?employeeId=${employeeId}`
    );
  }

  deleteToken(itemId) {
    return this.restfulWebService.delete(`TokenFeed/Token?tokenId=${itemId}`);
  }

  deleteFeedback(itemId) {
    return this.restfulWebService.delete(`EvaluationFeed?id=${itemId}`);
  }

  uploadCommentImage(image) {
    return this.restfulWebService.fileUpload(`PatFeed/CommentImage`, image);
  }

  updateComment(commentId, reqBody) {
    return this.restfulWebService.put(`CommentExt/${commentId}`, reqBody);
  }

  getSinglePat(employeeId, patId) {
    return this.restfulWebService.post(
      `PatFeed/Wall?employeeId=${employeeId}&pageStart=1&pagesize=30`,
      { PostId: patId }
    );
  }

  getPlacePredictions(text, sessionToken) {
    let url = `input=${text}`;
    if (sessionToken) {
      url += `&sessionToken=${sessionToken}`;
    }
    return this.restfulWebService.get(`Resource/PlacePrediction?${url}`);
  }
  getPlacePredictionDetails(placeId, sessionToken) {
    let url = `placeId=${placeId}`;
    if (sessionToken) {
      url += `&sessionToken=${sessionToken}`;
    }
    return this.restfulWebService.get(`Resource/PlaceDetails?${url}`);
  }
}
