import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CoreService } from '../services/core.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private coreService: CoreService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let role = this.coreService.getUserRole();
    let localUser = this.coreService.getUserInfo();
    let localToken = this.coreService.getUserToken();
    if (localUser && localToken && role.indexOf('SuperAdmin') >= 0) return true;
    else {
      this.coreService.toasterMessage(
        'error',
        'guard',
        this.coreService.getTranslation('Messages.NoPermission')
      );
      this.router.navigate(['/login']);
      return false;
    }
  }
}
