import { Component } from '@angular/core';
import { navigationService } from 'app/shared/services/navigation.service';
import { ActivatedRoute } from '@angular/router';

declare var jQuery: any;

@Component({
  selector: 'oauth',
  templateUrl: 'layouts/basicLayout.template.html',
})
export class OAuthComponent {
  public hideToolbar: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private navigationService: navigationService
  ) {
    this.route.fragment.subscribe((fragment: string) => {
      console.log('My hash fragment is here => ', fragment);
    });

    this.navigationService.hideToolbarObsv$.subscribe((hideToolbar) => {
      this.hideToolbar = hideToolbar;
    });
  }

  oldStatus: boolean;
  checkHideToolbar() {
    if (this.hideToolbar !== this.oldStatus) {
      this.oldStatus = this.hideToolbar;
    }

    console.log('OAuthComponent, checkHideToolbar', !this.hideToolbar);
    return !this.hideToolbar;
  }
}
