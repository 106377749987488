import {
  Component,
  OnInit,
  AfterContentChecked,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { NewPatComponent } from 'app/shared/components/new-pat/new-pat.component';
import { NewEvaluationComponent } from 'app/shared/components/new-evaluation/new-evaluation.component';
import { EvaluationComponent } from 'app/shared/components/evaluation/evaluation.component';
import { GiveTokenComponent } from '../give-token/give-token.component';
import { Router } from '@angular/router';
import { SharedService } from 'app/shared/services/shared.service';
import { AppConfigService } from 'app/app-config.service';

@Component({
  selector: 'app-employee-team-pop-up',
  templateUrl: './employee-team-pop-up.component.html',
  styleUrls: ['./employee-team-pop-up.component.scss'],
})
export class EmployeeTeamPopUpComponent implements OnInit {
  isAfterContentChecked: boolean = false;
  team: any;
  userInfo: any;

  patFeedData: any;
  patFeedPost: any;
  tokenFeedData = [];
  evalFeed = [];
  hoverImage: boolean = false;
  tokenFeedPost: any;
  @Output() addNewPat = new EventEmitter();
  @Output() addNewToken = new EventEmitter();
  @Output() postEval = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public coreService: CoreService,
    private route: Router,
    private sharedService: SharedService,
    private appConfigService: AppConfigService
  ) {}

  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();
  }

  ngAfterContentChecked() {
    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      this.team = this.bsModalRef['content'].team || [];
      if (!this.team.Type) {
        this.team.Type = 'Employee';
        this.team.Name = this.team.SecondName + ' ' + this.team.FirstName;
        this.team.Description = this.team.JobTitle;
      }
      this.isAfterContentChecked = true;

      console.log(this.team);
      console.log(this.userInfo);
    }
  }

  goToProfile() {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: this.team.Id },
      replaceUrl: true,
    });
    console.log(this.team.Id);

    this.bsModalRef.hide();
  }

  getNewPat() {
    this.bsModalRef.hide();
    let teamArr = [];
    teamArr.push(this.team);
    this.bsModalRef = this.modalService.show(NewPatComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.all_Employees_Teams = teamArr;

    this.bsModalRef.content.closeBtnName = 'Close';

    (<NewPatComponent>this.bsModalRef.content).refreshWallRequired.subscribe(
      (newPatData: boolean) => {
        this.appConfigService.refreshWall$.next(true); //TODO ???????????
        (<NewPatComponent>(
          this.bsModalRef.content
        )).refreshWallRequired.unsubscribe();
      }
    );
  }

  getNewEvaluation() {
    this.bsModalRef.hide();
    let emp = [];
    emp.push(this.team);
    this.bsModalRef = this.modalService.show(EvaluationComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true,
    });
    //this.bsModalRef = this.modalService.show(EvaluationComponent, {class: 'modal-lg EvaluationPopUp',ignoreBackdropClick: true});
    this.bsModalRef.content.type = 'get';
    this.bsModalRef.content.evaluation_Employees = emp;
    //this.bsModalRef.content.employees = emp;
    this.bsModalRef.content.postEval.subscribe((data: any) => {
      this.evalFeed.unshift(data);
      this.postEval.emit(data);
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  getTokenPopUp() {
    this.bsModalRef.hide();
    let token = [];
    token.push(this.team);
    //this.bsModalRef = this.modalService.show(GiveTokenComponent,{class: 'modal-md giveTokenPopup EvaluationPopUp',ignoreBackdropClick: true});
    this.bsModalRef = this.modalService.show(GiveTokenComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.employeesList = token;

    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
