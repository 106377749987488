import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css', '../../common-styles.scss'],
})
export class GlossaryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
