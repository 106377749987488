import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss', '../../common-styles.scss'],
})
export class MobileMenuComponent implements OnInit {
  @Output() onViewChange = new EventEmitter();
  @Output() acceptLTI = new EventEmitter();
  @Input() currentView: string;
  @Input() currentPlan: any;

  constructor(private coreService: CoreService) {}

  ngOnInit() {}

  action(currentView: string, prevView: string, headCaption: string) {
    this.currentView = currentView;
    this.onViewChange.emit({
      currentView: currentView,
      prevView: prevView,
      headCaption: this.coreService.getTranslation(headCaption),
    });
  }

  accept() {
    this.acceptLTI.emit(true);
  }
}
