import {
  Component,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'jquery-slimscroll';
import { navigationService } from 'app/shared/services/navigation.service';
declare var jQuery: any;
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { smoothlyMenu } from '../../../app.helpers';
import { environment } from '../../../../environments/environment';
import { ThemeService } from 'app/shared/theme/theme.service';

import { DeviceDetectorService } from 'ngx-device-detector';

import { PasswordChangeComponent } from 'app/shared/components/password-change/password-change.component';
import { AdminProfileChangeComponent } from 'app/shared/components/admin-profile-change/admin-profile-change.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html',
  styleUrls: ['./navigation.template.scss'],
})
export class NavigationComponent implements OnDestroy, OnInit {
  public userInfo: any;
  public isEvaluator: boolean;
  version: string = environment.name + ' ' + environment.version;
  oldmenuState = {};
  isOpen = false;
  bsModalRef: BsModalRef;
  public navBar = [];
  public navBarManager = [];
  public navBarCompanyAdmin = [];
  public navBarLocalAdmin = [];
  public navBarSuperAdmin = [];

  ready: boolean = false;
  userRole: string;
  menuSub: Subscription;

  nav = {
    activeRoute: true,
    iClass: 'fa fa-book sideIcon',
    refName: 'feed',
    routerLink: '/User/wall/feed',
    title: 'Menu.TheWall',
  };

  constructor(
    private ref: ChangeDetectorRef,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    public navService: navigationService,
    private deviceService: DeviceDetectorService,
    public coreService: CoreService,
    private themeSrv: ThemeService
  ) {
    this.readUserInfo();
    this.menuSub = this.navService.getUpdateSideMenu().subscribe((res) => {
      if (res) {
        this.updateSideMenu();
      }
    });
  }

  readUserInfo() {
    this.userInfo = this.coreService.getUserInfo();
    if (this.userInfo) {
      this.userRole = this.userInfo.PreferredRole;

      if (this.userInfo.belongingCompanyStyle) {
        this.themeSrv.setExternalTheme(this.userInfo.belongingCompanyStyle);
      } else {
        this.themeSrv.setDefaultTheme();
      }
      this.updateSideMenu();
    }
  }

  toggleNavigation(closeState) {
    this.userInfo = this.coreService.getUserInfo();
    if (!this.isMobile) {
      if ((!this.isOpen && closeState) || (this.isOpen && !closeState)) {
        jQuery('body').toggleClass('mini-navbar');
        jQuery('#myNavbar').toggleClass('moveRight');
        smoothlyMenu();
        this.isOpen = closeState;
        if (this.isOpen) {
          if (Object.keys(this.oldmenuState).length) {
            this.navService.menuOpenMap = JSON.parse(
              JSON.stringify(this.oldmenuState)
            );
          }
        } else {
          this.oldmenuState = JSON.parse(
            JSON.stringify(this.navService.menuOpenMap)
          );
          this.navService.CloseAllMenu();
        }
      }
    }
  }

  ngOnInit(): void {
    if (this.userRole === 'Manager') {
      this.nav = {
        activeRoute: true,
        title: 'Menu.MyDashboard',
        iClass: 'fa fa-th-large sideIcon',
        refName: 'Dashboard/manager',
        routerLink: '/Manager/dashboard',
      };
    }
  }

  ngOnDestroy(): void {
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
  }

  updateSideMenu() {
    this.ready = false;
    this.userInfo = this.coreService.getUserInfo();
    this.userRole = this.userInfo.PreferredRole;
    // console.log(this.userRole);
    this.isEvaluator = this.userInfo.isEvaluator === true;
    if (this.userInfo && this.userInfo.roles) {
      setTimeout(() => {
        this.manageMenu(
          this.navBar,
          this.navService.getNavigationItems(this.userInfo) || []
        );
        this.manageMenu(
          this.navBarManager,
          this.navService.getManagerNavigationItems(this.userInfo) || []
        );
        this.manageMenu(
          this.navBarCompanyAdmin,
          this.navService.getCompanyAdminNavigationItems(this.userInfo) || []
        );
        this.manageMenu(
          this.navBarLocalAdmin,
          this.navService.getLocalAdminNavigationItems(this.userInfo) || []
        );
        this.manageMenu(
          this.navBarSuperAdmin,
          this.navService.getSuperAdminNavigationItems(this.userInfo) || []
        );
      }, 1500);
      setTimeout(() => {
        this.ready = true;
      }, 1000);
      // console.log(this.navBar);
    }
  }

  onVersionClick() {
    if (environment.name === 'TEST') this.themeSrv.switchTheme();
  }

  manageMenu(currentMenus, newMenus) {
    var x = 1;
    newMenus.forEach((element: any) => {
      //Adds menus not existing
      if (!currentMenus.some((e: any) => e.refName === element.refName)) {
        element.activeRoute = this.activeRoute(element.refName);
        currentMenus.push(element);
      }
    });

    currentMenus.forEach((element, index) => {
      //Remove menus not existing
      if (!newMenus.some((e: any) => e.refName === element.refName)) {
        if (element.subChild) {
          element.subChild.forEach((subNav) => {
            subNav.activeRoute = this.activeRoute(subNav.refName);
          });
        }
        element.activeRoute = this.activeRoute(element.refName);
        currentMenus.splice(index, 1);
      }
    });
  }

  /*
      Yes it is possible, we can do that in couple of ways.
      1 . After click on 'companies' from side nav bar menu  it goes to list and get list from API ,
       and after that it checks if its having only one element then it redirect to its details page.

      2 . In first step we have to go to List page first, but we can do that in another way by calling list API before setting our side nav bar menu.
          but by doing that our side nav bar menu will take more time to load because we have to check from that no of Company from API.


      */

  isMobile: boolean;
  ngAfterViewInit() {
    // jQuery('#side-menu').metisMenu();

    if (jQuery('body').hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%',
        railOpacity: 0.9,
      });
    }

    this.isMobile = this.deviceService.isMobile();
  }

  onMenuClick(nav) {
    if (nav.refChildName) {
      this.navService.ToggleMenuStatus(nav.refChildName);
      // } else if (!nav.isChild){
    } else {
      if (!this.isMobile) {
        this.oldmenuState = JSON.parse(
          JSON.stringify(this.navService.menuOpenMap)
        );
        this.navService.CloseAllMenu();
        jQuery('body').toggleClass('mini-navbar');
        jQuery('#myNavbar').toggleClass('moveRight');
        smoothlyMenu();
        this.handleClick(nav);
        this.isOpen = false;
      }
    }

    if (
      this.deviceService.isMobile() &&
      (!nav.subChild || nav.subChild.length == 0)
    ) {
      smoothlyMenu();
    }
  }

  handleClick(nav) {
    let x = this.navBar;
    if (this.navBar) {
      this.navBar.forEach((n) => {
        if (n.refChildName && n.subChild.length) {
          const fIdx = n.subChild.findIndex((c) => c.refName === nav.refName);
          if (fIdx !== -1) {
            n.isSelected = true;
          } else {
            n.isSelected = false;
          }
          n.subChild.forEach((ns) => {
            if (ns.refName === nav.refName) {
              ns.isSelected = true;
            } else {
              ns.isSelected = false;
            }
          });
        }
      });
    }
    if (this.navBarManager) {
      this.navBarManager.forEach((n) => {
        if (n.refChildName && n.subChild.length) {
          const fIdx = n.subChild.findIndex((c) => c.refName === nav.refName);
          if (fIdx !== -1) {
            n.isSelected = true;
          } else {
            n.isSelected = false;
          }
          n.subChild.forEach((ns) => {
            if (ns.refName === nav.refName) {
              ns.isSelected = true;
            } else {
              ns.isSelected = false;
            }
          });
        }
      });
    }
    if (this.navBarLocalAdmin) {
      this.navBarLocalAdmin.forEach((n) => {
        if (n.refChildName && n.subChild.length) {
          const fIdx = n.subChild.findIndex((c) => c.refName === nav.refName);
          if (fIdx !== -1) {
            n.isSelected = true;
          } else {
            n.isSelected = false;
          }
          n.subChild.forEach((ns) => {
            if (ns.refName === nav.refName) {
              ns.isSelected = true;
            } else {
              ns.isSelected = false;
            }
          });
        }
      });
    }
    if (this.navBarCompanyAdmin) {
      this.navBarCompanyAdmin.forEach((n) => {
        if (n.refChildName && n.subChild.length) {
          const fIdx = n.subChild.findIndex((c) => c.refName === nav.refName);
          if (fIdx !== -1) {
            n.isSelected = true;
          } else {
            n.isSelected = false;
          }
          n.subChild.forEach((ns) => {
            if (ns.refName === nav.refName) {
              ns.isSelected = true;
            } else {
              ns.isSelected = false;
            }
          });
        }
      });
    }
    if (this.navBarSuperAdmin) {
      this.navBarSuperAdmin.forEach((n) => {
        if (n.refChildName && n.subChild.length) {
          const fIdx = n.subChild.findIndex((c) => c.refName === nav.refName);
          if (fIdx !== -1) {
            n.isSelected = true;
          } else {
            n.isSelected = false;
          }
          n.subChild.forEach((ns) => {
            if (ns.refName === nav.refName) {
              ns.isSelected = true;
            } else {
              ns.isSelected = false;
            }
          });
        }
      });
    }
  }

  @HostListener('window:beforeunload', ['$event']) windowBeforeUnload(
    event: BeforeUnloadEvent
  ) {
    const checkValue = localStorage.getItem('keepLoggedIn');
    const scheckValue = sessionStorage.getItem('keepLoggedIn');
    if ((checkValue == 'false' || !checkValue) && scheckValue) {
      this.onLogout();
    }
  }
  onLogout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }

  onChangePassword() {
    this.bsModalRef = this.modalService.show(PasswordChangeComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onProfileView() {
    this.bsModalRef = this.modalService.show(AdminProfileChangeComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.userInfo = this.userInfo;
  }

  onImageClick(image) {
    this.coreService.onImagePreview(image);
  }

  activeRoute(routename: string): boolean {
    // if (this.router.url.indexOf("companies/details") > -1) {
    //   return (routename == "acompanies")
    // }

    return this.router.url.endsWith('/' + routename);
  }

  activeChildRoute(routename: string): boolean {
    return this.navService.GetMenuStatus(routename);
  }

  isLinkActive(nav): boolean {
    let paramsId;
    this.route.queryParams.subscribe((params: any) => {
      paramsId = params.idVisitor;
    });
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    if (
      nav.refName === 'feed' &&
      this.router.url.indexOf('story?idVisitor=') !== -1 &&
      this.userInfo.linkedEmployeeId !== paramsId
    ) {
      return true;
    } else if (
      nav.refName === 'story' &&
      this.router.url.indexOf('story?idVisitor=') !== -1 &&
      this.userInfo.linkedEmployeeId !== paramsId
    ) {
      return false;
    } else {
      return baseUrl === nav.routerLink;
    }
  }
  setLogo() {
    let a = '';
    if (
      this?.userInfo?.Theme?.Code &&
      (this?.userInfo?.belongingCompanyName === 'Demo Company' ||
        this?.userInfo?.belongingCompanyName === 'Test Company' ||
        this?.userInfo?.belongingCompanyName === 'Active Value Advisors')
    ) {
      a = './assets/images/logos/' + this.userInfo.Theme.Code + '_L_logo.png ';
    } else {
      a = this.coreService.getImageURL(
        this.userInfo.belongingCompanyLogo,
        this.coreService.getDefaultImage()
      );
    }
    return a;
  }
}
