import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { PraiseService } from 'app/personal-growth/praise/praise.service';
import { CoreService } from 'app/shared/services/core.service';
import { Router } from '@angular/router';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { concatMap, map, switchMap, toArray } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
  selector: 'app-table-skills-by-skill',
  templateUrl: './table-skills-by-skill.component.html',
  styleUrls: [
    './table-skills-by-skill.component.scss',
    '../tables.scss',
    '../../../../common_styles.scss',
  ],
})
export class TableSkillsBySkillComponent implements OnInit {
  @Input() dateRange: string;
  @Input() viewMode: string;
  @Input() mode: string;
  @Input() focusOnSkill: string;
  @Input() Mobile: string;

  @Output() OnLoading = new EventEmitter();
  @Output() openComp = new EventEmitter();
  @Output() OnLoaded = new EventEmitter();

  @Input() praiseArr: any;
  @Input() radioType: any;

  filteredItems: any;
  items: any;
  currentFilter: any;
  userinfo: any;
  openPop: boolean;
  profileName: string;
  fname: string;
  mname: string;
  lname: string;
  flexLang: boolean;

  constructor(
    private praiseService: PraiseService,
    private ref: ChangeDetectorRef,
    public coreService: CoreService,
    public route: Router,
    private pp360SpinnerService: Pp360SpinnerService
  ) {}

  ngOnInit() {
    this.userinfo = this.coreService.getUserInfo();
    if (
      this.userinfo.language == 'it' ||
      this.userinfo.language == 'fr' ||
      this.userinfo.language == 'es' ||
      this.userinfo.language == 'nl' ||
      this.userinfo.language == 'pl'
    ) {
      this.flexLang = true;
    }
  }
  ngAfterViewInit() {
    this.reload(this.viewMode, this.dateRange);
  }

  openComponent(data) {
    this.openComp.next([data, 'skillSkill']);
  }

  isExpands(itemId: any) {
    for (let i of this.praiseArr) {
      if (itemId === i.id) {
        i['isExpanded'] = !i['isExpanded'];
      } else {
        i['isExpanded'] = false;
      }
    }
  }

  updateCurrentFilter() {
    this.currentFilter = {
      dateRange: this.dateRange,
      viewMode: this.viewMode, // Given/Received
      type: this.radioType,
    };
  }

  reload(data, dateRange) {
    this.updateCurrentFilter();
    this.OnLoading.emit();
    this.pp360SpinnerService.showSpinner(true);
    this.currentFilter.viewMode = data;
    this.currentFilter.dateRange = dateRange;

    this.praiseService
      .getItemsByBehaviorSkills(this.currentFilter)
      .pipe(
        switchMap((items: any) => from(items)),
        // then concatenate the calls to the second endpoint with concatMap
        concatMap((item: any) =>
          this.praiseService
            .getItemsPersonsByBehaviorSkills(item.Id, null, this.currentFilter)
            .pipe(
              // return the original item with its details
              map((itemDetail) => {
                item['details'] = itemDetail;
                return item;
              })
            )
        ),
        // finally gather all items into an array
        toArray()
      )
      .subscribe((items: any) => {
        // console.log(items);
        this.OnLoaded.emit();
        this.items = items;
        this.filteredItems = JSON.parse(JSON.stringify(this.items));

        for (let item of this.filteredItems) {
          item['EmpLength'] = item?.details?.length;
          // item['sortingValue']=(item?.Pats?.Tot || 0) + item?.EmpLength + (item?.Feedbacks?.Avg || 0) + (item?.Tokens?.Tot || 0);
        }
        this.filteredItems.sort((a, b) => {
          if (a.EmpLength === b.EmpLength) {
            if (a.Pats?.Tot === b.Pats?.Tot) {
              if (a.Feedbacks?.Avg === b.Feedbacks?.Avg) {
                return a.Tokens?.Tot > b.Tokens?.Tot
                  ? -1
                  : a.Tokens?.Tot < b.Tokens?.Tot
                    ? 1
                    : 0;
              }
              return a.Feedbacks?.Avg > b.Feedbacks?.Avg
                ? -1
                : a.Feedbacks?.Avg < b.Feedbacks?.Avg
                  ? 1
                  : 0;
            }
            return a.Pats?.Tot > b.Pats?.Tot
              ? -1
              : a.Pats?.Tot < b.Pats?.Tot
                ? 1
                : 0;
          }
          return a.EmpLength > b.EmpLength
            ? -1
            : a.EmpLength < b.EmpLength
              ? 1
              : 0;
        });
        for (let items of this.filteredItems) {
          for (let item of items.details) {
            // console.log(item);
            if (item.Name.length > 22) {
              let n = item.Name.split(' ');
              this.fname = item.Name.split(' ')[0][0] + '.';
              this.lname = n[n.length - 1];
              this.profileName = this.fname + ' ' + this.lname;
              this.profileName = this.profileName.trim();
            } else {
              this.profileName = item.Name;
            }
            item['profileName'] = this.profileName;
          }
        }
        setTimeout(() => {
          if (this.focusOnSkill) {
            const skill = this.filteredItems.find(
              (skill: any) => skill.Id === this.focusOnSkill
            );
            this.expandRow(skill);
          }
        }, 1000);
        this.pp360SpinnerService.showSpinner(false);
      });
  }

  findMyGuru(itemId: string) {
    this.route.navigate(
      ['/Personal-Growth/employee-personal-growth/find-my-guru'],
      { queryParams: { idSkill: itemId }, replaceUrl: true }
    );
  }

  goToProfile(id) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: id },
      replaceUrl: true,
    });
  }

  expandRow(item: any) {
    // console.log(this.filteredItems);

    for (let i of item.details) {
      i['sortingValue'] =
        (i?.Pats?.Tot || 0) + (i?.Feedbacks?.Avg || 0) + (i?.Tokens?.Tot || 0);
    }

    item.details.sort((a, b) => {
      return a.sortingValue > b.sortingValue
        ? -1
        : a.sortingValue < b.sortingValue
          ? 1
          : 0;
    });

    if (item.ShowDetails) {
      item.ShowDetails = false;
      this.ref.detectChanges();
      this.openPop = false;
    } else {
      this.filteredItems.forEach((element: any) => {
        element.ShowDetails = false;
        this.openPop = true;
      });

      if (!item.details) {
        this.updateCurrentFilter();
        // this.OnLoading.emit();
        // this.praiseService.getItemsPersonsByBehaviorSkills(item.Id, null, this.currentFilter).subscribe((persons: any) => {

        // });
        this.OnLoaded.emit();
        item.ShowDetails = true;
        this.ref.detectChanges();
      } else {
        item.ShowDetails = true;
        this.ref.detectChanges();
      }
    }
  }

  getTooltip(t) {
    let key = 'PRAISES.' + t;
    key += this.viewMode;
    return this.coreService.getTranslation(key);
  }

  getNumber(n) {
    return n ? n : '';
  }

  sort(event: any, type: string, subtype: string = '') {
    for (let item of this.filteredItems) {
      item.ShowDetails = false;
    }

    let currentSort;

    if ($(event.target).hasClass('sort-asc')) {
      $(event.target).removeClass('sort-asc'),
        $(event.target).addClass('sort-desc');
      currentSort = 'desc';
    } else {
      $(event.target).removeClass('sort-desc'),
        $(event.target).addClass('sort-asc');
      currentSort = 'asc';
    }

    this.filteredItems.sort((a, b) => {
      // console.log(a, type, subtype);

      switch (type) {
        case 'Name':
          if (!a || !a.Name || !b || !a.Name) return -1;
          if (currentSort === 'desc') {
            return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? -1
              : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                ? 1
                : 0;
          } else {
            return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                ? -1
                : 0;
          }
          break;

        case 'EmpLength':
          if (!a || !a.EmpLength || !b || !a.EmpLength) return -1;
          if (currentSort === 'desc') {
            return a.EmpLength > b.EmpLength
              ? -1
              : a.EmpLength < b.EmpLength
                ? 1
                : 0;
          } else {
            return a.EmpLength > b.EmpLength
              ? 1
              : a.EmpLength < b.EmpLength
                ? -1
                : 0;
          }
          break;

        default:
          const valueA =
            !a || !a[type] || !a[type][subtype] ? 0 : a[type][subtype];
          const valueB =
            !b || !b[type] || !b[type][subtype] ? 0 : b[type][subtype];

          if (currentSort === 'desc') {
            return valueB - valueA;
          } else {
            return valueA - valueB;
          }

          break;
      }
    });
  }
}
