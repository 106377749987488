import { Injectable, Injector } from '@angular/core';
import { Theme, light, dark, defaultStyle, p1, p2, p3, p4 } from './theme';
interface RGB {
  r: number;
  g: number;
  b: number;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private previuosExternal: any;
  private active: Theme = defaultStyle;
  private activeIdx: number = 0;

  private externalTheme: Theme = undefined;
  private availableThemes: Theme[] = [];

  private K_Light_FontColor = 'rgba(247, 247, 247, 1)';
  private K_Dark_FontColor = 'rgba(43, 43, 43, 1)';

  private K_light_background1 = 'rgba(245, 245, 245, 1)';
  private K_light_background1_font = 'rgba(103, 106, 108, 1)';

  private K_light_background2 = 'rgba(255, 255, 255, 1)';
  private K_light_background2_font = 'rgba(103, 106, 108, 1)';

  private K_light_background3 = 'rgba(235, 235, 235, 1)';
  private K_light_background3_font = 'rgba(103, 106, 108, 1)';

  private K_light_background4 = 'rgba(222, 222, 222, 1)';
  private K_light_background4_font = 'rgba(103, 106, 108, 1)';

  private K_dark_background1 = 'rgba(24, 25, 26,1)';
  private K_dark_background1_font = 'rgba(255, 255, 255, 1)';

  private K_dark_background2 = 'rgba(36, 37, 38, 1)';
  private K_dark_background2_font = 'rgba(255, 255, 255, 1)';

  private K_dark_background3 = 'rgba(58, 59, 60, 1)';
  private K_dark_background3_font = 'rgba(255, 255, 255, 1)';

  private K_dark_background4 = 'rgba(75, 77, 78, 1)';
  private K_dark_background4_font = 'rgba(255, 255, 255, 1)';

  constructor() {
    this.calculateAllStyles(defaultStyle.properties);
    this.calculateAllStyles(light.properties);
    this.calculateAllStyles(dark.properties);
    this.calculateAllStyles(p1.properties);
    this.calculateAllStyles(p2.properties);
    this.calculateAllStyles(p3.properties);
    this.calculateAllStyles(p4.properties);

    this.availableThemes.push(defaultStyle);
    this.availableThemes.push(light);
    this.availableThemes.push(dark);
    this.availableThemes.push(p1);
    this.availableThemes.push(p2);
    this.availableThemes.push(p3);
    this.availableThemes.push(p4);
    this.availableThemes.push(this.externalTheme);
  }

  /********************************************************************************** */
  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }
  /********************************************************************************** */
  getActiveTheme(): Theme {
    return this.active;
  }
  /********************************************************************************** */
  switchTheme(): void {
    this.activeIdx++;
    if (this.activeIdx >= this.availableThemes.length) this.activeIdx = 0;
    let theme = this.availableThemes[this.activeIdx];
    if (!theme) this.switchTheme();
    else this.setActiveTheme(theme);
  }
  /********************************************************************************** */
  setExternalTheme(style) {
    if (this.previuosExternal != style) {
      this.previuosExternal = style;
      let obj = JSON.parse(style);
      let theme: Theme = { name: 'Extern', properties: obj };

      this.calculateAllStyles(obj);

      this.externalTheme = theme;
      this.availableThemes[this.availableThemes.length - 1] = theme;

      this.setActiveTheme(theme);
    }
  }

  /********************************************************************************** */
  calculateAllStyles(obj) {
    let footer = obj['--footer-default'];
    let header = obj['--header-default'];
    let menu = obj['--menu-default'];
    for (var key in obj) {
      let value = obj[key].replace(/\s/g, '');
      switch (key) {
        case '--background-type':
          if (value === 'light') {
            obj['--background1'] = this.K_light_background1;
            obj['--background1-fontcolor'] = this.K_light_background1_font;

            obj['--background2'] = this.K_light_background2;
            obj['--background2-fontcolor'] = this.K_light_background2_font;

            obj['--background3'] = this.K_light_background3;
            obj['--background3-fontcolor'] = this.K_light_background3_font;

            obj['--background4'] = this.K_light_background4;
            obj['--background4-fontcolor'] = this.K_light_background4_font;

            let c = this.getRGB(this.K_light_background2);
            obj['--border'] = this.LightenDarkenColorString(c, -13);
            obj['--shadow'] = this.LightenDarkenColorString(c, -11);

            let link1 = this.LightenDarkenColorString(this.getRGB(footer), 10);
            let link2 = this.LightenDarkenColorString(this.getRGB(header), 10);
            obj['--link-color'] = this.getVisibleColor(
              link1,
              link2,
              this.K_light_background2,
              this.K_light_background2_font
            );
            link1 = this.LightenDarkenColorString(this.getRGB(footer), -10);
            link2 = this.LightenDarkenColorString(this.getRGB(header), -10);
            obj['--link-color-hover'] = this.getVisibleColor(
              link1,
              link2,
              this.K_light_background2,
              this.K_light_background2_font
            );

            obj['--background2-icon'] = this.getVisibleColor(
              footer,
              header,
              this.K_light_background2,
              this.K_light_background2_font
            );
            obj['--background2-icon2'] = this.getVisibleColor(
              header,
              footer,
              this.K_light_background2,
              this.K_light_background2_font
            );
          } else {
            obj['--background1'] = this.K_dark_background1;
            obj['--background1-fontcolor'] = this.K_dark_background1_font;

            obj['--background2'] = this.K_dark_background2;
            obj['--background2-fontcolor'] = this.K_dark_background2_font;

            obj['--background3'] = this.K_dark_background3;
            obj['--background3-fontcolor'] = this.K_dark_background3_font;

            obj['--background4'] = this.K_dark_background4;
            obj['--background4-fontcolor'] = this.K_dark_background4_font;

            let c = this.getRGB(this.K_light_background2);
            obj['--border'] = this.LightenDarkenColorString(c, 13);
            obj['--shadow'] = this.LightenDarkenColorString(c, 11);

            let link1 = this.LightenDarkenColorString(this.getRGB(footer), 10);
            let link2 = this.LightenDarkenColorString(this.getRGB(header), 10);
            obj['--link-color'] = this.getVisibleColor(
              link1,
              link2,
              this.K_dark_background2,
              this.K_dark_background2_font
            );
            link1 = this.LightenDarkenColorString(this.getRGB(footer), -10);
            link2 = this.LightenDarkenColorString(this.getRGB(header), -10);
            obj['--link-color-hover'] = this.getVisibleColor(
              link1,
              link2,
              this.K_dark_background2,
              this.K_dark_background2_font
            );

            obj['--background2-icon'] = this.getVisibleColor(
              footer,
              header,
              this.K_dark_background2,
              this.K_dark_background2_font
            );
            obj['--background2-icon2'] = this.getVisibleColor(
              header,
              footer,
              this.K_dark_background2,
              this.K_dark_background2_font
            );
          }

          break;
        case '--footer-default':
        case '--header-default':
        case '--menu-default':
          {
            let c = this.getRGB(value);
            let lightKey = key + '-light';
            if (!obj[lightKey]) {
              obj[lightKey] = this.LightenDarkenColorString(c, 60);
              // obj[lightKey] = value.replace(',1)', ',.3)');
            }

            let mediumKey = key + '-medium';
            if (!obj[mediumKey]) {
              obj[mediumKey] = this.LightenDarkenColorString(c, 30);
              // obj[mediumKey] = value.replace(',1)', ',.6)');
            }

            let darkKey = key + '-dark';
            if (!obj[darkKey]) {
              obj[darkKey] = this.LightenDarkenColorString(c, 15);
              // obj[mediumKey] = value.replace(',1)', ',.6)');
            }

            let fontColorKey = this.setFontColor(obj, key, value);

            let iconColorKey = this.setIconColor(obj, key, value, fontColorKey);
          }
          break;
      }
    }
  }
  /********************************************************************************** */
  getVisibleColor(iconColor, iconColor2, backColor, defaultColor): string {
    let iColor = this.getRGB(iconColor.replace(/\s/g, ''));
    let iColor2 = this.getRGB(iconColor2.replace(/\s/g, ''));
    let bColor = this.getRGB(backColor.replace(/\s/g, ''));
    let color = iconColor;

    let deltaE = this.deltaE(iColor, bColor);
    let deltaBrightness = Math.abs(
      this.getColorBrightness(iColor) - this.getColorBrightness(bColor)
    );
    let difference = this.getColorsDifference(iColor, bColor);
    let msg = 'OK';
    if (deltaE <= 1 || deltaBrightness <= 110 || difference <= 300) {
      color = iconColor2;

      deltaE = this.deltaE(iColor2, bColor);
      deltaBrightness = Math.abs(
        this.getColorBrightness(iColor2) - this.getColorBrightness(bColor)
      );
      difference = this.getColorsDifference(iColor2, bColor);

      if (deltaE <= 1 || deltaBrightness <= 110 || difference <= 300) {
        msg = 'FAIL';
        color = defaultColor;
      }
    }

    // console.log('>>>>>>' + msg + ' ===> Icon:' + iconColor + ', Icon2:' + iconColor2 + ', Back:'+ backColor +
    //             ' ==> DeltaE:' + deltaE + ', deltaBrightness:' + deltaBrightness + ', difference:' + difference + " =>> USED: " + color);

    return color;
  }

  /********************************************************************************** */
  setIconColor(obj, key, value, fontColorKey): string {
    let iconColorKey = key + '-icon';
    if (!obj[iconColorKey]) {
      let color = obj[fontColorKey];
      let cBack = this.getRGB(value);
      if (key === '--menu-default') {
        color = this.getVisibleColor(
          obj['--footer-default'],
          obj['--header-default'],
          value,
          obj[fontColorKey]
        );
      } else if (key === '--footer-default') {
        color = this.getVisibleColor(
          obj['--header-default'],
          obj['--menu-default'],
          value,
          obj[fontColorKey]
        );
      } else if (key === '--header-default') {
        color = this.getVisibleColor(
          obj['--footer-default'],
          obj['--menu-default'],
          value,
          obj[fontColorKey]
        );
      }

      obj[iconColorKey] = color;
    }
    return iconColorKey;
  }

  /********************************************************************************** */
  /********************************************************************************** */
  setFontColor(obj, key, value): string {
    let fontColorKey = key + '-fontcolor';
    if (!obj[fontColorKey]) {
      let c = this.getRGB(value);

      let type = this.isLightOrDarkColor(c);
      if (type === 'light') {
        //font DARK

        obj[fontColorKey] = this.K_Dark_FontColor;
      } else {
        //font LIGHT

        obj[fontColorKey] = this.K_Light_FontColor;
      }
    }
    return fontColorKey;
  }

  /********************************************************************************** */
  getRGB(rgbaString): RGB {
    let rgbString = rgbaString.substring(5, rgbaString.length - 1);
    let tk = rgbString.split(',');

    return { r: parseInt(tk[0]), g: parseInt(tk[1]), b: parseInt(tk[2]) };
  }
  /********************************************************************************** */
  setDefaultTheme(): void {
    this.setActiveTheme(defaultStyle);
  }
  /********************************************************************************** */
  setActiveTheme(theme: Theme): void {
    this.active = theme;

    this.activeIdx = this.availableThemes.findIndex(
      (i) => i && i.name === theme.name
    );
    Object.keys(this.active.properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }

  /********************************************************************************** */
  isLightOrDarkColor(c: RGB) {
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    let hsp = Math.sqrt(
      0.299 * (c.r * c.r) + 0.587 * (c.g * c.g) + 0.114 * (c.b * c.b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 190) {
      //127.5

      return 'light';
    } else {
      return 'dark';
    }
  }
  /********************************************************************************** */
  LightenDarkenColor(c: RGB, percent): RGB {
    let amt = Math.round(2.55 * percent),
      R = c.r + amt,
      B = c.b + amt,
      G = c.g + amt;

    let rgb: RGB = {
      r: R < 255 ? (R < 1 ? 0 : R) : 255,
      g: G < 255 ? (G < 1 ? 0 : G) : 255,
      b: B < 255 ? (B < 1 ? 0 : B) : 255,
    };

    return rgb;
  }
  /********************************************************************************** */
  LightenDarkenColorString(c: RGB, percent): string {
    let rgb = this.LightenDarkenColor(c, percent);
    return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',1)';
  }
  /*********************************************************************************** */
  getColorBrightness(c: RGB): number {
    return c.r * 0.299 + c.g * 0.587 + c.b * 0.114;
  }
  /************************************************************************************/
  getColorsDifference(c1: RGB, c2: RGB): number {
    return (
      Math.max(c1.r, c2.r) -
      Math.min(c1.r, c2.r) +
      (Math.max(c1.g, c2.g) - Math.min(c1.g, c2.g)) +
      (Math.max(c1.b, c2.b) - Math.min(c1.b, c2.b))
    );
  }
  /************************************************************************************
   *
   * deltaE
   * <=1 Differenza non percettibile
   * 1-2 percettibile con l'osservazione
   * 2-10 percettibile
   * 11-49 più simili che opposti
   * 100 opposti
   */
  deltaE(rgbA: RGB, rgbB: RGB) {
    let labA = this.rgb2lab(rgbA);
    let labB = this.rgb2lab(rgbB);
    let deltaL = labA[0] - labB[0];
    let deltaA = labA[1] - labB[1];
    let deltaB = labA[2] - labB[2];
    let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    let deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    let sc = 1.0 + 0.045 * c1;
    let sh = 1.0 + 0.015 * c1;
    let deltaLKlsl = deltaL / 1.0;
    let deltaCkcsc = deltaC / sc;
    let deltaHkhsh = deltaH / sh;
    let i =
      deltaLKlsl * deltaLKlsl +
      deltaCkcsc * deltaCkcsc +
      deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
  }

  rgb2lab(rgb) {
    let r = rgb.r / 255,
      g = rgb.g / 255,
      b = rgb.b / 255,
      x,
      y,
      z;
    r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;
    return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
  }
}
