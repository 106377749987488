import { Component, OnInit } from '@angular/core';
import { GoogleLoginProvider } from 'angularx-social-login';
import { SocialAuthService } from 'angularx-social-login';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.css'],
})
export class SocialLoginComponent implements OnInit {
  isLogging: boolean;

  constructor(
    private authService: AuthService,
    private socialLoginService: SocialAuthService
  ) {}

  signInWithGoogle(): void {
    this.isLogging = true;
    this.socialLoginService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((googleUser) => {
        this.authService.googleLogin(googleUser).subscribe(
          (data) => {
            this.isLogging = false;
          },
          (error) => {
            this.isLogging = false;
          }
        );
      });
  }

  ngOnInit(): void {}
}
