import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BasicLayoutComponent } from './basicLayout.component';
import { BlankLayoutComponent } from './blankLayout.component';
import { TopNavigationLayoutComponent } from './topNavigationLayout.component';

import { NavigationComponent } from './../navigation/navigation.component';
//import {FooterComponent} from "./../footer/footer.component";
import { HeaderComponent } from './../header/header.component';
import { TopNavbarComponent } from './../topnavbar/topnavbar.component';
import { TopNavigationNavbarComponent } from './../topnavbar/topnavigationnavbar.component';
import { SharedModule } from 'app/shared/shared.module';

import { DeviceDetectorService } from 'ngx-device-detector';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

@NgModule({
  declarations: [
    //FooterComponent,
    HeaderComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    AngularMultiSelectModule,
  ],
  exports: [
    //FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent,
  ],
  providers: [DeviceDetectorService],
})
export class LayoutsModule {}
