import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'pp360-doughnut-chart',
  templateUrl: './pp360-doughnut-chart.component.html',
  styleUrls: ['./pp360-doughnut-chart.component.scss'],
})
export class Pp360DoughnutComponent implements OnInit {
  @Input() review: any;
  @Input() canvasConfig: any;

  private chart: ElementRef;
  @ViewChild('chart') set _chart(content: ElementRef) {
    if (content) {
      this.chart = content;
    }
  }
  myChart: any;
  constructor() {}

  ngOnInit(): void {
    // console.log(this.review,this.canvasConfig);
  }

  ngAfterViewInit() {
    this.initChart();
  }
  initChart() {
    if (this.chart) {
      this.myChart?.destroy();
      console.log('ggg', this.chart);
      this.myChart = new Chart(this.chart.nativeElement, {
        type: 'doughnut',
        data: {
          labels: ['cycle 1', 'cycle 2', 'cycle 3', 'cycle 4'],
          datasets: [
            {
              type: 'doughnut',
              label: 'Bar Dataset',
              data: this.canvasConfig.data,
              backgroundColor: this.canvasConfig.color,
              hoverBackgroundColor: this.canvasConfig.color,
              borderColor: this.canvasConfig.borderColor,
              borderWidth: 1,
              hoverBorderWidth: 1,
              hoverOffset: 4,
            },
          ],
        },

        options: {
          cutout: this.canvasConfig.cutout,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: false,
              display: false,
            },
            y: {
              stacked: false,
              display: false,
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  }
}
