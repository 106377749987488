import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { SharedService } from 'app/shared/services/shared.service';
import { CoreService } from 'app/shared/services/core.service';

import {
  BsDatepickerConfig,
  BsDatepickerDirective,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ImageSelectorComponent } from 'app/company-report/objectives-individual/objective/obj-sheet-popup/image-selector/image-selector.component';

import { ScreenSizeService } from 'app/shared/services/screen-size.service';
// Cordova
import { Platform } from '@ionic/angular';

// Utility
import * as moment from 'moment-timezone';

// crop-image
import { CustomImageCropperComponent } from 'app/shared/components/custom-image-cropper/custom-image-cropper.component';
import { base64ToFile } from 'ngx-image-cropper';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { WallService } from '../wall.service';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { defineLocale, zhCnLocale } from 'ngx-bootstrap/chronos';
import {
  itLocale,
  enGbLocale,
  esLocale,
  frLocale,
  plLocale,
  ptBrLocale,
  deLocale,
  ruLocale,
  nlLocale,
  arLocale,
} from 'ngx-bootstrap/locale';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news-dialog',
  templateUrl: './news-dialog.component.html',
  styleUrls: ['./news-dialog.component.scss', '../../../common_styles.scss'],
})
export class NewsDialogComponent implements OnInit {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showAlert: boolean = false;
  showNotice: boolean = false;
  showEvent: boolean = false;
  disableSend: boolean = false;
  croppedImage: any = '';
  isUploading: boolean = false;
  timeValue: any;
  isTimeFocused: boolean = false;
  ischangeImg: boolean = false;
  isMobile: boolean;
  localTimeZone: any;
  localTimeRegion: any;
  imageUrl: any = '';
  public optionalPhotoData: any;
  public onClose: Subject<any>;
  public categories: any[];
  userInfo: any;
  @Output() addNews = new EventEmitter();
  news: any;
  selectedDate = new Date();
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  form: FormGroup;
  isRequired: boolean = false;
  isRequire: boolean = false;
  timeZones = [
    { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
    { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
    { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
    {
      label: '(GMT-08:00) Pacific Time (US & Canada)',
      value: 'America/Los_Angeles',
    },
    { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
    { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
    {
      label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
      value: 'America/Chihuahua',
    },
    { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
    { label: '(GMT-06:00) Central America', value: 'America/Managua' },
    { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
    {
      label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
      value: 'America/Mexico_City',
    },
    { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
    {
      label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
      value: 'America/Bogota',
    },
    { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
    { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
    { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
    { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
    { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
    { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
    { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
    {
      label: '(GMT-03:00) Buenos Aires, Georgetown',
      value: 'America/Argentina/Buenos_Aires',
    },
    { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
    { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
    { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
    { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    {
      label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
      value: 'Africa/Casablanca',
    },
    {
      label:
        '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      value: 'Etc/Greenwich',
    },
    {
      label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 'Europe/Amsterdam',
    },
    {
      label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      value: 'Europe/Belgrade',
    },
    {
      label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
      value: 'Europe/Brussels',
    },
    {
      label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      value: 'Europe/Sarajevo',
    },
    { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
    { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
    {
      label: '(GMT+02:00) Athens, Bucharest, Istanbul',
      value: 'Europe/Athens',
    },
    { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
    { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
    {
      label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 'Europe/Helsinki',
    },
    { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
    { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
    {
      label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
      value: 'Europe/Moscow',
    },
    { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
    { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
    { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
    { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
    { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
    { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
    { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
    {
      label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
      value: 'Asia/Karachi',
    },
    {
      label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      value: 'Asia/Calcutta',
    },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
    { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
    { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
    { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    {
      label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      value: 'Asia/Hong_Kong',
    },
    {
      label: '(GMT+08:00) Kuala Lumpur, Singapore',
      value: 'Asia/Kuala_Lumpur',
    },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
    { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
    { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
    { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
    { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
    { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
    { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
    { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
    {
      label: '(GMT+10:00) Canberra, Melbourne, Sydney',
      value: 'Australia/Canberra',
    },
    { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
    { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
    { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
    {
      label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      value: 'Asia/Magadan',
    },
    { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
    {
      label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
      value: 'Pacific/Fiji',
    },
    { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
  ];
  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSource: Observable<any>;
  sessionToken: string;
  isTypeaheadLoaded = false;
  @Output() update = new EventEmitter();
  whenReset: Date;
  resetTime: boolean = true;
  finalCroppedImage: any;
  currentFile: File;
  finalName: string;
  isEditableImg: boolean = false;

  public constructor(
    public _bsModalRef: BsModalRef,
    public sharedService: SharedService,
    public coreService: CoreService,
    public wallService: WallService,
    private modalService: BsModalService,
    public screenSizeService: ScreenSizeService,
    public fb: FormBuilder,
    public changeDetectorRef: ChangeDetectorRef,
    public platform: Platform,
    private pp360SpinnerService: Pp360SpinnerService,
    private localeService: BsLocaleService,
    private sanitizer: DomSanitizer
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }
  public ngOnInit(): void {
    this.onClose = new Subject();
    this.userInfo = this.coreService.getUserInfo();
    this.selectedDate.setDate(this.selectedDate.getDate() - 0);
    this.categories = [
      {
        Id: 1,
        Name: this.coreService.getTranslation('News.Event'),
        Icon: 'fa-clock-o',
      },
      {
        Id: 2,
        Name: this.coreService.getTranslation('News.Notice'),
        Icon: 'fa-sticky-note-o',
      },
      {
        Id: 3,
        Name: this.coreService.getTranslation('News.Alert'),
        Icon: 'fa-bell-o',
      },
    ];
    enGbLocale.invalidDate = 'Invalid date';
    itLocale.invalidDate = 'Data non valida';
    ptBrLocale.invalidDate = 'Data inválida';
    esLocale.invalidDate = 'Fecha no válida';
    frLocale.invalidDate = 'Date invalide';
    plLocale.invalidDate = 'Nieważna data';
    deLocale.invalidDate = 'Ungültiges Datum';
    ruLocale.invalidDate = 'Неверная дата';
    nlLocale.invalidDate = 'Ongeldige datum';
    arLocale.invalidDate = 'Invalid date';
    zhCnLocale.invalidDate = '无效日期';

    switch (this.userInfo.language) {
      case 'en':
        defineLocale(this.userInfo.language, enGbLocale);
        break;
      case 'it':
        // enGbLocale.invalidDate = 'Custom label';
        defineLocale(this.userInfo.language, itLocale);
        break;
      case 'pt':
        defineLocale(this.userInfo.language, ptBrLocale);
        break;
      case 'es':
        defineLocale(this.userInfo.language, esLocale);
        break;
      case 'fr':
        defineLocale(this.userInfo.language, frLocale);
        break;
      case 'pl':
        defineLocale(this.userInfo.language, plLocale);
        break;
      case 'de':
        defineLocale(this.userInfo.language, deLocale);
        break;
      case 'ru':
        defineLocale(this.userInfo.language, ruLocale);
        break;
      case 'nl':
        defineLocale(this.userInfo.language, nlLocale);
        break;
      case 'ar':
        defineLocale(this.userInfo.language, arLocale);
        break;
      case 'zh':
        defineLocale(this.userInfo.language, zhCnLocale);
        break;
    }
    this.localeService.use(this.userInfo.language);
    // console.log(moment.tz.guess());

    this.timeValue = this.timeZones.filter(
      (x) => x.value === 'Europe/Amsterdam'
    );
    // console.log('Default TimeZone Set:', this.timeValue);
  }

  initTypeahead() {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(
      debounceTime(400),
      mergeMap((token: string) => this.filterResults(token))
    );
    this.isTypeaheadLoaded = true;
  }

  filterResults(token: string) {
    // if your backend does the filtering, just return the results.
    // oterwise we have to massage them a bit
    return this.wallService
      .getPlacePredictions(token, this.sessionToken || null)
      .pipe(
        map((result: any) => {
          if (result && result.Predictions) {
            this.sessionToken = result.SessionToken;
            return result.Predictions;
          }
          return of([]);
        })
      );
  }

  // Show loading indicator
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  // Selected value event
  typeaheadOnSelect(e: TypeaheadMatch): void {
    // console.log('Selected value: ', e.value);
    this.asyncSelected = e.value;
    this.getPlaceDetails(e);
  }

  getPlaceDetails(place) {
    this.wallService
      .getPlacePredictionDetails(place.item.Place_id, this.sessionToken)
      .subscribe((res: any) => {
        if (res && res.Result) {
          this.sessionToken = null;
          this.form.patchValue({
            PlaceId: res.PlaceId,
            Where: this.asyncSelected,
            Latitude: res.Result.Geometry.Location.Lat,
            Longitude: res.Result.Geometry.Location.Lng,
          });
          this.form.markAsDirty();
        }
      });
  }

  initModal(news) {
    if (news?.TimeZone) {
      news.TimeZone = this.timeZones.find((x) => x.value === news.TimeZone);
    }
    this.news = JSON.parse(JSON.stringify(news));
    if (news) {
      this.imageUrl = news.Picture;
    }
    this.buildForm();
  }

  selectedZone(e) {
    if (this.form.value.category !== 1) {
      let when = new Date();
      if (this.news && this.news?.When) {
        const w = moment(this.news?.When).format('YYYY-MM-DD');
        when = new Date(w);
        this.whenReset = when;
      }
      if (this.news && this.news?.Where) {
        this.asyncSelected = this.news.Where;
      }
      let time = this.news?.StartTime
        ? new Date(this.news?.StartTime)
        : new Date();
      if (!moment(time).isValid()) {
        time = new Date();
      }
      if (!this.news) {
        const h = time.getHours();
        const m = time.getMinutes();
        time.setHours(h + 2);
        time.setMinutes(m);
      }
      this.resetTime = true;
      this.timeValue = e;
      if (this.timeValue) {
        this.form.patchValue({
          TimeZone: e?.value,
          When: when,
          StartTime: time,
        });
      } else {
        this.form.patchValue({
          TimeZone: e?.value,
          When: null,
          StartTime: null,
        });
      }
    } else {
      this.resetTime = true;
      this.form.patchValue({
        TimeZone: e.value,
      });
      this.timeValue = e;
    }
  }

  buildForm() {
    let when = new Date();
    if (this.news && this.news?.When) {
      const w = moment(this.news?.When).format('YYYY-MM-DD');
      when = new Date(w);
      this.whenReset = when;
    }
    if (this.news && this.news?.Where) {
      this.asyncSelected = this.news.Where;
    }
    let time = this.news?.StartTime
      ? new Date(this.news?.StartTime)
      : new Date();
    if (!moment(time).isValid()) {
      time = new Date();
    }
    if (!this.news) {
      const h = time.getHours();
      const m = time.getMinutes();
      time.setHours(h + 2);
      time.setMinutes(m);
    }
    if (this.news && this.news.Picture) {
      const urlSegments = this.news.Picture.split('/');
      const lastSegment = urlSegments[urlSegments.length - 1];
      const imageName = lastSegment.split('?')[0];
      this.finalName = imageName;
    }
    this.form = this.fb.group({
      Title: [this.news?.Title, Validators.required],
      Text: [this.news?.Description],
      IsOnSite: [this.news?.IsOnSite || false],
      When: [when, Validators.required],
      StartTime: [time, Validators.required],
      Category: [this.news?.Category || 1, Validators.required],
      TimeZone: [this.news?.TimeZone?.value],
      PlaceId: [null],
      Latitude: [],
      Longitude: [],
    });
    if (this.news && this.news?.When === null) {
      this.timeValue = false;
      this.form.patchValue({
        When: null,
        StartTime: null,
      });
    }
    if (this.form.value.Category === 1) {
      this.updateControls();
    } else {
      this.updateControlForCategoryChange();
    }
    this.changeDetectorRef.detectChanges();
    this.initTypeahead();
  }

  radioChange(e) {
    let when = new Date();
    if (this.news && this.news?.When) {
      const w = moment(this.news?.When).format('YYYY-MM-DD');
      when = new Date(w);
      this.whenReset = when;
    }
    if (this.news && this.news?.Where) {
      this.asyncSelected = this.news.Where;
    }
    let time = this.news?.StartTime
      ? new Date(this.news?.StartTime)
      : new Date();
    if (!moment(time).isValid()) {
      time = new Date();
    }
    if (!this.news) {
      const h = time.getHours();
      const m = time.getMinutes();
      time.setHours(h + 2);
      time.setMinutes(m);
    }
    const zone = this.timeValue[0].value;
    this.form.patchValue({
      IsOnSite: e,
      Title: null,
      Text: null,
      ExternalLink: null,
      TimeZone: zone,
      When: when,
      StartTime: time,
    });
    this.isRequired = false;
    this.isRequire = false;
    this.asyncSelected = '';
    this.updateControls();
  }

  updateControls() {
    const isOnSite = this.form.value.IsOnSite;
    if (isOnSite) {
      this.form.removeControl('ExternalLink');
      this.form.addControl('Where', new FormControl(this.news?.Where));
    } else {
      this.form.removeControl('Where');
      this.form.addControl(
        'ExternalLink',
        new FormControl(this.news?.ExternalLink, [
          Validators.required,
          Validators.pattern(
            '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=-]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=-]*)'
          ),
        ])
      );
    }
    this.form.updateValueAndValidity();
  }

  changeCategory(category) {
    if (category !== 1) {
      this.timeValue = '';
      this.resetTime = false;
      this.form.patchValue({
        Title: null,
        Text: null,
        ExternalLink: null,
        TimeZone: null,
        When: null,
        StartTime: null,
        Category: category,
      });
    } else if (category === 1) {
      this.resetTime = true;
      this.localeService.use(this.userInfo.language);
      this.timeValue = this.timeZones.filter(
        (x) => x.value === 'Europe/Amsterdam'
      );
      this.form.patchValue({
        Category: category,
      });
    }
    this.imageUrl = '';
    this.isRequired = false;
    this.updateControlForCategoryChange();
  }

  updateControlForCategoryChange() {
    const category = this.form.value.Category;
    if (category === 1) {
      this.form.addControl(
        'IsOnSite',
        new FormControl(this.news?.IsOnSite, Validators.required)
      );
      this.form.addControl('TimeZone', new FormControl(this.news?.TimeZone));
      this.form.addControl('Latitude', new FormControl(this.news?.Latitude));
      this.form.addControl('Longitude', new FormControl(this.news?.Longitude));
      this.radioChange(false);
    } else if (category !== 1) {
      this.form.addControl('TimeZone', new FormControl(this.news?.TimeZone));
      this.form.addControl('Latitude', new FormControl(this.news?.Latitude));
      this.form.addControl('Longitude', new FormControl(this.news?.Longitude));
    } else {
      this.form.removeControl('IsOnSite');
      this.form.removeControl('TimeZone');
      this.form.removeControl('Longitude');
      this.form.removeControl('Latitude');
      this.form.removeControl('ExternalLink');
      this.form.addControl(
        'Where',
        new FormControl(this.news?.Where, Validators.required)
      );
    }
    this.form.updateValueAndValidity();
  }

  getdpConfig() {
    this.dpConfig.adaptivePosition = true;
    this.dpConfig.dateInputFormat = 'DD/MM/YYYY';
    this.dpConfig.displayOneMonthRange = true;
    this.userInfo.language === 'it' ? 'D MMM, YYYY' : 'MMM D, YYYY';
    this.dpConfig.containerClass = 'magda_datepicker_theme_123';
    this.dpConfig.customTodayClass = 'custom-today-class';
    this.dpConfig.useUtc = true;
    this.dpConfig.showWeekNumbers = false;
    return this.dpConfig;
  }

  onAddImage(event) {
    if (this.news) {
      this.ischangeImg = true;
    }
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.cropImage(file);
      this.currentFile = file;
      this.finalName = this.currentFile.name;
    }
  }

  cropImage(file) {
    const cropModalRef = this.modalService.show(CustomImageCropperComponent, {
      class: 'image-change',
    });
    const content = {
      file,
      isRounded: false,
      aspectRatio: 4 / 2,
    };
    (<CustomImageCropperComponent>cropModalRef.content).showModal(content);
    (<CustomImageCropperComponent>cropModalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.croppedImage = result;
          this.uploadImage(file);
          this.isDone();
        }
      }
    );
  }

  uploadImage(file) {
    this.ischangeImg = true;
    const imageFile = base64ToFile(this.croppedImage);
    let formData: FormData = new FormData();
    formData.append('uploadFile', imageFile, file.name);
    this.pp360SpinnerService.showSpinner(true);
    this.sharedService.onAddImage(formData).subscribe(
      (data: any) => {
        this.optionalPhotoData = data;
        if (this.news) {
          this.news.Picture =
            this.coreService.getBaseURL() + this.optionalPhotoData.ImageUrl;
        } else {
          this.imageUrl =
            this.coreService.getBaseURL() + this.optionalPhotoData.ImageUrl;
        }
        this.isUploading = false;
        this.pp360SpinnerService.showSpinner(false);
      },
      (err: any) => {
        console.log('onAddImage err => ', JSON.stringify(err));
        this.pp360SpinnerService.showSpinner(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isUploading = false;
      }
    );
  }

  onRemoveImg() {
    if (this.imageUrl) {
      this.imageUrl = '';
    }
    if (this.news) {
      this.news.Picture = '';
      this.ischangeImg = true;
      const fileInput = document.getElementById('addImage') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
    }
    this.isEditableImg = false;
  }

  submitForm() {
    if (this.form?.value?.Category === 1) {
      if (this.form.invalid || this.disableSend) {
        this.coreService.toasterMessage(
          'error',
          'auth',
          'Please fill required fileds'
        );
        return;
      }

      this.loading$.next(true);
      this.disableSend = true;
    }
    const req = this.buildRequest();
    if (this.form?.value?.Category === 1 || this.timeValue) {
      if (moment(req.StartTime).isValid()) {
        req.StartTime = moment(req.StartTime).format('HH:mm');
      } else {
        req.StartTime = '';
      }
      if (!moment(req.When).isValid()) {
        req.When = '';
      } else {
        req.When = moment(req.When).format('YYYY-MM-DD');
      }
    } else {
      if (this.form?.value?.Category !== 1 && !this.timeValue) {
        req.StartTime = '';
        req.When = '';
      }
    }

    if (this.news) {
      req.Id = this.news.Id;
      this.updateNews(req);
    } else {
      this.createNews(req);
    }
  }

  createNews(req) {
    this.pp360SpinnerService.showSpinner(true);
    this.coreService.postNews(req).subscribe(
      (data: any) => {
        this.disableSend = false;
        this.loading$.next(false);
        // this.coreService.toasterMessage(
        //   "success",
        //   "auth",
        //   this.coreService.getTranslation("Messages.NewsAdded")
        // );
        if (this.form?.value?.Category === 1) {
          this.coreService.setShowPublished({ type: 'News', isEdit: false });
        } else if (this.form?.value?.Category === 2) {
          this.coreService.setShowPublished({ type: 'Info', isEdit: false });
        } else if (this.form?.value?.Category === 3) {
          this.coreService.setShowPublished({ type: 'Success', isEdit: false });
        }
        this.pp360SpinnerService.showSpinner(false);
        this.onClose.next(true);
        this._bsModalRef.hide();
      },
      (err: any) => {
        this.disableSend = false;
        this.pp360SpinnerService.showSpinner(false);
        this.loading$.next(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  updateNews(req) {
    this.pp360SpinnerService.showSpinner(true);
    this.coreService.updateNews(req).subscribe(
      (data: any) => {
        this.update.emit(data);
        this.disableSend = false;
        this.loading$.next(false);
        // this.coreService.toasterMessage(
        //   "success",
        //   "auth",
        //   this.coreService.getTranslation("Messages.NewsUpdated")
        // );
        if (this.form?.value?.Category === 1) {
          this.coreService.setShowPublished({ type: 'News', isEdit: true });
        } else if (this.form?.value?.Category === 2) {
          this.coreService.setShowPublished({ type: 'Info', isEdit: true });
        } else if (this.form?.value?.Category === 3) {
          this.coreService.setShowPublished({ type: 'Success', isEdit: true });
        }
        this.pp360SpinnerService.showSpinner(false);
        this.onClose.next(true);
        this._bsModalRef.hide();
      },
      (err: any) => {
        this.disableSend = false;
        this.pp360SpinnerService.showSpinner(false);
        this.loading$.next(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  buildRequest() {
    const form = this.form.value;
    if (this.news && this.news?.Picture) {
      this.ischangeImg = true;
      form.Picture = this.news.Picture;
    } else {
      form.Picture = this.imageUrl;
    }
    return JSON.parse(JSON.stringify(form));
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.onClose.next(null);
    this._bsModalRef.hide();
  }

  getNewsImage() {
    if (this.news) {
      if (this.news?.Picture) {
        return {
          'background-image': `url('${this.news?.Picture}')`,
          'background-color': `var(--feed-background)`,
          border: `none`,
        };
      } else {
        return undefined;
      }
    } else {
      if (this.imageUrl) {
        return {
          'background-image': `url('${this.imageUrl}')`,
          'background-color': `var(--feed-background)`,
          border: `none`,
        };
      } else {
        return undefined;
      }
    }
  }

  selectImage() {
    const content: any = {
      Search: '',
    };
    let modal = this.modalService.show(ImageSelectorComponent, {
      class: 'image-selector',
    });
    if (this.isMobile) {
      setTimeout(() => {
        $('.modal-backdrop').attr('style', 'z-index: 3002 !important');
        $('modal-container:last-child').css('z-index', '3003');
      }, 1000);
    }
    this.ischangeImg = true;

    (<ImageSelectorComponent>modal.content).showModal(content);
    (<ImageSelectorComponent>modal.content).onClose.subscribe((result: any) => {
      if (result) {
        this.finalCroppedImage = result.urls.small;
        // this.currentFile.size = 0;
        this.finalName = result.user.name;

        if (this.news) {
          this.news.Picture = result.urls.small;
        } else {
          this.imageUrl = result.urls.small;
        }
        this.onEdit(result.urls.regular);

        setTimeout(() => {
          // Immagine fittizzia per sapere quando viene caricata
          var image = new Image();
          image.src = result.urls.small;
          image.onload = () => {
            this.loading$.next(false);
          };
        }, 250);
      }
    });
    (<ImageSelectorComponent>modal.content).onCloseModal.subscribe(
      (base64: string) => {
        if (base64) {
          // console.log('onCloseModal', base64);
          this.news.ImagePath = base64;
          this.news.Picture = base64;
          // this.onEdit(base64)
          setTimeout(() => {
            // Immagine fittizzia per sapere quando viene caricata
            var image = new Image();
            image.src = base64;
            image.onload = () => {
              this.loading$.next(false);
            };
          }, 250);
        }
      }
    );
    modal.onHidden.subscribe(() => {
      $('.modal-backdrop').attr('style', 'z-index: 2040 !important');
    });
  }
  cancelChanges() {
    if (
      !this.form?.dirty &&
      !this.ischangeImg &&
      this.form.value.Category === 1
    ) {
      this._bsModalRef?.hide();
    } else if (
      !this.form?.dirty &&
      !this.ischangeImg &&
      this.form.value.Category !== 1 &&
      !this.timeValue
    ) {
      this._bsModalRef?.hide();
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DISCARD'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DISCARD_CHANGES'),
        subtitle: this.coreService.getTranslation('WALL.DISCARD_CHANGES_TITLE'),
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation',
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this._bsModalRef?.hide();
          }
        }
      );
    }
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  getImageSize(file) {
    return file ? (file.size / (1024 * 1024)).toFixed(2) : 0;
  }
  isDone() {
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(base64ToFile(this.croppedImage))
    );
    this.finalCroppedImage = url;
  }
  async onEdit(item: any) {
    const response = await fetch(item);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
    this.croppedImage = { file, url };
    this.cropImage(file);
  }
  onImageShow() {
    setTimeout(() => {
      this.isEditableImg = false;
    }, 1000);
  }

  makeImgEditable() {
    if (this.isMobile) {
      this.isEditableImg = true;
    }
  }
}
