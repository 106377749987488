import { Component, OnInit, NgZone, Directive } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { RestfulService } from './shared/services/restful.service';
import { CoreService } from './shared/services/core.service';
import { BleUtils } from './ble';
import * as locales from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BiometricService } from 'app/shared/services/cordova/biometric.service';

import { Platform } from '@ionic/angular';

import * as moment from 'moment';
import 'moment/min/locales';
import { NavigationEnd, Router } from '@angular/router';
import { ScreenSizeService } from './shared/services/screen-size.service';
function defineLocales() {
  for (const locale in locales) {
    defineLocale(locales[locale].abbr, locales[locale]);
  }
}

declare var blePeripheral;
declare var ble;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Keyboard],
})
@Directive({
  selector: '[preventTouchStart,preventDrag]',
})
export class AppComponent implements OnInit {
  public hideToolbar: boolean = false;
  public isMobile: boolean = false;
  somethingChanged = false;
  properties = [];
  ble: BleUtils;
  userDetails: any;
  constructor(
    private zone: NgZone,
    public translate: TranslateService,
    public restServ: RestfulService,
    public coreService: CoreService,
    private diagnostic: Diagnostic,
    private router: Router,
    public screenSizeService: ScreenSizeService,
    private biometricService: BiometricService,
    private platform: Platform
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
      this.checkHideToolbar();
    });
    translate.addLangs([
      'en',
      'it',
      'pl',
      'es',
      'pt',
      'nl',
      'de',
      'zh',
      'fr',
      'ru',
    ]);
    translate.setDefaultLang('en');
    defineLocales();
    const lang = translate.getBrowserLang();

    translate.use(lang.match(/en|it|pl|nl|es|pt|de|ru|fr|zh/) ? lang : 'en');
    moment.locale(lang);
    if (lang === 'zh') {
      moment.locale(lang + '-cn');
    }
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        // console.log(e.url);
        this.checkHideToolbar();
      }
    });
    // Cordova platform and plugins initialization
    // document.addEventListener('deviceready', () => {
    //   console.log('deviceready');
    //   this.zone.run(() => this.onCordovaReady());
    // }, false);
    this.platform.ready().then((readySource) => {
      // console.log('Platform ready from', readySource);
      this.onCordovaReady();
    });
    // interval(60 * 1000) //60 seconds
    //   .subscribe(() => {
    //     this.getUpdatedUserInfo();
    //   });
  }

  async ngAfterViewInit() {
    this.userDetails = await this.coreService.getUserInfo();
    await this.getUpdatedUserInfo();
  }
  get isLoggedIn() {
    const url = this.router.url;
    return this.coreService.isLoggedIn() && !url.includes('choose-login');
  }

  checkHideToolbar() {
    let hideListUrls = [];

    hideListUrls.push('/OKRs/report/myOKRs');
    hideListUrls.push('/MyWallet/exchange-tokens');
    hideListUrls.push('/MyWallet/my-coupons');
    hideListUrls.push('/MyWallet/patpoints');
    hideListUrls.push('/PartecipantLTI/recipent');
    hideListUrls.push('/Personal-Growth/employee-personal-growth/praise');
    hideListUrls.push('/User/opinion');

    this.hideToolbar = this.isMobile && hideListUrls.includes(this.router.url);
  }

  getUserInfoObserver = {
    next: (userInfo: any) => {
      var currentUserInfo = this.coreService.getUserInfo();
      this.somethingChanged = false;
      this.properties = [];
      this.changeProperty(currentUserInfo, userInfo, 'availableTokens');
      this.changeProperty(currentUserInfo, userInfo, 'canAddNews');
      this.changeProperty(currentUserInfo, userInfo, 'earnedTokens');
      this.changeProperty(currentUserInfo, userInfo, 'evaluateFeedbackAuthor');
      this.changeProperty(currentUserInfo, userInfo, 'fullName');
      this.changeProperty(currentUserInfo, userInfo, 'hasBossEvaluation');
      this.changeProperty(currentUserInfo, userInfo, 'hasEvaluationFeed');
      this.changeProperty(currentUserInfo, userInfo, 'hasPatFeed');
      this.changeProperty(currentUserInfo, userInfo, 'hasSkills');
      this.changeProperty(currentUserInfo, userInfo, 'hasBehaviors');
      this.changeProperty(currentUserInfo, userInfo, 'hasPatPrize');
      this.changeProperty(currentUserInfo, userInfo, 'hasObjectives');
      this.changeProperty(currentUserInfo, userInfo, 'hasSelfEvaluations');
      this.changeProperty(currentUserInfo, userInfo, 'hasObjectiveCycles');
      this.changeProperty(currentUserInfo, userInfo, 'imageUrl');
      this.changeProperty(currentUserInfo, userInfo, 'isEvaluator');
      this.changeProperty(currentUserInfo, userInfo, 'isManager');
      this.changeProperty(currentUserInfo, userInfo, 'jobRole');
      this.changeProperty(currentUserInfo, userInfo, 'language');
      this.changeProperty(currentUserInfo, userInfo, 'defaultlanguage');
      this.changeProperty(currentUserInfo, userInfo, 'belongingCompanyLogo');
      this.changeProperty(currentUserInfo, userInfo, 'belongingCompanyStyle');
      this.changeProperty(currentUserInfo, userInfo, 'belongingCompanyName');
      this.changeProperty(currentUserInfo, userInfo, 'roles');
      this.changeProperty(currentUserInfo, userInfo, 'receiveEvaluationFeed');
      this.changeProperty(currentUserInfo, userInfo, 'receivePatFeed');
      this.changeProperty(currentUserInfo, userInfo, 'hasTokenFeed');
      this.changeProperty(currentUserInfo, userInfo, 'receiveTokenFeed');
      this.changeProperty(currentUserInfo, userInfo, 'hasAnonymous');
      this.changeProperty(currentUserInfo, userInfo, 'hasTokenExchangers');

      this.changeProperty(
        currentUserInfo,
        userInfo,
        'hasSkillAdvancedSettings'
      );
      this.changeProperty(currentUserInfo, userInfo, 'hasScalesCustomization');
      this.changeProperty(currentUserInfo, userInfo, 'hasMultilanguage');
      this.changeProperty(currentUserInfo, userInfo, 'hasColorCustomizations');
      this.changeProperty(currentUserInfo, userInfo, 'hasLTI');

      this.changeProperty(currentUserInfo, userInfo, 'hasSurveys');
      this.changeProperty(currentUserInfo, userInfo, 'hasONA');
      this.changeProperty(currentUserInfo, userInfo, 'hasPerformanceReview');
      this.changeProperty(currentUserInfo, userInfo, 'hasTableauItegration');

      // if(window['cordova']){
      //   window['cordova'].plugins['notification'].badge.set(userInfo.NewNotifications);
      // }
      if (
        userInfo.NewNotifications > 0 ||
        userInfo.NewNotifications != currentUserInfo['StoredNotifications']
      ) {
        this.coreService.hasNewNotification();
        currentUserInfo['StoredNotifications'] = userInfo.NewNotifications;
        this.somethingChanged = true;
      }

      if (
        !currentUserInfo['EvaluationScale'] ||
        JSON.stringify(currentUserInfo['EvaluationScale']) !=
          JSON.stringify(userInfo['EvaluationScale'])
      ) {
        //WALL Is Changed
        currentUserInfo['EvaluationScale'] = userInfo['EvaluationScale'];
        this.coreService.setUserInfo(currentUserInfo);
        this.coreService.changedProperty('EvaluationScale');
      }

      if (
        !currentUserInfo['userCycles'] ||
        JSON.stringify(currentUserInfo['userCycles']) !=
          JSON.stringify(userInfo['userCycles'])
      ) {
        //WALL Is Changed
        currentUserInfo['userCycles'] = userInfo['userCycles'];
        this.coreService.setUserInfo(currentUserInfo);
        this.coreService.changedProperty('userCycles');
      }
      if (
        !currentUserInfo['viewCycles'] ||
        JSON.stringify(currentUserInfo['viewCycles']) !=
          JSON.stringify(userInfo['viewCycles'])
      ) {
        //WALL Is Changed
        currentUserInfo['viewCycles'] = userInfo['viewCycles'];
        this.coreService.setUserInfo(currentUserInfo);
        this.coreService.changedProperty('viewCycles');
      }

      if (
        !currentUserInfo['wallDate'] ||
        currentUserInfo['wallDate'] != userInfo['wallDate']
      ) {
        //WALL Is Changed
        currentUserInfo['wallDate'] = userInfo['wallDate'];
        this.coreService.setUserInfo(currentUserInfo);
        this.coreService.onWallChanged();
      }

      if (this.somethingChanged) {
        this.coreService.setUserInfo(currentUserInfo);
        this.coreService.changedProperty(this.properties);
      }
      if (currentUserInfo) {
        if (currentUserInfo.PreferredRole) {
          if (currentUserInfo.PreferredRole === 'Manager') {
            if (!currentUserInfo.isManager) {
              this.router.navigate(['choose-login']);
            }
          } else {
            const role =
              currentUserInfo.roles.indexOf(currentUserInfo.PreferredRole) !==
              -1;
            if (!role) {
              this.router.navigate(['choose-login']);
            }
          }
        }
      }
      this.changeTheme(userInfo.Theme);
    },
  };

  async changeTheme(theme) {
    if (theme) {
      document.documentElement.style.setProperty('--white', '#' + theme.RGB1);
      document.documentElement.style.setProperty(
        '--feed-background',
        '#' + theme.RGB2
      );
      document.documentElement.style.setProperty(
        '--login-textfield-color',
        '#' + theme.RGB3
      );
      document.documentElement.style.setProperty(
        '--login-textfield-border',
        '#' + theme.RGB4
      );
      document.documentElement.style.setProperty(
        '--login-text-color',
        '#' + theme.RGB5
      );
      document.documentElement.style.setProperty(
        '--login-button',
        '#' + theme.RGB6
      );
      document.documentElement.style.setProperty(
        '--login-text-error',
        '#' + theme.RGB7
      );
      document.documentElement.style.setProperty(
        '--login-button-hover',
        '#' + theme.RGB8
      );
      document.documentElement.style.setProperty(
        '--okr-green',
        '#' + theme.RGB9
      );
    }
    if (theme?.VariationCode === 'F' || theme?.VariationCode === 'D') {
      document.documentElement.style.setProperty('--shadow-color', '#ffffff80');
      document.body.classList.add('flipColor');
      this.userDetails = await this.coreService.getUserInfo();
      await this.getUpdatedUserInfo();
    } else {
      document.body.classList.remove('flipColor');
      document.documentElement.style.setProperty('--shadow-color', '#00000080');
      this.userDetails = await this.coreService.getUserInfo();
      await this.getUpdatedUserInfo();
    }
  }

  changeProperty(currentItem, newItem, property) {
    if (currentItem[property] != newItem[property]) {
      currentItem[property] = newItem[property];
      this.somethingChanged = true;
      this.properties.push(property);
    }
  }

  getUpdatedUserInfo() {
    return this.restServ
      .get(`Account/UserInfo`)
      .subscribe(this.getUserInfoObserver);
  }

  async onCordovaReady() {
    if (window['cordova']) {
      console.log('onCordovaReady', window);

      // Avviso il biometricService che da li in poi può funzionare.
      const biometric = await this.biometricService.biometricIsAvailable();
      this.biometricService.isAvailable =
        biometric.indexOf('face') >= 0 ||
        biometric.indexOf('finger') >= 0 ||
        biometric.indexOf('biometric') >= 0;

      // if (window['cordova'].platformId == 'android') {
      //   ;
      // } else {
      //   window['Keyboard'].hideFormAccessoryBar(true);
      // }

      // Fa apparire il [Done] sulla tastiera iOS
      // this.keyboard.hideFormAccessoryBar(false);
      // Gestione del resize dell'app quando appare la tastiera (SOLO PER iOS)
      // this.keyboard.setResizeMode(KeyboardResizeMode.Native);

      // window['cordova'].plugins['backgroundMode'].enable();

      this.diagnostic
        .isCameraAvailable()
        .then((callback) => {
          console.log('isCameraAvailable', callback);
          if (window['cordova'].platformId !== 'android') {
            //getCameraRollAuthorizationStatus solo per iOS
            this.diagnostic
              .getCameraRollAuthorizationStatus()
              .then((status) => {
                switch (status) {
                  case this.diagnostic.permissionStatus.NOT_REQUESTED:
                    console.log('Permission not requested');
                    break;
                  case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                    console.log('Permission denied');
                    break;
                  case this.diagnostic.permissionStatus.GRANTED:
                    console.log('Permission granted');
                    break;
                }
              })
              .catch((error) => {
                console.error('The following error occurred: ' + error);
              });
          }
        })
        .catch((errorCallback) => {
          console.log('isCameraAvailable ERROR=>', errorCallback);
        });

      // this.startBluetoothPeripheralAdvertisement();
      // interval(5  60  1000)             //5 minutes
      //   .subscribe(() => this.startBluetoothPeripheralAdvertisement());

      // interval(2  60  1000)             //2 minutes
      //   .subscribe(() => this.scan());
    }
  }
  /**************************************************************************************************************/
  /***************************            Bluetooth Central                                                  **/
  /*************************************************************************************************************/
  devices: any[] = [];

  /**
   * Scans for discover near devices
   */
  // scan() {

  //   ble.scan([], 30,
  //     (result: any) => this.onDiscoverDevice(result),
  //     (err: any) => this.onError(err)
  //   );

  // }

  /**
   * Method invoked each time a device is discovered
   * @param result
   */
  // onDiscoverDevice(result) {

  //   const advData = this.parseAdvertisingData(result.advertising)
  //   result.decodedAdvertising = advData;
  //   console.log('FOUND DEVICE:' + JSON.stringify(result));
  //   if (advData['0x07']) {
  //     let emp = this.getPatPatEmployee(advData['0x07'][0]);
  //     if (emp) {
  //       this.addNearbyEmployee(emp, result.rssi);
  //     }

  //   }

  // }

  // addNearbyEmployee(emp, ssi) {

  //   console.log("Nearby Employee: " + JSON.stringify(emp));
  //   let nearbyStr = localStorage.getItem('nearBy');
  //   let nearby: any[];
  //   if (!nearbyStr) {
  //     nearby = [];
  //     localStorage.setItem('nearBy', JSON.stringify(nearby));
  //   } else {
  //     nearby = JSON.parse(nearbyStr);
  //   }

  //   let foundEmp = nearby.find(x => x.Id === emp.Id);
  //   if (foundEmp) {
  //     foundEmp.Time = Date.now();
  //     foundEmp.Picture = emp.Picture;
  //     foundEmp.TeamId = emp.TeamId;
  //     foundEmp.Team = emp.Team;
  //     foundEmp.ssi = ssi;
  //   } else {
  //     emp.Time = Date.now();
  //     emp.ssi = ssi;
  //     nearby.push(emp);
  //   }
  //   localStorage.setItem('nearBy', JSON.stringify(nearby));

  // }

  // getPatPatEmployee(discoveredId) {

  //   var userListStr = localStorage.getItem('userList');
  //   if (userListStr) {
  //     let userList = JSON.parse(userListStr);
  //     let employee = userList.find(x => x.Id.replace(/-/g, '').toLowerCase() === discoveredId.toLowerCase());
  //     return employee
  //   }
  //   else {
  //     this.restServ.get(`Account/CompanyEmployees`).subscribe(
  //       (result: any) => {
  //         localStorage.setItem('userList', JSON.stringify(result));
  //       }
  //     );
  //   }
  //   return undefined;
  // }

  // parseAdvertisingData(buffer) {

  //   let length, type, data, i = 0, advertisementData = {};
  //   let bytes = new Uint8Array(buffer);

  //   while (length !== 0) {

  //     length = bytes[i] & 0xFF;
  //     i++;

  //     // decode type constants from https://www.bluetooth.org/en-us/specification/assigned-numbers/generic-access-profile
  //     type = bytes[i] & 0xFF;
  //     i++;

  //     data = bytes.slice(i, i + length - 1).buffer; // length includes type byte, but not length byte
  //     i += length - 2;  // move to end of data
  //     i++;
  //     let x: any;
  //     switch (type) {
  //       case this.ble.AdvertisementType.Flags:
  //         x = this.ble.toStringArray(data);
  //         break;
  //       case this.ble.AdvertisementType.Incomplete_List_16_bit_Services:
  //       case this.ble.AdvertisementType.List_16_bit_Services:
  //       case this.ble.AdvertisementType.Slave_Connection_Interval_Range:
  //       case this.ble.AdvertisementType.List_of_16_bit_Service_Solicitation_UUIDs:
  //       case this.ble.AdvertisementType.Advertising_Interval:
  //         x = this.ble.toOctetStringArray(2, data, undefined, 'BE');

  //         break;
  //       case this.ble.AdvertisementType.Incomplete_List_32_bit_Services:
  //       case this.ble.AdvertisementType.List_32_bit_Services:
  //       case this.ble.AdvertisementType.List_of_32_bit_Service_Solicitation_UUIDs:
  //       case this.ble.AdvertisementType.Service_Data_32_bit_UUID:
  //         x = this.ble.toOctetStringArray(4, data, undefined, 'BE');
  //         break;
  //       case this.ble.AdvertisementType.Incomplete_List_128_bit_Services:
  //       case this.ble.AdvertisementType.List_128_bit_Services:
  //       case this.ble.AdvertisementType.List_of_128_bit_Service_Solicitation_UUIDs:
  //       case this.ble.AdvertisementType.Simple_Pairing_Hash_C_256:
  //       case this.ble.AdvertisementType.Simple_Pairing_Randomizer_R_256:
  //       case this.ble.AdvertisementType.Service_Data_128_bit_UUID:
  //         x = this.ble.toOctetStringArray(16, data, undefined, 'BE');
  //         break;
  //       case this.ble.AdvertisementType.Local_Name:
  //       case this.ble.AdvertisementType.Short_Local_Name:
  //       case this.ble.AdvertisementType.URI:
  //         x = this.ble.toString(data);
  //         break;
  //       case this.ble.AdvertisementType.TX_Power_level:
  //         x = this.ble.toSignedInt(data);
  //         break;
  //       case this.ble.AdvertisementType.Class_of_Device:
  //         x = this.ble.toOctetString(3, data, undefined, 'BE');
  //         break;
  //       case this.ble.AdvertisementType.Simple_Pairing_Hash_C:
  //       case this.ble.AdvertisementType.Simple_Pairing_Randomizer_R:
  //       case this.ble.AdvertisementType.Device_ID:
  //       case this.ble.AdvertisementType.Security_Manager_Out_of_Band_Flags:
  //         x = this.ble.toOctetString(16, data, undefined, 'BE');
  //         break;
  //       case this.ble.AdvertisementType.Service_Data:
  //         x = this.ble.toOctetString(1, data, undefined, 'BE');
  //         break;
  //       case this.ble.AdvertisementType.Public_Target_Address:
  //       case this.ble.AdvertisementType.Random_Target_Address:
  //       case this.ble.AdvertisementType.LE_Bluetooth_Device_Address:
  //         x = this.ble.toOctetString(6, data, undefined, 'BE');
  //         break;

  //       default:
  //         x = this.ble.bufferToHexString(data);
  //         break;

  //     }

  //     advertisementData[this.ble.asHexString(type)] = x;

  //   }

  //   return advertisementData;

  // }
  /**************************************************************************************************************/
  /***************************            Bluetooth Peripheral                                               **/
  /*************************************************************************************************************/

  // onError(data) {
  //   console.log('Error ' + JSON.stringify(data));
  // }
  // startBluetoothPeripheralAdvertisement() {

  //   //Get User ID
  //   var currentUserInfo = this.coreService.getUserInfo();
  //   if (currentUserInfo) {
  //     const id = currentUserInfo.linkedEmployeeId;

  //     Promise.all([
  //       // blePeripheral.createService(this.SERVICE_UUID),
  //       // blePeripheral.addCharacteristic(SERVICE_UUID, TX_UUID, property.WRITE, permission.WRITEABLE),

  //       // blePeripheral.publishService(this.SERVICE_UUID),
  //       blePeripheral.startAdvertising(id, 'PatPat')
  //     ]).then(
  //       success => function () { console.log('Created PatPat Service'); },
  //       (err: any) => this.onError(err)
  //     );
  //   }

  // }

  /*************************************************************************************************************/
}
