import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
//RxJS
import { Subject } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from '../../services/shared.service';
import { IosAutocompleteComponent } from '../ios-autocomplete/ios-autocomplete.component';
import { TranslateService } from '@ngx-translate/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-give-token',
  templateUrl: './give-token.component.html',
  styleUrls: [
    './give-token.component.scss',
    '../../../common_styles.scss',
    '../common_popup_styles.scss',
    '../../../magic-checkbox.scss',
  ],
  providers: [
    Keyboard,
    {
      provide: BsDropdownConfig,
      useValue: { autoClose: true },
    },
  ],
})
export class GiveTokenComponent implements OnInit {
  public onClose: Subject<any>;
  iOS: boolean =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  employeesList: any;
  isAfterContentChecked: boolean = false;
  isSkillProcessing: boolean = true;
  isGoalProcessing: boolean = true;
  isBehaviorsProcessing: boolean = true;
  isActivityProcessing: boolean = true;
  isKeyResult: boolean = false;
  user_info: any;
  currentEmployeeId: any;
  skills: any;
  behaviors: any;
  goals: any;
  tokenActivities: any;
  globalTokenActivities: any;
  selectedSkill: any;
  selectedBehavior: any;
  selectedGoal: any;
  selectedActivity: any;
  selectedActivityId: string;
  totalAvailableToken: any;
  givenToken: any;
  totalEarnToken: any;
  availableToken: any;
  selectedEmployee: any;
  isPreselected: boolean = false;
  isSelectedSkills: boolean = false;
  isSelectedBehaviors: boolean = false;
  isSelectedGoals: boolean = false;
  @Output() addNewToken = new EventEmitter();
  @Output() updateToken = new EventEmitter();
  isAnonymous: boolean = false;
  isValid: boolean = false;
  isTokenFocused: boolean = false;
  showMandatory = false;
  isSubmitToken: boolean = false;
  targetEmployee: any;
  feedback: any = '';
  tokenError: string = '';
  settingsEmployees = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
  };
  dropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
  };
  radioType: string = '';
  selectedItemIOS: any[] = [];
  iosModalRef: any;
  searchText: string;
  serachTextChanged: Subject<string> = new Subject<string>();
  pageStart = 1;
  pageSize = 50;
  currentList = [];
  allFilteredDataList: any[] = [];
  @Output() getResponse = new EventEmitter();
  pat: any;
  commentEdited = false;
  disableEmployeeDropDown = false;

  constructor(
    public sharedService: SharedService,
    public coreService: CoreService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private ref: ChangeDetectorRef,
    private keyboard: Keyboard,
    private translate: TranslateService
  ) {
    this.givenToken = 0;
  }

  ngOnInit() {
    this.pat = {
      patType: 'skill',
      Skill: undefined,
      Goal: undefined,
      Activity: undefined,
    };
    this.getUserInfo();
    this.onClose = new Subject();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  getUserInfo() {
    this.user_info = this.coreService.getUserInfo();
    this.totalAvailableToken = this.user_info.availableTokens;
    this.currentEmployeeId = this.user_info.linkedEmployeeId;
  }

  ngAfterContentChecked() {
    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      const emp = JSON.parse(
        JSON.stringify(this.bsModalRef['content'].employeesList)
      );
      this.employeesList = emp;
      this.employeesList = this.employeesList.filter((x) => x.ReceiveToken);
      this.employeesList.forEach(async (item: any) => {
        item['Id'] = item.Id.replace('e:', '');
        if (item.isPreselect === true) {
          if (item.FirstName) {
            item['Name'] = item.SecondName + ' ' + item.FirstName;
          }
          if (!this.disableEmployeeDropDown) {
            this.isPreselected = true;
          }
          this.selectedEmployee = item;
          this.isSelectedSkills = true;
          await this.getTokenSkills(item.Id);
          await this.getTokenGoals(item.Id);
          await this.getTokenBehaviors(item.Id);
          await this.getTokenActivities(item.Id);
        }
        if (item.Type == 'Employee') {
          this.targetEmployee = item;
        }
        if (item.Type == 'GivenEvaluation' || item.Type == 'GivenToken') {
          this.targetEmployee = item;
          if (!this.disableEmployeeDropDown) {
            this.isPreselected = true;
          }
          this.isSelectedSkills = true;
          await this.getTokenSkills(item.Target.Id);
          await this.getTokenGoals(item.Target.Id);
          await this.getTokenBehaviors(item.Id);
          await this.getTokenActivities(item.Target.Id);
        }

        if (item.Type == 'ReceivedEvaluation' || item.Type == 'ReceivedToken') {
          this.targetEmployee = item;
          this.isPreselected = true;
          this.isSelectedSkills = true;
          await this.getTokenSkills(item.Author.Id);
          await this.getTokenGoals(item.Author.Id);
          await this.getTokenBehaviors(item.Id);
          await this.getTokenActivities(item.Author.Id);
        }
      });
      this.isAfterContentChecked = true;
    }
    // this.setValue();
  }

  ngAfterContentInit() {
    this.refreshRadioSelection();
  }

  refreshRadioSelection() {
    if (this.user_info.hasSkills) {
      this.radioType = 'skill';
      this.isKeyResult = false;
      this.tokenActivities = this.globalTokenActivities;
    } else if (this.user_info.hasObjectives) {
      this.radioType = 'goal';
      this.isKeyResult = false;
      this.tokenActivities = [];
      this.selectedActivity = [];
      this.selectedActivityId = undefined;
    } else if (this.user_info.hasBehaviors) {
      this.radioType = 'behavior';
      this.isKeyResult = false;
      this.tokenActivities = this.globalTokenActivities;
    }
  }

  onSelectEmployeeChange(event) {
    if (!event) return;
    this.getTokenSkills(event.Id);
    this.getTokenGoals(event.Id);
    this.getTokenBehaviors(event.Id);
    this.getTokenActivities(event.Id);
  }

  searchEmployeeText: string;
  switchTypeAhead() {
    this.selectedEmployee = undefined;
    this.searchEmployeeText = '';
    setTimeout(() => {
      $('#gtEmployeeList').trigger('click');
    }, 100);
  }

  selectItem(event: any) {
    this.onSelectEmployeeChange(event.item);
  }

  async getTokenSkills(employeeId) {
    this.isSkillProcessing = true;
    const data = await this.sharedService
      .getTokenSkills(this.currentEmployeeId, employeeId)
      .toPromise();
    this.isSkillProcessing = false;
    this.skills = data;
    this.skills.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
    // this.skills.sort((a, b) => {
    //   return !a.IsFromCycle ? -1 : 0;
    // });
  }

  async getTokenBehaviors(employeeId) {
    this.isBehaviorsProcessing = true;
    const data = await this.sharedService
      .getTokenBehaviors(this.currentEmployeeId, employeeId)
      .toPromise();
    this.isBehaviorsProcessing = false;
    this.behaviors = data;
    this.behaviors.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
  }

  async getTokenGoals(employeeId) {
    this.isGoalProcessing = true;
    const data = await this.sharedService
      .getTokenGoals(this.currentEmployeeId, employeeId)
      .toPromise();
    this.isGoalProcessing = false;
    this.goals = data;
    this.goals.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
  }
  async getTokenActivities(employeeId) {
    this.isActivityProcessing = true;
    const data = await this.sharedService
      .getTokenActivities(employeeId)
      .toPromise();
    this.isActivityProcessing = false;
    this.tokenActivities = data;
    this.tokenActivities.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
    // this.tokenActivities.sort((a, b) => {
    //   return !a.IsFromCycle ? -1 : 0;
    // });
    this.globalTokenActivities = data;
  }

  onChange(event) {
    if (!Number.isInteger(event)) {
      this.isValid = false;
      return;
    }

    if (event < 1) {
      this.isValid = false;
      return;
    }
    if (event > this.totalAvailableToken) {
      this.isValid = false;

      return;
    }
    this.isValid = true;
    // this.user_info.availableTokens = this.totalAvailableToken - event;
  }

  value(val) {
    this.isAnonymous = val.target.checked;
  }

  onGoalChange(e) {
    var foundGoal = this.goals.find((x) => x.Id == e.Id);
    if (foundGoal && foundGoal.IsFromCycle) {
      this.isKeyResult = true;
      this.tokenActivities = [];
      if (foundGoal.KeyResults) {
        foundGoal.KeyResults.forEach((element: any) => {
          this.tokenActivities.push({
            Id: element.Item1,
            Name: element.Item2,
          });
          this.tokenActivities.sort((a: any, b: any) => {
            return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
              ? -1
              : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
                ? 1
                : 0;
          });
        });
      }
    } else {
      this.tokenActivities = this.globalTokenActivities;
      this.isKeyResult = false;
    }
  }

  postAToken(reqBody) {
    if (reqBody.Id) {
      this.sharedService.updateTokenFeed(reqBody).subscribe(
        (data: any) => {
          this.updateToken.emit(data);
          // this.coreService.toasterMessage(
          //   'success',
          //   'company',
          //   this.coreService.getTranslation("Notifications_MSG.Updated")
          // );
          this.coreService.setShowPublished({ type: 'Token', isEdit: true });

          if (this.employeesList) {
            this.employeesList.forEach((e) => {
              e['selected'] = false;
            });
          }
          // this.refreshWallRequired.next(this.newPatData);
          this.sendingConfirmation = false;
        },

        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
          this.sendingConfirmation = false;
        }
      );
    } else {
      this.sharedService.postAToken(reqBody).subscribe(
        async (data: any) => {
          await this.coreService.SetAuthToken(this.user_info);
          this.addNewToken.emit(data);

          // this.coreService.toasterMessage(
          //   'success',
          //   'wall',
          //   this.coreService.getTranslation("Notifications_MSG.Sent")
          // );
          this.coreService.setShowPublished({ type: 'Token', isEdit: false });
          this.sendingConfirmation = false;
          // this.bsModalRef.hide();
        },
        (err: any) => {
          this.sendingConfirmation = false;
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
    }
    this.onClose.next(reqBody);

    this.bsModalRef.hide();
  }

  onFocusFilterEmployees() {
    setTimeout(() => {
      $('typeahead-container.dropdown.open').css(
        'width',
        $('#employee_selector').outerWidth() + 'px'
      );
      $('typeahead-container ul.dropdown-menu').css(
        'width',
        $('#employee_selector').outerWidth() + 'px'
      );
    }, 1);
  }

  isDisabled() {
    return (
      (!this.selectedSkill && !this.selectedGoal && !this.selectedBehavior) ||
      !this.selectedEmployee
    );
  }

  iosAutocompleteDialog() {
    if (this.isPreselected) return;
    this.iosModalRef = this.modalService.show(IosAutocompleteComponent, {
      class: 'iosAutocompleteDialog',
      ignoreBackdropClick: true,
    });
    this.iosModalRef.content.setData(this.employeesList);
    this.iosModalRef.content.selectionMultiple = false;
    this.iosModalRef.content.selectionViewType = 'EMPLOYEES_TEAMS';
    this.iosModalRef.content.title = this.translate.instant(
      'Generic.ChooseEmployee'
    );
    this.iosModalRef.content.getResponse.subscribe((data: any) => {
      //this.selectedItemIOS = data.selected;
      this.selectedEmployee = data.selected[0];
      this.employeesList = data.originalList;
      this.onSelectEmployeeChange(data.selected[0]);
    });
  }

  sendingConfirmation: boolean = false;
  onConfirm() {
    if (
      this.isSubmitToken ||
      !this.selectedEmployee ||
      this.givenToken == 0 ||
      (this.radioType == 'skill' && !this.selectedSkill) ||
      (this.radioType == 'behavior' && !this.selectedBehavior) ||
      (this.radioType == 'goal' && !this.selectedGoal)
    )
      return;

    if (this.sendingConfirmation) return;

    this.sendingConfirmation = true;

    this.isSubmitToken = true;
    if (this.feedback == undefined) {
      this.feedback = '';
    }
    let reqBody = {};
    let targetEmployeeId: any;
    let activityId = this.selectedActivityId;
    if (this.pat.Id) {
      this.pat.Description = this.feedback;
      reqBody = this.pat;
    } else {
      targetEmployeeId = this.selectedEmployee.Id;
      if (
        this.selectedSkillId ||
        this.selectedBehaviorId ||
        this.selectedGoalId
      ) {
        reqBody = {
          Author: {
            Id: this.currentEmployeeId,
          },
          Target: {
            Id: targetEmployeeId,
          },
          Description: this.feedback,
          IsAnonymous: this.isAnonymous,
          Amount: Number.parseInt(this.givenToken),
        };
        if (this.radioType === 'skill') {
          reqBody['Skill'] = {
            Id: this.selectedSkillId,
          };
        } else if (this.radioType === 'goal') {
          var foundGoal = this.goals.find((x) => x.Id == this.selectedGoalId);

          reqBody['Goal'] = {
            Id: this.selectedGoalId,
            IsFromCycle: foundGoal.IsFromCycle,
          };
        } else if (this.radioType === 'behavior') {
          reqBody['Skill'] = {
            Id: this.selectedBehaviorId,
          };
        }
      } else {
        reqBody = {
          Author: {
            Id: this.currentEmployeeId,
          },
          Target: {
            Id: targetEmployeeId,
          },
          Description: this.feedback,
          IsAnonymous: this.isAnonymous,
          Amount: Number.parseInt(this.givenToken),
        };
      }

      if (activityId && activityId.length > 0) {
        reqBody['Activity'] = {
          Id: activityId,
        };
      }
      this.employeesList.forEach((element: any) => {
        element.selected = false;
      });
    }
    this.postAToken(reqBody);
  }

  subToken() {
    this.givenToken == 0 ? (this.givenToken = 0) : this.givenToken--;
    this.setValue();
    this.changeAmount();
  }

  addToken() {
    this.givenToken++;
    this.setValue();
    this.changeAmount();
  }

  selectedSkillId: string;
  onSelectSkill(event: any) {
    if (event) {
      this.selectedSkillId = event.Id;
      this.selectedSkill = event.Name;
    }
  }

  selectedBehaviorId: string;
  onSelectBehavior(event: any) {
    this.selectedBehaviorId = event.Id;
    this.selectedBehavior = event.Name;
  }

  selectedGoalId: string;
  onSelectGoal(event: any) {
    this.selectedGoalId = event.Id;
    this.selectedGoal = event.Name;
    this.onGoalChange(event);
  }

  onSelectActivity(data: any) {
    this.selectedActivityId = data.Id;
  }

  onSelectedEmployee(data: any) {
    this.selectedEmployee = data.selected[0];
    this.employeesList = data.originalList;
    this.onSelectEmployeeChange(data.selected[0]);
  }
  setValue(isClosed?: boolean) {
    this.tokenError = '';
    this.showMandatory = false;
    if (this.givenToken) {
      if (this.givenToken === '') {
        this.showMandatory = false;
      }
      const token = parseInt(this.givenToken);
      if (Number(this.givenToken) === 0) {
        this.showMandatory = true;
        this.tokenError = this.coreService.getTranslation(
          'PleaseChooseQuantityDifferent'
        );
      } else if (this.totalAvailableToken < token) {
        this.showMandatory = true;
        this.tokenError = this.coreService.getTranslation(
          'AmountExceedsBudget'
        );
      }
    }
    if (isClosed) {
      this.showMandatory = false;
    }
  }
  selectSkillItem(data: any) {
    if (!this.selectedSkill) this.selectedSkill = {};
    this.selectedSkillId = data.Id;
    this.selectedSkill = data.Name;
    this.keyboard.hide();
  }

  public async showModal(content: any) {
    this.pat = content.pat;
    this.employeesList = content.all_Employees_Teams;
    this.givenToken = this.pat.Amount;
    this.feedback = this.pat.Description;
    this.radioType = this.pat.patType;

    this.selectedItemIOS = [];

    const preselected = this.employeesList.filter((i) => i.isPreselect);

    preselected.forEach((item) => {
      this.isPreselected = true;
      this.selectedItemIOS.push(item);
    });

    switch (this.pat.patType) {
      case 'skill':
        this.selectedSkill = this.pat.Skill.Name;
        this.selectedSkillId = this.pat.Skill.Id;
        break;
      case 'behavior':
        this.selectedBehavior = this.pat.Skill.Name;
        this.selectedBehaviorId = this.pat.Skill.Id;
        break;
      case 'goal':
        this.selectedGoal = this.pat.Goal.Name;
        this.selectedGoalId = this.pat.Goal.Id;

        break;
    }
    this.selectedActivity = this.pat.Activity
      ? this.pat.Activity.Name
      : undefined;
    this.selectedActivityId = this.pat.Activity?.Id;
  }
  cancelChanges() {
    if (!this.selectedEmployee || (this.isPreselected && !this.commentEdited)) {
      this.bsModalRef.hide();
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DISCARD'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DISCARD_CHANGES'),
        subtitle: this.coreService.getTranslation('WALL.DISCARD_CHANGES_TITLE'),
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation',
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.bsModalRef.hide();
          }
        }
      );
    }
  }

  getDisabled() {
    return (
      (this.radioType === 'skill' && !this.selectedSkill) ||
      (this.radioType === 'behavior' && !this.selectedBehavior) ||
      (this.radioType === 'goal' && !this.selectedGoal)
    );
  }

  setTokenValue(e) {
    this.givenToken = e;
    this.changeAmount();
  }

  changeAmount() {
    if (Number(this.givenToken) === 0) {
      console.log('change amount', Number(this.givenToken));
      this.showMandatory = true;
      this.isPreselected = false;
      if (!this.isTokenFocused) {
        this.tokenError = this.coreService.getTranslation(
          'PleaseChooseQuantityDifferent'
        );
      }
    } else {
      this.showMandatory = false;
      const token = parseInt(this.givenToken);
      if (this.totalAvailableToken < token) {
        this.showMandatory = true;
        this.tokenError = this.coreService.getTranslation(
          'AmountExceedsBudget'
        );
      }
    }
  }
}
