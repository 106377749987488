import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeTeamPopUpComponent } from 'app/shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { WallService } from '../wall.service';
import { InappropriateDialogComponent } from './../inappropriate-dialog/inappropriate-dialog.component';
import { EvaluationComponent } from 'app/shared/components/evaluation/evaluation.component';
import { SharedService } from 'app/shared/services/shared.service';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { ChoicePopUpComponent } from 'app/shared/components/choice-pop-up/choice-pop-up.component';

@Component({
  selector: 'feed-ask',
  templateUrl: './feed-ask.component.html',
  styleUrls: ['./feed-ask.component.scss', '../common-feed-style.scss'],
})
export class FeedAskComponent implements OnInit {
  @Input() showReported: boolean;
  @Input() item: any;
  @Input() selectData: any;
  @Input() readOnly: boolean;
  @Output() reloadRequired = new EventEmitter();
  @Output() reportedAsInappropriate = new EventEmitter<any>();

  bsModalRef: BsModalRef;
  userInfo: any;
  expiringTime: any;
  expiryDate: any;
  isExpired: boolean;
  isAlert: boolean;
  empInfo: any;

  constructor(
    public coreService: CoreService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private route: Router,
    private service: WallService
  ) {
    this.userInfo = this.coreService.getUserInfo();
  }

  ngOnInit() {
    this.item.Date = this.sharedService.getLocalDate(this.item.Date);
    this.item.ExpiringDate = this.sharedService.getLocalDate(
      this.item.ExpiringDate
    );
    let expDate = moment(this.item.ExpiringDate);
    setInterval(
      () => {
        let now = moment();
        let duration = moment.duration(expDate.diff(now));
        let d = duration.days();
        let h = duration.hours();
        let m = duration.minutes();
        let s = duration.seconds();

        let msg = '';
        if (d) msg = d + ' d ';
        if (d || h) msg += h + ' h ';
        if (d || h || m) msg += m + ' m ';
        if (d || h || m || s) msg += s + ' s ';
        this.expiryDate = msg;
        this.isExpired = duration.asMilliseconds() <= 0;
        this.isAlert = d < 5;
      },

      1000
    );

    this.expiringTime = this.item.ExpiringTime.split(':');

    if (this.service.profiles.length) {
      const employee = this.service.profiles.find(
        (x) => x.Id === this.item.Author.Id
      );
      if (employee) {
        this.empInfo = employee;
      } else {
        this.getProfileInfo();
      }
    } else {
      this.getProfileInfo();
    }
  }

  getProfileInfo() {
    this.service.getEmployeeInfo(this.item.Author.Id).subscribe((data: any) => {
      if (data) {
        this.empInfo = data;
        const employee = this.service.profiles.find(
          (x) => x.Id === this.item.Author.Id
        );
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  showHideTranslated(item, property) {
    if (item.ShowTranslated) {
      item.ShowTranslated = !item.ShowTranslated;
    } else {
      let selectedLang = this.userInfo.language;
      const text = item[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          item['TranslatedText'] = result.TranslatedText;
          item.ShowTranslated = true;
        });
    }
  }
  shortcutForPat(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;
    this.bsModalRef = this.modalService.show(EmployeeTeamPopUpComponent, {
      class: 'popup-user',
    });
    this.bsModalRef.content.team = team;

    this.bsModalRef.content.addNewPat.subscribe((data: any) => {
      this.reloadRequired.emit();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  expandMenu(item) {
    item.expandMenu = !item.expandMenu;
  }

  reportInnapropriate(item) {
    item.expandMenu = false;
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    const content: any = {
      title: msg,
      type: 'Feedback Request',
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation',
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.Id,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 0,
          };

          switch (item.Type) {
            case 'ReceivedAsk':
              req.FeedType = 3;
              break;
            case 'ReceivedEvaluation':
              req.FeedType = 2;
              break;
            case 'ReceivedToken':
              req.FeedType = 1;
              break;
            case 'Pat':
              req.FeedType = 0;
              break;
            default:
              req.FeedType = 4;
              break;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }

  answerOnAsk(evaluate) {
    this.bsModalRef = this.modalService.show(ChoicePopUpComponent, {
      class: 'choicePopUp ammazza wallPopup',
      ignoreBackdropClick: false,
      initialState: { selectedFeedback: evaluate },
    });
    // this.bsModalRef.content.employees = this.evaluation_Employees;
    this.bsModalRef.content.postEval.subscribe((data: any) => {
      // this.refreshWall(data);
      this.bsModalRef.hide();
    });
  }

  getShortEmployeeName(employee) {
    var name =
      (employee.SecondName ? employee.SecondName : '') +
      ' ' +
      employee.FirstName;
    var len = 35;
    if (window.innerWidth < 350) {
      len = 21;
    }
    if (name.length > len) {
      name = employee.SecondName.charAt(0) + '.' + ' ' + employee.FirstName;
    }

    return name;
  }
  goToProfile(idofemployee) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: idofemployee },
      replaceUrl: true,
    });
  }
}
