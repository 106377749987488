import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WallService } from 'app/employee/wall/wall.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'view-feed-popup',
  templateUrl: './view-feed-popup.component.html',
  styleUrls: ['./view-feed-popup.component.scss'],
})
export class ViewFeedPopupComponent implements OnInit {
  selectedFeedback: any = [];
  type: any;
  @Input() patFeedData = [];
  public onClose: Subject<any>;

  employeeId: string;
  pageStart = 1;
  pageStartAboutMe = 1;
  pageSize = 10;
  userInfo: any;
  numberOfAsks = 0;
  feedbackRequests = [];
  wallFilters = {
    IsAboutMe: false,
    Items: null,
    Employees: null,
    Teams: null,
    Roles: null,
    Locations: null,
  };

  constructor(
    public service: WallService,
    public coreService: CoreService,
    public router: Router,
    public sharedService: SharedService,
    public pp360SpinnerService: Pp360SpinnerService,
    private _bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
    this.userInfo = this.coreService.getUserInfo();
    this.employeeId = this.userInfo.linkedEmployeeId;
    this.getPatFeedForWall();
  }
  getPatFeedForWall() {
    this.pp360SpinnerService.showSpinner(true);
    let request;
    if (this.wallFilters) {
      request = JSON.parse(JSON.stringify(this.wallFilters));
    }
    this.service
      .getPagedPatfeedForAboutWall(
        this.employeeId,
        this.pageStart,
        this.pageSize,
        request
      )
      .toPromise()
      .then((data: any) => {
        this.coreService.setEvents(data.Events);
        this.userInfo['wallDate'] = data.Date;
        this.coreService.setUserInfo(this.userInfo);
        this.pp360SpinnerService.showSpinner(false);
        this.patFeedData = data.WallFeeds.Rows;

        let currentFeedbacks = JSON.parse(
          JSON.stringify(
            this.patFeedData.filter((x) => x.Type === 'GivenEvaluation')
          )
        );
        // console.log(currentFeedbacks);
        this.selectedFeedback = currentFeedbacks[0];
      })
      .catch((err: any) => {
        this.pp360SpinnerService.showSpinner(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
      });
  }
  onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
}
