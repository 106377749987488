import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../shared/services/core.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss'],
})
export class HelpDialogComponent implements OnInit {
  content: any;
  currImage: string = 'rolling';

  constructor(
    private coreService: CoreService,
    public modalRef: BsModalRef
  ) {}

  ngOnInit() {}

  public showModal(content: any): void {
    this.content = content;
  }

  setImage(name: string) {
    this.currImage = name;
  }

  getHelpImage() {
    switch (this.currImage) {
      case 'plan':
        return this.coreService.getImageURL(
          '',
          'assets/images/admin-lti/endwithback.png'
        );
        break;
      default:
        return this.coreService.getImageURL(
          '',
          'assets/images/admin-lti/rolling.png'
        );
    }
  }
}
