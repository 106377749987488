import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent implements OnInit {
  public onClose: Subject<any>;
  public content: any = {};

  public constructor(private _bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public showModal(content: any): void {
    this.content = content;

    setTimeout(() => {
      $('.onConfirm').on('click', (event: any) => {
        if (!event.detail || event.detail == 1) {
          //activate on first click only to avoid hiding again on multiple clicks
          this.onConfirm();
        }
      });
    }, 1000);
  }

  private onConfirm(): void {
    this._bsModalRef.hide();
    this.onClose.next(true);
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.onClose.next(null);
    this._bsModalRef.hide();
  }
}
