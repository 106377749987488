import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthRouting } from './auth.routing';
import { FormsModule } from '@angular/forms';
import { AuthService } from './auth.service';
import { FirstLoginComponent } from './first-login/first-login.component';
import { SharedModule } from '../shared/shared.module';
import { SocialLoginComponent } from './social-login/social-login.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ChooseLoginComponent } from './choose-login/choose-login.component';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  ProtectedResourceScopes,
} from '@azure/msal-angular';

import {
  msalConfig,
  loginRequest,
  protectedResources,
} from '../../../src/app/auth/auth.config';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();

  protectedResourceMap.set(
    protectedResources.apiPatPatADAuthenticate.endpoint,
    [
      {
        httpMethod: 'GET',
        scopes: [...protectedResources.apiPatPatADAuthenticate.scopes.read],
      },
    ]
  );

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: loginRequest,
  };
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    AuthRouting,
    SharedModule,
    SlickCarouselModule,
    MsalModule,
    HttpClientModule,
    PopoverModule.forRoot(),
  ],
  declarations: [
    LoginComponent,
    FirstLoginComponent,
    SocialLoginComponent,
    ChooseLoginComponent,
  ],
  providers: [
    //{
    //    provide: HTTP_INTERCEPTORS,
    //    useClass: MsalInterceptor,
    //    multi: true
    //},
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthService,
  ],
})
export class AuthModule {}
