import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScreenSizeService {
  private ScreenSize$: BehaviorSubject<number> = new BehaviorSubject<number>(
    window.innerWidth
  );
  ScreenSize: Observable<number> = this.ScreenSize$.asObservable();

  constructor() {
    window.addEventListener('resize', () => {
      this.updateScreenSize(window.innerWidth);
    });
    this.updateScreenSize(window.innerWidth);
  }

  // Update the Screen Size Behavior Subject
  private updateScreenSize(ScreenSize: number): void {
    this.ScreenSize$.next(ScreenSize);
  }
}
