import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { Subject } from 'rxjs';

@Component({
  selector: 'custom-image-cropper',
  templateUrl: './custom-image-cropper.component.html',
  styleUrls: ['./custom-image-cropper.component.scss'],
})
export class CustomImageCropperComponent implements OnInit {
  public onClose: Subject<any>;
  file: File;
  canvasRotation = 0;
  transform: ImageTransform = {};
  croppedImage: any = '';
  scale = 1;
  roundCropper = true;
  isCrop: boolean = false;
  aspectRatio = 4 / 4;
  isMaintainAspectRatio = false;
  aspectRatios = [
    { label: 'original', value: 2 },
    { label: '1:1', value: 1 },
    { label: '4:5', value: 4 / 5 },
    { label: '16:9', value: 16 / 9 },
    // Add more aspect ratios as needed
  ];
  // selectedRatio: string = 'original';
  originalRatio = this.aspectRatios[0];

  //slider
  isShowSlider: boolean = false;
  showCrop = true;
  sliderValue: number = 1; // Initial value of the slider

  constructor(private _bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  public showModal(content: {
    file: File;
    isRounded: boolean;
    aspectRatio?: any;
  }): void {
    this.file = content.file;
    this.roundCropper = content.isRounded;
    if (content.aspectRatio) {
      // this.containWithinAspectRatio = true;
      this.aspectRatio = content.aspectRatio;
      this.originalRatio.value = content.aspectRatio;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  zoomIn() {
    this.isCrop = false;
    this.isShowSlider = !this.isShowSlider;
  }

  flipImage() {
    this.isCrop = false;
    this.isShowSlider = false;
    this.isMaintainAspectRatio = true;
    if (this.aspectRatio === 4 / 5) {
      return (this.aspectRatio = 5 / 4);
    }
    if (this.aspectRatio === 16 / 9) {
      return (this.aspectRatio = 9 / 16);
    }
    if (this.aspectRatio === 9 / 16) {
      return (this.aspectRatio = 16 / 9);
    }
    if (this.aspectRatio === 5 / 4) {
      return (this.aspectRatio = 4 / 5);
    }
  }

  onCancel(): void {
    this.isShowSlider = false;
    this.isCrop = false;
    this.file = null;
    this.croppedImage = null;
    const fileInput = document.getElementById('addImage') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this._bsModalRef.hide();
    this.onClose.next(null);
  }

  saveCropped() {
    this.isShowSlider = false;
    this.isCrop = false;
    this._bsModalRef.hide();
    this.onClose.next(this.croppedImage);
  }

  getImageSize(file) {
    return file && file.file ? (file.file.size / (1024 * 1024)).toFixed(2) : 0;
  }

  onAspectRatioChange(ratio: any) {
    this.showCrop = false;
    this.isShowSlider = false;
    if (ratio.value === 2) {
      this.originalRatio = this.aspectRatios[0];
      this.aspectRatio = ratio.value;
      // this.originalRatio.value = this.aspectRatio;
      this.isMaintainAspectRatio = false;
      setTimeout(() => {
        this.showCrop = true;
      }, 0);
    } else {
      this.originalRatio = ratio;
      this.isMaintainAspectRatio = true;
      this.aspectRatio = this.originalRatio.value;
      this.showCrop = true;
    }
  }

  onScaleChange(event: Event) {
    this.isCrop = false;
    const inputElement = event.target as HTMLInputElement;
    this.scale = parseFloat(inputElement.value);
    this.sliderValue = this.scale;
    this.updateTransform();
  }
  cropImageRatio() {
    this.isShowSlider = false;
    this.isCrop = !this.isCrop;
  }
  updateTransform() {
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  getSliderBackground(): string {
    const filledColor = 'var(--login-textfield-border)';
    const unfilledColor = 'var(--white)';
    const percentageFilled = (this.sliderValue / 10) * 100;
    return `linear-gradient(to right, ${filledColor} ${percentageFilled}%, ${unfilledColor} ${percentageFilled}%)`;
  }

  hidePopup() {
    this.isShowSlider = false;
    this.isCrop = false;
  }
}
