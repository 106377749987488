import { Injectable } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CyclesUtilitiesService {
  constructor(
    private coreService: CoreService,
    private translateService: TranslateService
  ) {}

  get _today() {
    const now = new Date();
    let d = this.coreService.ClearDateGMT(now, 0, 0);
    return d;
  }

  /**
   *
   * @param cycle
   */
  inDefinitionPhase(cycle: any, role: string) {
    const model =
      role === 'ADMIN'
        ? 'AdminDefinitionInterval'
        : role === 'MANAGER'
          ? 'ManagerDefinitionInterval'
          : 'IndividualDefinitionInterval';
    if (!model) return false;

    const definitionFrom = this.coreService.ClearDateGMT(
      new Date(cycle[model].Start),
      0,
      0
    );
    const definitionTo = this.coreService.ClearDateGMT(
      new Date(cycle[model].End),
      23,
      59
    );

    return this._today >= definitionFrom && this._today <= definitionTo;
  }

  /**
   *
   * @param cycle
   */
  afterDefinitionPhase(cycle: any, role: string) {
    const model =
      role === 'ADMIN'
        ? 'AdminDefinitionInterval'
        : role === 'MANAGER'
          ? 'ManagerDefinitionInterval'
          : 'IndividualDefinitionInterval';
    if (!model) return false;

    const definitionTo = this.coreService.ClearDateGMT(
      new Date(cycle[model].End),
      23,
      59
    );

    return this._today > definitionTo;
  }

  inExecutionPhase(cycle: any, role: string) {
    let cycleStart;
    let cycleEnd;
    if (cycle.Period) {
      cycleStart = this.coreService.ClearDateGMT(
        new Date(cycle.Period.Start),
        0,
        0
      );
      cycleEnd = this.coreService.ClearDateGMT(
        new Date(cycle.Period.End),
        23,
        59
      );
    } else {
      cycleStart = this.coreService.ClearDateGMT(
        new Date(cycle.CycleInfo.Period.Start),
        0,
        0
      );
      cycleEnd = this.coreService.ClearDateGMT(
        new Date(cycle.CycleInfo.Period.End),
        23,
        59
      );
    }

    return this._today >= cycleStart && this._today <= cycleEnd;
  }

  afterExecutionPhase(cycle: any, role: string) {
    let cycleEnd;
    if (cycle.Period) {
      cycleEnd = this.coreService.ClearDateGMT(
        new Date(cycle.Period.End),
        23,
        59
      );
    } else {
      cycleEnd = this.coreService.ClearDateGMT(
        new Date(cycle.CycleInfo.Period.End),
        23,
        59
      );
    }

    return this._today > cycleEnd;
  }

  inEvaluationPhase(cycle: any, role: string) {
    const model =
      role === 'ADMIN'
        ? 'AdminAppraisalInterval'
        : role === 'MANAGER'
          ? 'ManagerAppraisalInterval'
          : 'AdminAppraisalInterval';
    if (!model) return false;

    const definitionFrom = this.coreService.ClearDateGMT(
      new Date(cycle[model].Start),
      0,
      0
    );
    const definitionTo = this.coreService.ClearDateGMT(
      new Date(cycle[model].End),
      23,
      59
    );

    return this._today >= definitionFrom && this._today <= definitionTo;
  }

  showExecutionPhase(cycle: any, role: string) {
    let executionFrom;
    if (cycle.Period) {
      executionFrom = this.coreService.ClearDateGMT(
        new Date(cycle.Period.Start),
        0,
        0
      );
    } else {
      executionFrom = this.coreService.ClearDateGMT(
        new Date(cycle.CycleInfo.Period.Start),
        0,
        0
      );
    }
    // Aggiungo anche un controllo sul fatto che dopo esecuzione deve anche essere oltre inizio valutazione.
    // Se per esempio la FINE della fase di esecuzione di ESECUZIONE non coincide con l'INIZIO della fase di valutazione
    // allora ne devo tenere conto per il selettore delle fasi.

    //console.log('showExecutionPhase', cycle.Period, executionFrom);

    return (
      /*this.afterDefinitionPhase(cycle, role) && */ this._today >=
      executionFrom
    );
  }

  showEvaluationPhase(cycle: any, role: string) {
    const model =
      role === 'ADMIN'
        ? 'AdminAppraisalInterval'
        : role === 'MANAGER'
          ? 'ManagerAppraisalInterval'
          : 'AdminAppraisalInterval';

    const evaluationFrom = this.coreService.ClearDateGMT(
      new Date(cycle[model].Start),
      0,
      0
    );
    // Aggiungo anche un controllo sul fatto che dopo esecuzione deve anche essere oltre inizio valutazione.
    // Se per esempio la FINE della fase di esecuzione di ESECUZIONE non coincide con l'INIZIO della fase di valutazione
    // allora ne devo tenere conto per il selettore delle fasi.
    return (
      /*this.afterExecutionPhase(cycle, role) && */ this._today >=
      evaluationFrom
    );
  }

  afterEvaluationPhase(cycle: any, role: string) {
    const model =
      role === 'ADMIN'
        ? 'AdminAppraisalInterval'
        : role === 'MANAGER'
          ? 'ManagerAppraisalInterval'
          : 'AdminAppraisalInterval';
    if (!model) return false;

    const definitionTo = this.coreService.ClearDateGMT(
      new Date(cycle[model].End),
      23,
      59
    );

    return this._today > definitionTo;
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    return Number(value).toLocaleString(this.translateService.currentLang, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }
}
